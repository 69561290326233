import {useState} from 'react'

import Thumb from '../../../../components/Thumb/Thumb'

import type {ReactElement} from 'react'
import type {IStandardsIconsBlock} from './interfaces/IStandardsIconsBlock'

import {ReactComponent as CopyIcon} from '../../../../assets/icons/copy.svg'
import {ReactComponent as TickIcon} from '../../../../assets/icons/tick.svg'

import styles from './sass/StandardsIconsBlock.module.scss'

export default function StandardsIconsBlock({
  isPositiveReview,
  setIsPositiveReview,
  message,
}: IStandardsIconsBlock): ReactElement {
  const [isCopied, setIsCopied] = useState(false)

  const onCopyClickHandler = async () => {
    try {
      setIsCopied(true)
      await navigator.clipboard.writeText(message)
    } finally {
      setTimeout(() => setIsCopied(false), 3000)
    }
  }

  return (
    <div className={styles.icons}>
      {isCopied ? (
        <TickIcon className={`${styles.icon} ${styles.copy}`} />
      ) : (
        <CopyIcon className={`${styles.icon} ${styles.copy}`} onClick={onCopyClickHandler} />
      )}
      <Thumb
        isUp
        className={`${styles.icon} ${styles.thumb} ${isPositiveReview ? styles.activeIcon : ''}`}
        onClick={() => {
          setIsPositiveReview(isPositiveReview ? undefined : true)
        }}
      />
      <Thumb
        className={`${styles.icon} ${styles.thumb} ${
          isPositiveReview === false ? styles.activeIcon : ''
        }`}
        onClick={() => setIsPositiveReview(isPositiveReview === false ? undefined : false)}
      />
    </div>
  )
}

import {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import {Controller, useForm} from 'react-hook-form'

import {useContentItemTypes} from 'src/app/common/queries/getTypes'

import classes from './CrreateNewItemButton.module.scss'
import {useStatuses} from '../../common/queries/getStatuses'
import {useAppSelector} from '../../pages/PythiaV3/store/hooks/useApp'
import dashboardSelector from '../../common/store/slices/dashboard/selectors'
import {useDashboard} from '../../common/queries/getDashboard'
import {useCurrentUser} from '../../common/queries/getCurrentUser'
import {useCreateContentItem} from '../../common/queries/createContentItem'
import {CreateContentItemCommand} from '../../../api'

interface IFormData {
  Subject: string
  Content: string
  Status: string
  Type: string
}

export function CreateNewItemButton() {
  const [isOpen, setIsOpen] = useState(false)
  const {key} = useAppSelector(dashboardSelector) ?? {}
  const {data} = useDashboard(key)
  const types = useContentItemTypes(data?.id)
  const {control, watch, handleSubmit} = useForm<IFormData>()
  const statuses = useStatuses(watch('Type'))
  const user = useCurrentUser()
  const hasAccessToCreate =
    user.isSuccess &&
    (user.data.roles?.includes('Admin') || user.data.roles?.includes('PythiaModerator'))
  const createContentItem = useCreateContentItem()
  const onClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const onSubmit = (data: any) => {
    const request: CreateContentItemCommand = {
      subject: data.Subject,
      content: data.Content,
      contentItemTypeId: data.Type,
      contentItemStatusId: data.Status,
    }
    createContentItem.mutate(request)
    handleClose()
  }

  return (
    <div className={classes.btnWrapper}>
      {hasAccessToCreate ? (
        <Button
          variant='primary'
          onClick={onClick}
          color='#4962A8'
          style={{backgroundColor: '#4962A8', height: '3rem'}}
        >
          + Add New
        </Button>
      ) : null}
      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Header>Create New Item</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Subject</Form.Label>
              <Controller
                name='Subject'
                control={control}
                defaultValue=''
                rules={{required: true}}
                render={({field}) => <Form.Control {...field} />}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Content</Form.Label>
              <Controller
                name='Content'
                control={control}
                defaultValue=''
                rules={{required: true}}
                render={({field}) => <Form.Control as='textarea' rows={3} {...field} />}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Status</Form.Label>
              {types.isSuccess ? (
                <Controller
                  name='Type'
                  control={control}
                  // defaultValue={ComplaintStatus.PENDING}
                  // rules={{required: true}}
                  render={({field}) => (
                    <Form.Control as='select' {...field}>
                      <option value=''>None</option>
                      {types.data.map((type) => (
                        <option value={type.id}>{type.name}</option>
                      ))}
                    </Form.Control>
                  )}
                />
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Status</Form.Label>
              {statuses.isSuccess ? (
                <Controller
                  name='Status'
                  control={control}
                  // defaultValue={statuses.data[0].id!}
                  // rules={{required: true}}
                  render={({field}) => (
                    <Form.Control as='select' {...field}>
                      <option value=''>None</option>
                      {statuses.data.map((status) => (
                        <option value={status.submenuId}>{status.submenuTitle}</option>
                      ))}
                    </Form.Control>
                  )}
                />
              ) : null}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button type='submit' onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

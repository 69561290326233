/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import './auth.styles.scss'
import {useAppConfigurationQuery} from 'src/queries/getAppConfiguration'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])
  const configuration = useAppConfigurationQuery()
  const image =
    configuration.isSuccess &&
    (configuration.data?.loginBackgroundImage ?? toAbsoluteUrl('/media/background/Senior1.jpg'))
  const logo =
    configuration.isSuccess &&
    (configuration.data?.loginLogo ?? toAbsoluteUrl('/media/logos/LexiLogo.png'))
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed background-container'
      style={{backgroundImage: `url(${image})`}}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 z-1'>
        <a href='#' className='mb-12'>
          <img alt='Logo' src={logo || ''} className='theme-dark-show h-45px' />
          <img alt='Logo' src={logo || ''} className='theme-light-show h-45px'></img>
        </a>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}

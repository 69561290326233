/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {use} from 'i18next'
import {FC, useEffect} from 'react'
import {useAppConfigurationQuery} from 'src/queries/getAppConfiguration'

const Footer: FC = () => {
  const configuration = useAppConfigurationQuery()
  // const {classes} = useLayout()
  const disclaimerLineOne =
    configuration.isSuccess &&
    (configuration.data?.disclaimerLineOne ||
      'Artificial Intelligence (AI) can occasionally make errors. Please verify all critical information independently.')

  const DisclaimerLineTwo = () =>
    configuration.data?.disclaimerLineTwo ? (
      <>{<div dangerouslySetInnerHTML={{__html: configuration.data?.disclaimerLineTwo ?? ''}} />}</>
    ) : (
      <>
        {' '}
        Refer to the accepted{' '}
        <a href='https://www.agedcareai.com.au/disclaimer' target='_blank'>
          Disclaimer
        </a>{' '}
        &{' '}
        <a href='https://www.agedcareai.com.au/user-license' target='_blank'>
          T&Cs
        </a>{' '}
        for further details. Powered by{' '}
        <a href='https://www.agedcareai.com.au/' target='_blank'>
          AgedCareAI.
        </a>
      </>
    )
  return (
    <>
      <div
        className={'footer py-4 d-flex flex-lg-column'}
        id='kt_footer'
        style={{paddingLeft: 92, textAlign: 'center'}}
      >
        <div className='text-dark order-2 order-md-1'>
          <p>{disclaimerLineOne}</p>
          <p>{DisclaimerLineTwo && <DisclaimerLineTwo />}</p>
        </div>
      </div>
      <div style={{bottom: 2, right: 2, position: 'fixed'}}>Beta Version 0.2.83</div>
    </>
  )
}

export {Footer}

import {createSelector} from '@reduxjs/toolkit'
import type IComplaints from './interfaces/IContentItems'

export interface RootState {
  contentItems: IComplaints
}

const contentItems = (state: RootState) => state.contentItems

const contentItemsSelector = createSelector(
  [contentItems],
  ({isModalOpen, activeAnalysisCategory, activeOracleOffering}) => ({
    isModalOpen,
    activeAnalysisCategory,
    activeOracleOffering,
  })
)

export default contentItemsSelector

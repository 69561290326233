import {useNavigate, useParams} from 'react-router-dom'
import {useCategories} from '../api/getCategories'
import {Avatar} from 'primereact/avatar'
import {Divider} from 'primereact/divider'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {useState} from 'react'
import {useUpdateCategory} from '../api/updateCategory'
import {TabMenu} from 'primereact/tabmenu'
import {AddSubcategoryView} from './addSubcategoryView'
import {useDeleteCategory} from '../api/deleteCategory'
import { RolesView } from './rolesView'

const items = [
  {label: 'Subcategories', icon: 'pi pi-wrench'},
  {label: 'Roles', icon: 'pi pi-users'},
]

const viewMap: {[key: number]: JSX.Element} = {
  0: <AddSubcategoryView />,
  1: <RolesView />,
}

export const CategoryView = () => {
  const {categoryId} = useParams()
  const [activeIndex, setActiveIndex] = useState(0)
  const categories = useCategories()
  const category = categories.data?.find((category) => category.id === categoryId)
  const [isEditing, setIsEditing] = useState(false)
  const updateCategory = useUpdateCategory()
  const [categoryName, setCategoryName] = useState(category?.name ?? '')
  const deleteCategory = useDeleteCategory()
  const navigate = useNavigate()
  const categoryLabel = `${category?.data?.name?.charAt(0).toUpperCase()}${category?.data?.name
    ?.charAt(1)
    .toUpperCase()}`

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const handleCategoryNameChange = (e: any) => {
    setCategoryName(e.target.value)
  }

  const handleUpdateClick = () => {
    updateCategory.mutate({id: categoryId ?? '', name: categoryName}, {})
    setIsEditing(!isEditing)
  }

  const handleDeleteClick = () => {
    deleteCategory.mutate(categoryId ?? '', {
      onSuccess: () => {
        navigate('../')
      },
    })
  }

  const handleTabChange = (e: any) => {
    setActiveIndex(e.index)
  }

  return (
    <div style={{padding: '2rem'}}>
      <div style={{position: 'sticky', backgroundColor: 'white', top: 0, zIndex: 100}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <div style={{margin: '4px 20px 0px 0px'}}>
              <Avatar label={categoryLabel} shape='circle' size='xlarge' />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              {isEditing ? (
                <div style={{display: 'flex'}}>
                  <InputText value={categoryName} onChange={handleCategoryNameChange} />
                  <Button
                    icon='pi pi-check'
                    text
                    severity='info'
                    size='large'
                    onClick={handleUpdateClick}
                    disabled={updateCategory.isPending}
                  />
                </div>
              ) : (
                <h3 style={{paddingLeft: '16.250px'}}>{category?.name}</h3>
              )}
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <Button
              icon='pi pi-trash'
              text
              severity='danger'
              size='large'
              onClick={handleDeleteClick}
              disabled={deleteCategory.isPending}
            />
            <Button
              icon='pi pi-pencil'
              text
              severity='info'
              size='large'
              onClick={handleEditClick}
              disabled={updateCategory.isPending}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: '2rem',
            width: '100%',
          }}
        >
        </div>

        <TabMenu model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
      </div>
      <div style={{padding: '1rem'}}>{viewMap[activeIndex]}</div>
    </div>
  )
}

import {ReactElement, useEffect, useMemo, useState} from 'react'
import {Form} from 'react-bootstrap'
import 'react-quill/dist/quill.snow.css'
import avatar from 'src/app/common/assets/images/avatar1.png'
import {useCurrentUser} from '../../../../../queries/getCurrentUser'
import {ButtonVariant, ButtonWidthVariant} from '../../../../common/constants/enams'
import {
  useDeleteContentItemMutation,
  useUpdateContentItemMutation,
} from '../../../../common/store/slices/contentItems/apis/contentItems'
import {IContentItem} from '../../../../common/store/slices/contentItems/interfaces/IContentItemsResponse'
import Button from '../../../PythiaV3/components/Button/Button'
import {useFileContent} from '../../hooks/useFileContent'
import {
  useDeleteFileMutation,
  useUpdateFileMutation,
} from '../../store/slices/assistFiles/apis/assistFiles'
import {IAssistFile} from '../../store/slices/assistFiles/interfaces/IAssistFile'
import styles from './AssistFilesContentView.module.scss'
import {useStatus} from 'src/app/common/queries/getStatus'
import {useContentItemType} from 'src/app/pages/Configuration/Pythia/queries/getType'
import AssistFilesTabs from '../AssistFilesTabs/AssistFilesTabs'
import AssistTemplatesTabs from '../AssistTemplatesTabs/AssistTemplatesTabs'
import {useSuggestions} from 'src/app/pages/Configuration/AskMe/api/getSuggestions'

const defaultItem = '{"content":"", "context":""}'

interface CommonProps {
  typeId?: string
  statusId?: string
  onAiRequestComplete?: () => void
  onUpdatingChanged?: (isUpdating: boolean) => void
}

interface FileProps {
  activeItem: IAssistFile | null
  mode: 'file'
}

interface TemplateProps {
  activeItem: IContentItem | null
  mode: 'template'
}

export type Props = (CommonProps & FileProps) | (CommonProps & TemplateProps)

export default function AssistFilesContentView({
  activeItem,
  mode,
  typeId,
  statusId,
  onAiRequestComplete,
  onUpdatingChanged,
}: Props): ReactElement {
  const [deleteFile, {isLoading: isDeletingFile}] = useDeleteFileMutation()
  const [deleteContentItem, {isLoading: isDeletingContentItem}] = useDeleteContentItemMutation()
  const [updateFile, {isLoading: isUpdatingFile}] = useUpdateFileMutation()
  const [updateContentItem, {isLoading: isUpdatingContentItem}] = useUpdateContentItemMutation()
  const fileContent = useFileContent(activeItem?.content || defaultItem)
  const [subject, setSubject] = useState(activeItem?.subject || '')
  const [content, setContent] = useState(fileContent.content)
  const [context, setContext] = useState(fileContent.content)
  const user = useCurrentUser()
  const isAdmin = user.data?.roles?.includes('Admin') ?? false
  const isReadonly = mode === 'template' && !isAdmin
  const [activeItemId, setActiveItemId] = useState<string | undefined>(undefined)
  const [isBusy, setIsBusy] = useState(false)
  // Generate submenu title
  const status = useStatus(statusId)
  const type = useContentItemType(typeId)
  const submenuTitle = useMemo(
    () => (type.data?.name ? `${type.data?.name ?? ''} / ${status.data?.submenuTitle ?? ''}` : ''),
    [type, status]
  )
  const {data: suggestions} = useSuggestions({})

  useEffect(() => {
    setSubject(activeItem?.subject || '')
    setContent(fileContent.content ?? '<p>*</p>')
    setContext(fileContent.context ?? '<p>*</p>')
  }, [activeItem?.id, activeItem?.subject, fileContent.content, fileContent.context])

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (activeItemId !== activeItem?.id) {
        setActiveItemId(activeItem?.id)
        return
      }

      const fileContent = JSON.stringify({content, context})
      if (activeItem && !isReadonly) {
        mode === 'file'
          ? await updateFile({id: activeItem?.id, subject, content: fileContent})
          : await updateContentItem({
              id: activeItem?.id,
              subject: subject,
              content: fileContent,
              contactId: activeItem.contactId,
              flagged: activeItem.flagged,
              highPriority: activeItem.highPriority,
            })
      }
    }, 500)

    return () => {
      clearTimeout(timerId)
    }
  }, [
    activeItem,
    activeItemId,
    content,
    context,
    isReadonly,
    mode,
    subject,
    updateContentItem,
    updateFile,
  ])

  useEffect(() => {
    const isBusy = isUpdatingFile || isUpdatingContentItem
    if (isBusy) {
      setIsBusy(isBusy)
      onUpdatingChanged?.(isBusy)
      return
    }
    setTimeout(() => {
      setIsBusy(isBusy)
      onUpdatingChanged?.(isBusy)
    }, 500)
  }, [isUpdatingFile, isUpdatingContentItem, onUpdatingChanged])

  const handleDelete = async () => {
    if (!activeItem) {
      return
    }

    if (mode === 'file') {
      await deleteFile({id: activeItem.id})
    } else {
      deleteContentItem(activeItem.id)
    }
  }

  return (
    <>
      <div className={styles.incidentBlock}>
        <div className={styles.incidentBlockInnerContainer}>
          <div>
            <pre>{submenuTitle}</pre>
          </div>
          <div className={styles.incidentBlockHeader}>
            <div className={styles.titleBlock}>
              <div className={styles.avatar}>
                <img src={avatar} alt='avatar' />
              </div>
              <Form.Control
                type='text'
                value={subject}
                className={styles.titleInput}
                onChange={({target}) => setSubject(target.value)}
              ></Form.Control>
            </div>
            <div className={styles.contextButtons}>
              {activeItem && (
                <Button
                  title='Delete'
                  variant={ButtonVariant.GRAY_TEXT}
                  widthVariant={ButtonWidthVariant.EXPLORE}
                  onClick={handleDelete}
                  isLoading={isDeletingFile || isDeletingContentItem}
                />
              )}
              <i
                className='pi pi-spin pi-cog'
                style={{
                  color: '#a2acbc',
                  fontSize: '2rem',
                  transition: 'opacity 0.5s ease-in-out',
                  opacity: isBusy ? 1 : 0,
                }}
              ></i>
            </div>
          </div>
          <div className={styles.contentBlock}>
            {mode === 'file' ? (
              <AssistFilesTabs
                activeItem={activeItem}
                content={content}
                context={context}
                readonly={isReadonly}
                onAiRequestComplete={onAiRequestComplete}
                onContentUpdated={setContent}
                onContextUpdated={setContext}
                suggestions={suggestions?.data}
              />
            ) : (
              <AssistTemplatesTabs
                activeItem={activeItem}
                content={content}
                context={context}
                readonly={isReadonly}
                onContentUpdated={setContent}
                onContextUpdated={setContext}
                suggestions={suggestions?.data}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

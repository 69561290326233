/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {CreateUserModel} from '../models/CreateUserModel'
import type {CurrentUserDataDto} from '../models/CurrentUserDataDto'
import type {LoginModel} from '../models/LoginModel'
import type {LoginResponseDto} from '../models/LoginResponseDto'
import type {PaginatedListOfRoleDto} from '../models/PaginatedListOfRoleDto'
import type {PaginatedUsers} from '../models/PaginatedUsers'
import type {RoleWithEntitiesDto} from '../models/RoleWithEntitiesDto'
import type {UserDto} from '../models/UserDto'
import type {UserRoleModel} from '../models/UserRoleModel'
import type {UserWithRolesDto} from '../models/UserWithRolesDto'
import type {VerifyTokenModel} from '../models/VerifyTokenModel'

import type {CancelablePromise} from '../core/CancelablePromise'
import {OpenAPI} from '../core/OpenAPI'
import {request as __request} from '../core/request'

export class AccountService {
  /**
   * @param requestBody
   * @returns LoginResponseDto
   * @throws ApiError
   */
  public static accountLogin(requestBody: LoginModel): CancelablePromise<LoginResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Account/login',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static accountVerifyToken(requestBody: VerifyTokenModel): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Account/verify_token',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @returns binary
   * @throws ApiError
   */
  public static accountLogout(): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Account/Logout',
    })
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static accountCreateRole(requestBody: string): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Account/CreateRole',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static accountAddRoleToUser(requestBody: UserRoleModel): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Account/addRoleToUser',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static accountCreateUser(requestBody: CreateUserModel): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Account/createUser',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static accountUpdateUser(requestBody: UserDto): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Account',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @returns CurrentUserDataDto
   * @throws ApiError
   */
  public static accountGetCurrentUserData(): CancelablePromise<CurrentUserDataDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Account/GetCurrentUserData',
    })
  }

  /**
   * @param page
   * @param pageSize
   * @param search
   * @param sortBy
   * @param sortDescending
   * @returns PaginatedUsers
   * @throws ApiError
   */
  public static accountGetUsers(
    page?: number,
    pageSize?: number,
    search?: string | null,
    sortBy?: string | null,
    sortDescending?: boolean
  ): CancelablePromise<PaginatedUsers> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Account/getUsers',
      query: {
        Page: page,
        PageSize: pageSize,
        Search: search,
        SortBy: sortBy,
        SortDescending: sortDescending,
      },
    })
  }

  /**
   * @param userId
   * @returns string
   * @throws ApiError
   */
  public static accountGetUserRoles(userId?: string): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Account/getUserRoles',
      query: {
        userId: userId,
      },
    })
  }

  /**
   * @param id
   * @returns binary
   * @throws ApiError
   */
  public static accountDeleteUser(id: string): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/Account/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns UserWithRolesDto
   * @throws ApiError
   */
  public static accountGetUserById(id: string): CancelablePromise<UserWithRolesDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Account/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param userId
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static accountRemoveRolesFromUser(
    userId: string,
    requestBody: Array<string>
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/Account/remove-roles/{userId}',
      path: {
        userId: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param search
   * @param page
   * @param pageSize
   * @returns PaginatedListOfRoleDto
   * @throws ApiError
   */
  public static accountGetAllRoles(
    search: string | null = '',
    page: number = 1,
    pageSize: number = 10
  ): CancelablePromise<PaginatedListOfRoleDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Account/roles',
      query: {
        search: search,
        page: page,
        pageSize: pageSize,
      },
    })
  }

  /**
   * @param roleId
   * @returns RoleWithEntitiesDto
   * @throws ApiError
   */
  public static accountGetRoleWithEntities(roleId: string): CancelablePromise<RoleWithEntitiesDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/Account/roles/{roleId}',
      path: {
        roleId: roleId,
      },
    })
  }
}

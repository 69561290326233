/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {Category} from '../models/Category'
import type {CategoryDto} from '../models/CategoryDto'
import type {UpdateCategoryCommand} from '../models/UpdateCategoryCommand'

import type {CancelablePromise} from '../core/CancelablePromise'
import {OpenAPI} from '../core/OpenAPI'
import {request as __request} from '../core/request'

export class CategoriesService {
  /**
   * @param requestBody
   * @returns Category
   * @throws ApiError
   */
  public static categoriesCreateCategory(requestBody: CategoryDto): CancelablePromise<Category> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/admin/Categories',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns binary
   * @throws ApiError
   */
  public static categoriesUpdateCategory(
    requestBody: UpdateCategoryCommand
  ): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/admin/Categories',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns Category
   * @throws ApiError
   */
  public static categoriesDeleteCategory(id: string): CancelablePromise<Category> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/admin/Categories/{id}',
      path: {
        id: id,
      },
    })
  }
}

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface BasicInfo {
  field: string;
  details: string;
}

interface MedicalHistory {
  condition: string;
  details: string;
}

interface CarePlanItem {
  focusArea: string;
  goal: string;
  intervention: string[];
}

interface Medication {
  medication: string;
  dosage: string;
  frequency: string;
  purpose: string;
}

interface NurseCall {
  date: string;
  timeOfCall: string;
  reasonForCall: string;
  responseTime: string;
  resolution: string;
}

interface FallIncident {
  date: string;
  time: string;
  location: string;
  description: string;
  outcome: string;
}

interface Observation {
  date: string;
  time: string;
  observation: string;
  notes: string;
}

interface VitalSign {
  date: string;
  time: string;
  bloodPressure: string;
  heartRate: string;
  temperature: string;
  oxygenSaturation: string;
}

interface Contact {
  name: string;
  relationship: string;
  phone: string;
  email: string;
}

// Data
const basicInfoData: BasicInfo[] = [
  { field: "Resident ID", details: "123456" },
  { field: "Full Name", details: "John Smith" },
  { field: "Date of Birth", details: "January 15, 1940 (Age: 84)" },
  { field: "Gender", details: "Male" },
  { field: "Room", details: "A101, 2nd Floor" },
  { field: "Wing", details: "Section Alpha" },
  { field: "Admission Date", details: "March 12, 2024" },
  { field: "Language", details: "English" },
  { field: "Marital Status", details: "Widowed" },
  { field: "Allergies", details: "None known" },
  { field: "Emergency Contact", details: "Jane Smith (Daughter), +1 (555) 123-4567" }
];

const medicalHistoryData: MedicalHistory[] = [
  { condition: "Diabetes Type 2", details: "Managed with oral hypoglycemics (Metformin 500 mg daily). Regular blood glucose monitoring." },
  { condition: "Hypertension", details: "Managed with Lisinopril 10 mg once daily." },
  { condition: "Osteoarthritis", details: "Affects knees and lower back. Managed with physiotherapy and PRN pain relief." },
  { condition: "Chronic Urinary Tract Infections (UTIs)", details: "Recurring UTIs. Previous hospitalizations for IV antibiotics. Regular monitoring for symptoms." },
  { condition: "History of Falls", details: "Increased fall risk due to balance issues. Two falls reported within the past 3 months." },
  { condition: "Depression", details: "Mild depression post-widowhood, managed with regular social interaction and counseling." }
];

const carePlanData: CarePlanItem[] = [
  {
    focusArea: "Mobility and Fall Prevention",
    goal: "Reduce fall risk and promote safe mobility.",
    intervention: ["Use walker or wheelchair for ambulation.", "Call bell in easy reach."]
  },
  {
    focusArea: "Skin Integrity",
    goal: "Prevent pressure injuries and promote wound healing.",
    intervention: ["Regular repositioning, daily skin checks, wound dressing changes."]
  },
  {
    focusArea: "Pain Management",
    goal: "Effectively control and manage pain.",
    intervention: ["Administer PRN pain medication.", "Cold compress for knee pain, repositioning."]
  },
  {
    focusArea: "Nutrition",
    goal: "Maintain adequate nutrition and hydration.",
    intervention: ["Assist with meals, monitor food intake, encourage hydration."]
  },
  {
    focusArea: "Continence",
    goal: "Manage incontinence and promote toileting independence.",
    intervention: ["Scheduled toileting every 2-3 hours, continence aids, Ural for urinary discomfort."]
  },
  {
    focusArea: "Mental Health and Psychosocial",
    goal: "Support emotional well-being and social engagement.",
    intervention: ["Encourage family visits, participation in group activities, regular conversations."]
  }
];

const medicationData: Medication[] = [
  { medication: "Metformin", dosage: "500 mg", frequency: "Once daily", purpose: "Diabetes management" },
  { medication: "Lisinopril", dosage: "10 mg", frequency: "Once daily", purpose: "Hypertension management" },
  { medication: "Paracetamol", dosage: "500 mg", frequency: "PRN (as needed)", purpose: "Pain relief (arthritis)" },
  { medication: "Ural", dosage: "2 sachets", frequency: "PRN (as needed)", purpose: "Urinary discomfort from UTIs" },
  { medication: "IV Antibiotics", dosage: "As per hospital discharge plan", frequency: "Post-hospitalization (as needed)", purpose: "Treating chronic UTIs" }
];

const nurseCallLogData: NurseCall[] = [
  { date: "August 29, 2024", timeOfCall: "04:00 PM", reasonForCall: "Assistance with shower", responseTime: "7 minutes", resolution: "Resident showered with assistance." },
  { date: "August 22, 2024", timeOfCall: "11:55 AM", reasonForCall: "Restroom assistance", responseTime: "5 minutes", resolution: "Assisted to restroom, no issues." },
  { date: "August 18, 2024", timeOfCall: "08:20 PM", reasonForCall: "Pain (urinary discomfort)", responseTime: "3 minutes", resolution: "Ural administered, fluids encouraged." }
];

const fallIncidentData: FallIncident[] = [
  { date: "June 15, 2024", time: "07:15 AM", location: "Room A101", description: "Resident attempted to get out of bed without assistance, fell onto the floor.", outcome: "No injuries, monitored for 24 hours." },
  { date: "July 2, 2024", time: "02:30 PM", location: "Hallway, Section Alpha", description: "Resident tripped over carpet edge, fell forward onto knees while using walker.", outcome: "Minor abrasions to knees, applied cold compress." }
];

const observationData: Observation[] = [
  { date: "September 1, 2024", time: "06:00 PM", observation: "Skin integrity check", notes: "No new pressure areas or skin concerns." },
  { date: "September 2, 2024", time: "09:00 AM", observation: "Blood glucose check (fasting)", notes: "Blood glucose: 6.5 mmol/L, within normal limits." },
  { date: "September 3, 2024", time: "08:30 PM", observation: "Complaints of urinary discomfort", notes: "Ural administered, symptoms relieved." }
];

const vitalSignsData: VitalSign[] = [
  { date: "September 1, 2024", time: "08:00 AM", bloodPressure: "135/85 mmHg", heartRate: "76 bpm", temperature: "36.8°C", oxygenSaturation: "98%" },
  { date: "September 2, 2024", time: "10:00 AM", bloodPressure: "130/82 mmHg", heartRate: "78 bpm", temperature: "36.7°C", oxygenSaturation: "97%" },
  { date: "September 3, 2024", time: "09:30 AM", bloodPressure: "138/88 mmHg", heartRate: "74 bpm", temperature: "36.9°C", oxygenSaturation: "98%" }
];

const contactInfoData: Contact[] = [
  { name: "Jane Smith", relationship: "Daughter", phone: "+1 (555) 123-4567", email: "jane.smith@example.com" },
  { name: "Dr. Michael Lee", relationship: "Primary Physician", phone: "+1 (555) 987-6543", email: "dr.lee@hospital.com" }
];

export const Profile = () => {
  return (
    <div className="p-card p-5">
      <h2>Resident Profile: John Smith</h2>

      <ul>
        {basicInfoData.map((info, index) => (
          <li key={index}>
            <strong>{info.field}:</strong> {info.details}
          </li>
        ))}
      </ul>

      <ul>
        {medicalHistoryData.map((history, index) => (
          <li key={index}>
            <strong>{history.condition}:</strong> {history.details}
          </li>
        ))}
      </ul>

      {carePlanData.map((plan, index) => (
        <div key={index}>
          <h4>Focus Area: {plan.focusArea}</h4>
          <p><strong>Goal:</strong> {plan.goal}</p>
          <ul>
            {plan.intervention.map((intervention, idx) => (
              <li key={idx}>{intervention}</li>
            ))}
          </ul>
        </div>
      ))}
      <div className='mt-10'>
        <h3>Medications </h3>
        <DataTable value={medicationData} paginator rows={5} responsiveLayout="scroll">
          <Column field="medication" header="Medication" />
          <Column field="dosage" header="Dosage" />
          <Column field="frequency" header="Frequency" />
          <Column field="purpose" header="Purpose" />
        </DataTable>
      </div>
      <div className='mt-10'>
        <h3>Nurse Call Log</h3>
        <DataTable value={nurseCallLogData} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="timeOfCall" header="Time of Call" />
          <Column field="reasonForCall" header="Reason for Call" />
          <Column field="responseTime" header="Response Time" />
          <Column field="resolution" header="Resolution" />
        </DataTable>
      </div>
      <div className='mt-10'>
        <h3>Fall Incidents</h3>
        <DataTable value={fallIncidentData} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="time" header="Time" />
          <Column field="location" header="Location" />
          <Column field="description" header="Description" />
          <Column field="outcome" header="Outcome" />
        </DataTable>
      </div>
      <div className='mt-10'>
        <h3>Observations</h3>
        <DataTable value={observationData} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="time" header="Time" />
          <Column field="observation" header="Observation" />
          <Column field="notes" header="Notes" />
        </DataTable>
      </div>
      <div className='mt-10'>
        <h3>Vital Signs</h3>
        <DataTable value={vitalSignsData} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="time" header="Time" />
          <Column field="bloodPressure" header="Blood Pressure" />
          <Column field="heartRate" header="Heart Rate" />
          <Column field="temperature" header="Temperature" />
          <Column field="oxygenSaturation" header="Oxygen Saturation" />
        </DataTable>
      </div>

      <div className='mt-10'>
        <h3>Contact Info</h3>
        <DataTable value={contactInfoData} paginator rows={5} responsiveLayout="scroll">
          <Column field="name" header="Name" />
          <Column field="relationship" header="Relationship" />
          <Column field="phone" header="Phone" />
          <Column field="email" header="Email" />
        </DataTable>
      </div>
    </div>
  );
};

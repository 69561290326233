import about from './about'
import common from './common'
import home from './home'
import login from './login'

export default {
  common,
  home,
  about,
  login,
}

import {useQuery} from '@tanstack/react-query'
import {AccountService} from '../api'

export type GetAllRolesOptions = {
  search?: string | null
  page?: number
  pageSize?: number
}

export const useRoles = ({search, page, pageSize}: GetAllRolesOptions) =>
  useQuery({
    queryFn: () => AccountService.accountGetAllRoles(search, page, pageSize),
    queryKey: ['roles', search, page, pageSize],
  })

import {TabMenu, TabMenuTabChangeEvent} from 'primereact/tabmenu'
import {ReactElement, useEffect, useMemo, useState} from 'react'
import {useCurrentUser} from 'src/app/common/queries/getCurrentUser'
import {IContentItem} from 'src/app/common/store/slices/contentItems/interfaces/IContentItemsResponse'
import styles from './AssistTemplatesTabs.module.scss'
import TextEditor from '../TextEditor/TextEditor'
import {Skeleton} from 'primereact/skeleton'
import {useActiveSession, useNewSession} from 'src/app/common/queries/chatSession'
import PlaygroundView from '../PlaygroundView/PlaygroundView'
import {AiRequestsList} from '../AiActionsSidebarView/AiActionsList/AiRequestsList'
import UploadFilesView from '../UploadFilesView/UploadFilesView'
import {handleAiAction} from '../../../../common/helpers/handleAiAction'
import {Button} from 'primereact/button'
import {Suggestion} from 'src/api/models/Suggestion'

interface Props {
  activeItem: IContentItem | null
  content: string
  context: string
  readonly?: boolean
  suggestions?: Suggestion[]
  onContentUpdated: (content: string) => void
  onContextUpdated: (context: string) => void
  onTextSelected?: (text: string) => void
}

export default function AssistTemplatesTabs({
  activeItem,
  content,
  context,
  readonly,
  onTextSelected,
  onContentUpdated,
  onContextUpdated,
}: Props): ReactElement {
  const user = useCurrentUser()
  const isAdmin = user.data?.roles?.includes('Admin') ?? false
  const isReadonly = !isAdmin
  const [activeIndex, setActiveIndex] = useState(0)
  const [upToDate, setUpToDate] = useState<string | undefined>(new Date().toISOString())
  const {
    data: activeChatSession,
    status: activeChatSessionStatus,
    isLoading: isLoadingActiveChatSession,
  } = useActiveSession(activeItem?.id ?? '', upToDate)
  const {mutate: createNewSession, isPending: isCreatingNewSession} = useNewSession(
    activeItem?.id ?? ''
  )
  const [executionPrompt, setExecutionPrompt] = useState<
    {prompt: string; actionId: string} | undefined
  >(undefined)
  const [busy, setBusy] = useState(false)

  const activeChatSessionId = activeChatSession?.id ?? ''

  useEffect(() => {
    if (!activeChatSessionId && ['success', 'error'].indexOf(activeChatSessionStatus) > 0) {
      createNewSession()
    }
  }, [activeChatSessionId, activeChatSessionStatus, createNewSession])

  useEffect(() => {
    setBusy(isCreatingNewSession || isLoadingActiveChatSession)
  }, [isCreatingNewSession, isLoadingActiveChatSession])

  const handleTabChange = (e: TabMenuTabChangeEvent) => {
    setActiveIndex(999)
    setTimeout(() => {
      setActiveIndex(e.index)
      if (e.index === 0) {
        setUpToDate(new Date().toISOString())
      }
    }, 100)
  }

  const loadingView = useMemo(
    () => (
      <>
        <Skeleton height='8rem' className='mb-2'></Skeleton>
        <Skeleton height='3rem' className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
        <Skeleton height='12rem' style={{marginTop: '2rem'}} className='mb-2'></Skeleton>
      </>
    ),
    []
  )

  const items = [
    ...[isAdmin ? {label: 'Playground'} : {}],
    // ...[isAdmin ? {label: 'Actions'} : {}],
    ...[isAdmin ? {label: 'Files'} : {}],
    {label: 'Training'},
    {label: 'Completion'},
  ]

  const viewMapAdmins: {[key: number]: JSX.Element} = useMemo(
    () => ({
      999: loadingView,
      0: (
        <>
          {activeChatSession && (
            <PlaygroundView
              session={activeChatSession}
              executionPrompt={executionPrompt}
              onPrompt={(prompt, actionId) =>
                handleAiAction({prompt, actionId, context, activeChatSessionId})
              }
              onAddToCompletion={(response) => onContentUpdated(`${content} </br> ${response}`)}
            />
          )}
        </>
      ),
      998: (
        <AiRequestsList
          onAction={(actionId, prompt) => {
            setActiveIndex(0)
            setExecutionPrompt({prompt, actionId})
          }}
          statusId={activeItem?.status?.id}
        />
      ),
      1: (
        <>
          {activeItem ? (
            <UploadFilesView sessionId={activeChatSessionId} fileId={activeItem.id} />
          ) : (
            <div>No active file selected</div>
          )}
        </>
      ),
      2: <TextEditor text={context} setText={onContextUpdated} readonly={isReadonly || readonly} />,
      3: (
        <TextEditor
          fileId={activeItem?.id}
          text={content}
          setText={onContentUpdated}
          readonly={isReadonly || readonly}
          textSelected={(text) => {
            onTextSelected?.(text)
          }}
        />
      ),
    }),
    [
      loadingView,
      activeChatSession,
      executionPrompt,
      activeItem,
      activeChatSessionId,
      context,
      onContextUpdated,
      isReadonly,
      readonly,
      content,
      onContentUpdated,
      onTextSelected,
    ]
  )
  const viewMapsUsers: {[key: number]: JSX.Element} = useMemo(() => {
    const {2: contextEditor, 3: contentEditor} = viewMapAdmins
    return {0: contextEditor, 1: contentEditor}
  }, [viewMapAdmins])
  const viewMap = isAdmin ? viewMapAdmins : viewMapsUsers

  if (!busy && !activeChatSessionId) {
    return (
      <div className={styles.spinnerContainer}>
        <p>Something went wrong with your session. Please click to renew</p>
        <Button
          onClick={() => createNewSession()}
          icon={<i className='pi pi-replay' style={{fontSize: '2rem'}}></i>}
          severity='secondary'
          rounded
          text
          raised
        />
      </div>
    )
  }

  return (
    <>
      <TabMenu model={items} activeIndex={activeIndex} onTabChange={handleTabChange} />
      <div className={styles.content}>{viewMap[activeIndex]}</div>
    </>
  )
}

import React from 'react'
import {Provider} from 'react-redux'
import * as Sentry from '@sentry/browser'
import {PersistGate} from 'redux-persist/integration/react'

import {persistor, store} from './store/store'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
export const AskMe = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

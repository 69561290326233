import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {ChatSessionService} from 'src/api/services/ChatSessionService'

export const useActiveSession = (fileId: string, upToDate?: string) => {
  return useQuery({
    queryFn: () => ChatSessionService.getActiveSession(fileId),
    queryKey: ['activeSession', fileId, upToDate],
    retry: false,
  })
}

export const useNewSession = (fileId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => ChatSessionService.createSession(fileId),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['activeSession', fileId]})
    },
  })
}

import {Button} from 'primereact/button'
import {Chip} from 'primereact/chip'
import {Divider} from 'primereact/divider'
import {useState} from 'react'
import {ContentItemStatus, PythiaOffering} from '../../../../../../api'
import {DataView} from 'primereact/dataview'
import {useParams} from 'react-router-dom'
import {Column} from 'primereact/column'
import {useAvailableStatusPrompts} from '../../../Pythia/queries/getStatuses'
import {usePrompts} from '../../../Pythia/queries/getPrompts'
import {
  DataTable,
  DataTableFilterMeta,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable'
import {InputText} from 'primereact/inputtext'
import {InputIcon} from 'primereact/inputicon'
import {IconField} from 'primereact/iconfield'
import {FilterMatchMode} from 'primereact/api'
import {
  useAssignPromptToStatus,
  useUnAssignPromptToStatus,
} from '../../../Pythia/queries/assignPromptToStatus'
import {useSubcategories} from '../../../../../common/queries/getStatus'

export const PromptsView = () => {
  const [showManagePrompts, setShowManagePrompts] = useState(false)
  const {subCategoryId} = useParams()
  const subCategory = useSubcategories(subCategoryId)
  const availablePrompts = useAvailableStatusPrompts(subCategoryId)

  const prompts = usePrompts()

  const filteredPrompts = prompts.data?.filter((prompt) =>
    subCategory.data?.analysisPromptContentItemStatuses?.some(
      (ap) => ap.promptId === prompt.id ?? ''
    )
  )

  const handleManagePromptsClick = () => {
    setShowManagePrompts(!showManagePrompts)
  }
  return (
    <div>
      <div
        style={{
          marginBottom: '1rem',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {!showManagePrompts && <h3>Assigned Actions</h3>}
        <Button
          label={showManagePrompts ? '' : 'Manage Actions'}
          text
          size={showManagePrompts ? 'large' : 'small'}
          pt={{root: {style: {margin: 0, padding: 0}}}}
          onClick={handleManagePromptsClick}
          icon={showManagePrompts ? 'pi pi-arrow-left' : undefined}
        />
      </div>
      {!showManagePrompts && (
        <DataView value={filteredPrompts} itemTemplate={promptTemplate}>
          <Column field='name' header='Name' />
          <Column field='description' header='Description' />
        </DataView>
      )}
      {showManagePrompts && subCategory.data && (
        <AssignPromptsTable
          subCategory={subCategory.data}
          prompts={
            prompts.data?.filter((prompt) =>
              availablePrompts.data?.some((ap) => ap === prompt.id)
            ) ?? []
          }
          filteredPrompts={
            filteredPrompts?.filter((prompt) =>
              availablePrompts.data?.some((ap) => ap === prompt.id)
            ) ?? []
          }
        />
      )}
    </div>
  )
}

const promptTemplate = (prompt: PythiaOffering) => {
  return (
    <div>
      <h4>{prompt.name}</h4>
      <div style={{marginBottom: '1rem'}}>{prompt.notes}</div>
      <Chip label={prompt.category} />
      <Divider />
    </div>
  )
}

type AssignPromptsTableProps = {
  subCategory: ContentItemStatus
  prompts: PythiaOffering[]
  filteredPrompts: PythiaOffering[]
}
const AssignPromptsTable = ({subCategory, prompts, filteredPrompts}: AssignPromptsTableProps) => {
  const assignPrompts = useAssignPromptToStatus()
  const unAssignPrompts = useUnAssignPromptToStatus()
  const [selectedPrompts, setSelectedPrompts] = useState<PythiaOffering[]>(filteredPrompts)
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const handleSelect = (e: DataTableSelectionMultipleChangeEvent<PythiaOffering[]>) => {
    setSelectedPrompts(e.value ?? [])

    const promptsToUnassign = filteredPrompts.filter((prompt) => {
      return !e.value?.some((ev) => ev.id === prompt.id)
    })
    const promptsToAssign = e.value?.filter((prompt) => {
      return !filteredPrompts.some((fp) => fp.id === prompt.id)
    })
    console.log('promptsToUnassign', promptsToUnassign)
    promptsToAssign.forEach((prompt) => {
      assignPrompts.mutate({
        statusId: subCategory.id ?? '',
        promptId: prompt.id ?? '',
      })
    })

    promptsToUnassign.forEach((prompt) => {
      unAssignPrompts.mutate({
        statusId: subCategory.id ?? '',
        promptId: prompt.id ?? '',
      })
    })
  }

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...filters}

    // @ts-ignore
    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Keyword Search'
          />
        </IconField>
      </div>
    )
  }

  const header = renderHeader()
  return (
    <div>
      <h3>Assign Actions</h3>
      <DataTable
        value={prompts}
        selection={selectedPrompts}
        selectionMode={null}
        onSelectionChange={handleSelect}
        dataKey='id'
        filters={filters}
        globalFilterFields={['name', 'category', 'notes']}
        header={header}
      >
        <Column selectionMode='multiple' headerStyle={{width: '3rem'}} />
        <Column sortable field='name' header='Name' />
        <Column sortable field='category' header='Category' />
        <Column sortable field='notes' header='Description' />
      </DataTable>

      {/* <TreeTable
        value={categorizedPrompts.data ?? []}
        selectionMode='checkbox'
        selectionKeys={selectedNodeKeys}
        onSelectionChange={(e: TreeTableSelectionEvent) => setSelectedNodeKeys(e.value)}
        columnResizeMode='fit'
      >
        <Column field='name' header='Name' expander style={{display: 'flex'}} />
        <Column field='description' header='Description' />
      </TreeTable> */}
    </div>
  )
}

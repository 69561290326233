import {createSelector} from '@reduxjs/toolkit'

import type {RootState} from '../../types/TStore'

const categories = (state: RootState) => state.categories

const categoriesSelector = createSelector([categories], ({category, subCategory, categories}) => ({
  category,
  subCategory,
  categories,
}))

export default categoriesSelector

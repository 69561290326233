import {useParams} from 'react-router-dom'
import {Skeleton} from 'primereact/skeleton'
import {Avatar} from 'primereact/avatar'
import {PromptsView} from './subCategories/promptsView'
import {Divider} from 'primereact/divider'
import {useUpdateStatus} from '../../Pythia/queries/updateStatus'
import {useState} from 'react'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {useSubcategories} from '../../../../common/queries/getStatus'

export const SubCategoriesView = () => {
  const {subCategoryId} = useParams()
  const subCategory = useSubcategories(subCategoryId)
  const [isEditing, setIsEditing] = useState(false)
  const [subCategoryName, setSubCategoryName] = useState(subCategory.data?.name ?? '')
  const updateSubCategory = useUpdateStatus()
  const subCategoryLabel = `${subCategory.data?.name
    ?.charAt(0)
    .toUpperCase()}${subCategory.data?.name?.charAt(1).toUpperCase()}`

  const handleCategoryNameChange = (e: any) => {
    setSubCategoryName(e.target.value)
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }

  const handleUpdateClick = () => {
    updateSubCategory.mutate({id: subCategoryId ?? '', name: subCategoryName}, {})
    setIsEditing(!isEditing)
  }

  return (
    <div>
      {subCategory.isLoading ? (
        <>
          <Skeleton shape='circle' size='5rem'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton width='10rem' className='mb-2'></Skeleton>
          <Skeleton width='5rem' className='mb-2'></Skeleton>
          <Skeleton height='2rem' className='mb-2'></Skeleton>
          <Skeleton width='10rem' height='4rem'></Skeleton>
        </>
      ) : (
        <>
          <div style={{position: 'sticky', backgroundColor: 'white', top: 0, zIndex: 100}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <div style={{margin: '4px 20px 0px 0px'}}>
                  <Avatar label={subCategoryLabel} shape='circle' size='xlarge' />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                  }}
                >
                  {isEditing ? (
                    <div style={{display: 'flex'}}>
                      <InputText value={subCategoryName} onChange={handleCategoryNameChange} />
                      <Button
                        icon='pi pi-check'
                        text
                        severity='info'
                        size='large'
                        onClick={handleUpdateClick}
                        // onClick={handleEditClick}
                        // disabled={deleteType.isPending}
                      />
                    </div>
                  ) : (
                    <h3 style={{paddingLeft: '16.250px'}}>{subCategory.data?.name}</h3>
                  )}
                </div>
              </div>
              <div style={{display: 'flex'}}>
                <Button
                  icon='pi pi-pencil'
                  text
                  severity='info'
                  size='large'
                  onClick={handleEditClick}
                  disabled={updateSubCategory.isPending}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '2rem',
                width: '100%',
              }}
            >
              <Divider />
            </div>
          </div>
          <PromptsView />
        </>
      )}
    </div>
  )
}

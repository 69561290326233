import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface IncidentReport {
    resident: string;
    date: string;
    timeOfIncident: string;
    location: string;
    incidentType: string;
    reportedBy: string;
    description: string;
    assessment: {
        consciousness: string;
        injury: string;
        painLevel: string;
        vitalSigns: string;
        mobility: string;
    };
    intervention: string[];
    followUpActions: string[];
}

// Incident data
const incidentReports: IncidentReport[] = [
    {
        resident: "John Smith",
        date: "Today",
        timeOfIncident: "07:15 AM",
        location: "2nd Floor, Room A101",
        incidentType: "Fall",
        reportedBy: "Anna Kournikova, Enrolled Nurse",
        description: `At approximately 07:15 AM, the resident, John Smith, was found on the floor beside his bed. The resident attempted to get out of bed without assistance and lost his balance, resulting in a fall. The resident was discovered by the care staff during routine morning rounds.`,
        assessment: {
            consciousness: "Resident was alert and oriented.",
            injury: "No visible injuries or bruises. Minor redness noted on the right elbow, but no complaints of pain.",
            painLevel: "Resident denies pain when asked.",
            vitalSigns: "Blood pressure, heart rate, and oxygen levels were within normal limits.",
            mobility: "Assisted back to bed with 2 staff members using a lifting device."
        },
        intervention: [
            "Immediate assistance was provided to return the resident safely to bed.",
            "Full assessment was conducted, and vital signs were checked.",
            "Incident reported to the nurse-in-charge and physician.",
            "Family member notified via phone call.",
            "Resident was monitored closely for the next 24 hours for any delayed onset of pain or injury."
        ],
        followUpActions: [
            "Fall risk assessment updated.",
            "Review of care plan for mobility assistance and ensuring that the call bell is within easy reach.",
            "Scheduled physiotherapy reassessment."
        ]
    },
    {
        resident: "John Smith",
        date: "Today",
        timeOfIncident: "02:30 PM",
        location: "Section Alpha, Hallway",
        incidentType: "Fall",
        reportedBy: "Katerina Pappas, Registered Nurse",
        description: `At approximately 02:30 PM, John Smith was walking down the hallway near his room (B202) with the assistance of a walker. The resident appeared to trip over the edge of the carpet, losing his balance and falling forward onto his knees. The fall was witnessed by the care staff nearby, who immediately went to assist the resident.`,
        assessment: {
            consciousness: "Resident remained alert and oriented throughout.",
            injury: "Minor abrasions to both knees, no other injuries or bleeding observed. Resident complained of mild discomfort in the knees.",
            painLevel: "Pain rated as 3/10 in the knees when asked.",
            vitalSigns: "Vital signs taken post-fall were within normal limits.",
            mobility: "Assisted to sit on a nearby chair before being transferred to his room using a wheelchair."
        },
        intervention: [
            "Resident’s knees were cleaned, and a topical ointment was applied to the abrasions.",
            "Cold compress applied to reduce any swelling.",
            "Incident reported to the nurse-in-charge and physician.",
            "Resident was monitored for the remainder of the shift for any signs of delayed pain or discomfort.",
            "Family notified via phone call, updated on the incident."
        ],
        followUpActions: [
            "Fall risk assessment reviewed.",
            "Care plan updated to increase staff assistance during ambulation.",
            "Resident encouraged to avoid walking alone and to use the call bell for assistance.",
            "Physiotherapist notified to reassess mobility aids and walking technique."
        ]
    }
];

export const Incidents = () => {
    return (
        <div className="p-card p-5">
            <h2>Incident Reports</h2>

                {incidentReports.map((report, index) => (
                        <div>
                            <strong>Resident:</strong> {report.resident}<br />
                            <strong>Date:</strong> {report.date}<br />
                            <strong>Time of Incident:</strong> {report.timeOfIncident}<br />
                            <strong>Location:</strong> {report.location}<br />
                            <strong>Incident Type:</strong> {report.incidentType}<br />
                            <strong>Reported By:</strong> {report.reportedBy}<br />
                            <br />
                            <strong>Description of Incident:</strong>
                            <p>{report.description}</p>

                            <h4>Assessment</h4>
                            <ul>
                                <li><strong>Consciousness:</strong> {report.assessment.consciousness}</li>
                                <li><strong>Injury:</strong> {report.assessment.injury}</li>
                                <li><strong>Pain Level:</strong> {report.assessment.painLevel}</li>
                                <li><strong>Vital Signs:</strong> {report.assessment.vitalSigns}</li>
                                <li><strong>Mobility:</strong> {report.assessment.mobility}</li>
                            </ul>

                            <h4>Intervention</h4>
                            <ul>
                                {report.intervention.map((item, idx) => (
                                    <li key={idx}>{item}</li>
                                ))}
                            </ul>

                            <h4>Follow-Up Actions</h4>
                            <ul>
                                {report.followUpActions.map((item, idx) => (
                                    <li key={idx}>{item}</li>
                                ))}
                            </ul>
                        </div>
                ))}
        </div>
    );
};


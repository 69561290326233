import type {ReactElement} from 'react'
import type {IErrorMessage} from './interfaces/IErrorMessage'

import styles from './sass/ErrorMessage.module.scss'

export default function ErrorMessage({error}: IErrorMessage): ReactElement {
  return (
    <p className={styles.error}>{typeof error === 'string' ? error : error?.message?.toString()}</p>
  )
}

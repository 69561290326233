import type {ReactElement} from 'react'
import type {ISearchInput} from './interfaces/ISearchInput'

import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg'

import styles from './sass/SearchInput.module.scss'

export default function SearchInput({searchTerm, onChangeHandler}: ISearchInput): ReactElement {
  return (
    <div className={styles.searchInput}>
      <SearchIcon className={styles.searchIcon} />
      <div className={styles.inputField}>
        <input
          type='text'
          value={searchTerm}
          onChange={(e) => onChangeHandler(e.currentTarget.value)}
          aria-label='Search'
          placeholder='Search'
        />
      </div>
    </div>
  )
}

import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import auth from './slices/auth/slice'
import calendarFilter from './slices/calendarFilter/slice'
import clientFilter from './slices/clientFilter/slice'
import translates from 'src/app/common/store/slices/translates/slice'
import dashboard from 'src/app/common/store/slices/dashboard/slice'
import mainApi from '../../../../api/mainApi'
import contentItems from '../../../common/store/slices/contentItems/slice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'theme'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [mainApi.reducerPath]: mainApi.reducer,
    translates,
    contentItems,
    calendarFilter,
    clientFilter,
    dashboard,
    auth,
  })
)

export function makeStore(preloadedState = {}) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: Object.keys(preloadedState).length ? preloadedState : undefined,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([mainApi.middleware]),
  })
}

export const store = makeStore()

setupListeners(store.dispatch)

export const persistor = persistStore(store)

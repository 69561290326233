import SecondarySidebar from '../SecondarySidebar/SecondarySidebar'

import type {ReactElement} from 'react'

import styles from './sass/Sidebar.module.scss'

export default function Sidebar(): ReactElement {
  return (
    <div className={styles.sidebar}>
      <SecondarySidebar />
    </div>
  )
}

import type {ReactElement} from 'react'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Button from '../../../../components/Button/Button'
import Subtitle from '../../../../components/Subtitle/Subtitle'
import {ButtonVariant, ButtonWidthVariant} from '../../../../../../common/constants/enams'

import IncidentBlockModal from '../IncidentBlockModal/IncidentBlockModal'
import type {IIncidentBlock} from './interfaces/IIncidentBlock'

import avatar from 'src/app/common/assets/images/avatar1.png'

import styles from './sass/IncidentBlock.module.scss'

import {useDeleteContentItem} from '../../queries/deleteContentItem'
import {useCurrentUser} from '../../../../../../common/queries/getCurrentUser'
import mainApi from '../../../../../../../api/mainApi'
import {
  useFetchContentItemQuery,
  useGetAnalysisCategoriesQuery,
} from '../../../../../../common/store/slices/contentItems/apis/contentItems'
import contentItemsSelector from '../../../../../../common/store/slices/contentItems/selectors'

export default function IncidentBlock({activeIncident}: IIncidentBlock): ReactElement {
  const dispatch = useDispatch()
  const [typeId, setTypeId] = useState<string>(activeIncident?.contentItemTypeId || '')
  const [statusId, setStatusId] = useState<string>(activeIncident?.contentItemStatusId || '')
  const user = useCurrentUser()
  const isAdmin = user.data?.roles?.includes('Admin') ?? false
  const {isModalOpen} = useSelector(contentItemsSelector)
  const deleteItem = useDeleteContentItem()

  useEffect(() => {
    setTypeId(activeIncident?.contentItemTypeId || '')
    setStatusId(activeIncident?.contentItemStatusId || '')
  }, [activeIncident])

  const activeIncidentId = activeIncident ? activeIncident.id : ''

  const {data} = useFetchContentItemQuery({id: activeIncidentId})
  const {data: analysisCategories} = useGetAnalysisCategoriesQuery({
    statusId,
    typeId,
  })

  const handleDelete = () => {
    deleteItem.mutate(activeIncidentId, {onSuccess: () => dispatch(mainApi.util.resetApiState())})
  }

  return (
    <>
      <div className={styles.incidentBlock}>
        <div className={styles.incidentBlockHeader}>
          <div className={styles.titleBlock}>
            <div className={styles.avatar}>
              <img src={avatar} alt='avatar' />
            </div>
            <Subtitle text={activeIncident ? activeIncident?.subject : ''} />
          </div>
          <div className={styles.contextButtons}>
            {/* {analysisCategories ? (
              <Button
                title='AI'
                variant={ButtonVariant.PRIMARY}
                icon={book}
                widthVariant={ButtonWidthVariant.AI}
                onClick={() => dispatch(toggleModal())}
              />
            ) : null} */}
            {isAdmin && (
              <Button
                title='Delete'
                variant={ButtonVariant.GRAY_TEXT}
                widthVariant={ButtonWidthVariant.EXPLORE}
                onClick={handleDelete}
              />
            )}
          </div>
        </div>
        <div className={styles.contentBlock}>
          <p className={styles.content}>{activeIncident ? activeIncident?.content : ''}</p>
        </div>
      </div>
      {isModalOpen ? (
        <IncidentBlockModal
          isModalOpen={isModalOpen}
          activeIncident={data || null}
          analysisCategories={analysisCategories || null}
        />
      ) : null}
    </>
  )
}

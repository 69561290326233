import {useDispatch} from 'react-redux'
import {useMutation} from '@tanstack/react-query'

import {ContentItemService} from '../../../api'
import {queryClient} from '../../../libs/react-query'
import mainApi from '../../../api/mainApi'

export const useCreateContentItem = () => {
  const dispatch = useDispatch()
  return useMutation({
    mutationFn: ContentItemService.contentItemCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['contentItems']})
      dispatch(mainApi.util.resetApiState())
    },
  })
}

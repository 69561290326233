/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {AnalysisCategory} from '../models/AnalysisCategory'
import type {AnalysisCategoryDto} from '../models/AnalysisCategoryDto'
import type {AssignAnalysisPromptToAnalysisCategoryCommand} from '../models/AssignAnalysisPromptToAnalysisCategoryCommand'
import type {AssignAnalysisPromptToContentItemStatusCommand} from '../models/AssignAnalysisPromptToContentItemStatusCommand'
import type {AssignRoleToAnalysisCategoryCommand} from '../models/AssignRoleToAnalysisCategoryCommand'
import type {AssignRoleToContentItemTypeCommand} from '../models/AssignRoleToContentItemTypeCommand'
import type {AssignStatusToContentItemTypeCommand} from '../models/AssignStatusToContentItemTypeCommand'
import type {ContentItem} from '../models/ContentItem'
import type {ContentItemStatus} from '../models/ContentItemStatus'
import type {ContentItemType} from '../models/ContentItemType'
import type {CreateAnalysisCategoryCommand} from '../models/CreateAnalysisCategoryCommand'
import type {CreateContentItemCommand} from '../models/CreateContentItemCommand'
import type {CreateContentItemStatusCommand} from '../models/CreateContentItemStatusCommand'
import type {CreateContentItemTypeCommand} from '../models/CreateContentItemTypeCommand'
import type {IdentityRole} from '../models/IdentityRole'
import type {PaginatedResultOfContentItem} from '../models/PaginatedResultOfContentItem'
import type {UnAssignAnalysisPromptToAnalysisCategoryCommand} from '../models/UnAssignAnalysisPromptToAnalysisCategoryCommand'
import type {UnAssignAnalysisPromptToContentItemStatusCommand} from '../models/UnAssignAnalysisPromptToContentItemStatusCommand'
import type {UnAssignRoleToAnalysisCategoryCommand} from '../models/UnAssignRoleToAnalysisCategoryCommand'
import type {UnAssignRoleToContentItemTypeCommand} from '../models/UnAssignRoleToContentItemTypeCommand'
import type {UpdateAnalysisCategoryCommand} from '../models/UpdateAnalysisCategoryCommand'
import type {UpdateContentItemCommand} from '../models/UpdateContentItemCommand'
import type {UpdateContentItemStatusCommand} from '../models/UpdateContentItemStatusCommand'
import type {UpdateContentItemTypeCommand} from '../models/UpdateContentItemTypeCommand'

import type {CancelablePromise} from '../core/CancelablePromise'
import {OpenAPI} from '../core/OpenAPI'
import {request as __request} from '../core/request'

export class ContentItemService {
  /**
   * @param pageNumber
   * @param pageSize
   * @param statusId
   * @param typeId
   * @param searchInput
   * @returns PaginatedResultOfContentItem
   * @throws ApiError
   */
  public static contentItemGet(
    pageNumber?: number,
    pageSize?: number,
    statusId?: string | null,
    typeId?: string,
    searchInput?: string | null
  ): CancelablePromise<PaginatedResultOfContentItem> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem',
      query: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        StatusId: statusId,
        TypeId: typeId,
        SearchInput: searchInput,
      },
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemCreate(
    requestBody: CreateContentItemCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns ContentItem
   * @throws ApiError
   */
  public static contentItemGetById(id: string): CancelablePromise<ContentItem> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUpdateContentItem(
    id: string,
    requestBody: UpdateContentItemCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ContentItem/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static contentItemDeleteContentItem(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param dashboardId
   * @returns ContentItemType
   * @throws ApiError
   */
  public static contentItemGetContentItemTypes(
    dashboardId?: string | null
  ): CancelablePromise<Array<ContentItemType>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/types',
      query: {
        dashboardId: dashboardId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemCreate2(
    requestBody: CreateContentItemTypeCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/types',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUpdateType(
    requestBody: UpdateContentItemTypeCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ContentItem/types',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns ContentItemStatus
   * @throws ApiError
   */
  public static contentItemGetContentItemStatuses(
    id: string
  ): CancelablePromise<Array<ContentItemStatus>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/types/{id}/statuses',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns IdentityRole
   * @throws ApiError
   */
  public static contentItemGetContentItemTypeRoles(
    id: string
  ): CancelablePromise<Array<IdentityRole>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/types/{id}/roles',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param statusId
   * @param typeId
   * @returns AnalysisCategory
   * @throws ApiError
   */
  public static contentItemGetAnalysisCategories(
    statusId?: string | null,
    typeId?: string | null
  ): CancelablePromise<Array<AnalysisCategory>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/analysisCategories',
      query: {
        statusId: statusId,
        typeId: typeId,
      },
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemCreate3(
    requestBody: CreateAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/analysisCategories',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUpdateAnalysisCategory(
    requestBody: UpdateAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ContentItem/analysisCategories',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @returns IdentityRole
   * @throws ApiError
   */
  public static contentItemGetAnalysisCategoriesRoles(
    id: string
  ): CancelablePromise<Array<IdentityRole>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/analysisCategories/{id}/roles',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns AnalysisCategoryDto
   * @throws ApiError
   */
  public static contentItemGetAnalysisCategoryById(
    id: string
  ): CancelablePromise<AnalysisCategoryDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/analysisCategories/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns string
   * @throws ApiError
   */
  public static contentItemDeleteAnalysisCategory(id: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/analysisCategories/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns ContentItemType
   * @throws ApiError
   */
  public static contentItemGetContentItemTypeById(id: string): CancelablePromise<ContentItemType> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/types/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns string
   * @throws ApiError
   */
  public static contentItemDeleteType(id: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/types/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns ContentItemStatus
   * @throws ApiError
   */
  public static contentItemGetContentItemStatusById(
    id: string
  ): CancelablePromise<ContentItemStatus> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/ContentItem/statuses/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns string
   * @throws ApiError
   */
  public static contentItemDeleteStatus(id: string): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/statuses/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemCreate4(
    requestBody: CreateContentItemStatusCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/statuses',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUpdateStatus(
    requestBody: UpdateContentItemStatusCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/ContentItem/statuses',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static contentItemAssignPromptToStatus(
    requestBody: AssignAnalysisPromptToContentItemStatusCommand
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/statuses/assignPrompt',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static contentItemUnAssignPromptToStatus(
    requestBody: UnAssignAnalysisPromptToContentItemStatusCommand
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/statuses/unAssignPrompt',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemAssignStatusToType(
    requestBody: AssignStatusToContentItemTypeCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/types/assignStatus',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemAssignRolesToType(
    requestBody: AssignRoleToContentItemTypeCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/types/assignRoles',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUnAssignRolesFromType(
    requestBody: UnAssignRoleToContentItemTypeCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/types/unAssignRoles',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemAssignRolesToCategory(
    requestBody: AssignRoleToAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/categories/assignRoles',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUnAssignRolesToCategory(
    requestBody: UnAssignRoleToAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/categories/unAssignRoles',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemAssignPromptToCategory(
    requestBody: AssignAnalysisPromptToAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/analysisCategories/assignPrompt',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemUnAssignPromptToCategory(
    requestBody: UnAssignAnalysisPromptToAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/ContentItem/analysisCategories/unAssignPrompt',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param requestBody
   * @returns string
   * @throws ApiError
   */
  public static contentItemAssignPromptToType(
    requestBody: AssignAnalysisPromptToAnalysisCategoryCommand
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/types/assignPrompt',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param id
   * @param serviceId
   * @param resetData
   * @returns string
   * @throws ApiError
   */
  public static contentItemGenerateAnalysis(
    id: string,
    serviceId?: string,
    resetData?: boolean
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/ContentItem/{id}/generate-analysis',
      path: {
        id: id,
      },
      query: {
        serviceId: serviceId,
        resetData: resetData,
      },
    })
  }
}

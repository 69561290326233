import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface DietaryOverview {
  dietType: string;
  texture: string;
  caloricRequirement: string;
  fluidRequirement: string;
}

interface NutritionalGoal {
  goal: string;
  description: string;
}

interface DietaryRestriction {
  type: string;
  details: string;
}

interface DietaryPreference {
  foodPreference: string;
  comments: string;
}

interface MealPlan {
  meal: string;
  time: string;
  menu: string;
  notes: string;
}

interface NutritionalMonitoring {
  date: string;
  weight: string;
  bmi: string;
  bloodGlucose: string;
  bloodPressure: string;
  comments: string;
}

interface Feedback {
  date: string;
  feedback: string;
}

const dietaryOverview: DietaryOverview = {
  dietType: "Diabetic, Heart-Healthy",
  texture: "Regular",
  caloricRequirement: "1800-2000 calories/day",
  fluidRequirement: "1.5-2 liters/day"
};

const nutritionalGoals: NutritionalGoal[] = [
  { goal: "Blood Glucose Control", description: "Manage blood glucose levels through a balanced carbohydrate intake and portion control to support diabetes management." },
  { goal: "Cardiovascular Health", description: "Maintain a heart-healthy diet with low sodium, reduced saturated fats, and cholesterol to support hypertension and cholesterol management." },
  { goal: "Weight Management", description: "Maintain stable weight through appropriate caloric intake, avoiding weight loss or gain." },
  { goal: "Hydration", description: "Ensure adequate hydration to prevent urinary discomfort (due to recurrent UTIs) and support overall health." }
];

const dietaryRestrictions: DietaryRestriction[] = [
  { type: "Diabetic-friendly", details: "Controlled intake of simple carbohydrates and sugars (limit refined sugars, desserts, and sugary drinks)." },
  { type: "Low Sodium", details: "Sodium intake restricted to 1500-2000 mg/day (to manage hypertension)." },
  { type: "Low Cholesterol", details: "Avoid high-cholesterol foods (e.g., fried foods, full-fat dairy, processed meats)." },
  { type: "Low Saturated Fat", details: "Limit intake of foods high in saturated fats (e.g., butter, fatty cuts of meat, baked goods)." }
];

const dietaryPreferences: DietaryPreference[] = [
  { foodPreference: "Favorite Foods", comments: "Likes lean proteins (chicken, turkey), grilled fish, and vegetable dishes. Prefers meals with light seasoning and steamed vegetables." },
  { foodPreference: "Preferred Snacks", comments: "Enjoys fresh fruit (apples, berries), nuts, and whole wheat crackers. Prefers low-sugar options." },
  { foodPreference: "Preferred Drinks", comments: "Prefers water, herbal teas, and occasionally black coffee. Avoids sugary drinks and soft drinks." },
  { foodPreference: "Disliked Foods", comments: "Dislikes spicy or overly seasoned foods. Avoids shellfish and prefers to skip heavy sauces or gravies." }
];

const mealPlan: MealPlan[] = [
  { meal: "Breakfast", time: "07:30 AM", menu: "- Scrambled eggs (with spinach)\n- Whole wheat toast\n- Fresh fruit (apple slices)\n- Black coffee or herbal tea", notes: "Low-sugar breakfast option, good protein and fiber intake." },
  { meal: "Morning Snack", time: "10:00 AM", menu: "- Handful of unsalted almonds\n- Fresh berries", notes: "Light, low-sodium snack to maintain energy levels." },
  { meal: "Lunch", time: "12:30 PM", menu: "- Grilled chicken breast (skinless)\n- Steamed vegetables (broccoli, carrots)\n- Brown rice\n- Water or herbal tea", notes: "Balanced meal with lean protein, complex carbs, and vegetables." },
  { meal: "Afternoon Snack", time: "03:00 PM", menu: "- Low-fat yogurt (unsweetened)\n- Whole wheat crackers", notes: "Low-sugar, low-fat snack to manage blood sugar levels." },
  { meal: "Dinner", time: "06:30 PM", menu: "- Baked salmon (with lemon)\n- Quinoa salad (cucumber, tomatoes, olive oil)\n- Steamed green beans\n- Water or herbal tea", notes: "Heart-healthy dinner with omega-3 fatty acids from salmon." },
  { meal: "Evening Snack", time: "08:30 PM", menu: "- Fresh fruit (sliced pear)\n- Small handful of walnuts", notes: "Light snack with good fiber and healthy fats." }
];

const nutritionalMonitoring: NutritionalMonitoring[] = [
  { date: "August 1, 2024", weight: "72 kg", bmi: "23.5", bloodGlucose: "6.2 mmol/L", bloodPressure: "130/85 mmHg", comments: "Weight stable, blood glucose controlled. No issues with diet adherence." },
  { date: "August 15, 2024", weight: "72.2 kg", bmi: "23.6", bloodGlucose: "6.0 mmol/L", bloodPressure: "132/84 mmHg", comments: "Slight weight increase, but within normal range. Diet and hydration on track." },
  { date: "August 29, 2024", weight: "72 kg", bmi: "23.5", bloodGlucose: "6.3 mmol/L", bloodPressure: "130/82 mmHg", comments: "Stable weight, glucose levels within target. Hydration needs met daily." }
];

const residentFeedback: Feedback[] = [
  { date: "August 5, 2024", feedback: "John expressed a preference for more fresh vegetables in his meals. The dietitian increased the variety of steamed vegetables offered during lunch and dinner." },
  { date: "August 18, 2024", feedback: "John requested to reduce the frequency of black coffee due to occasional heartburn. Herbal teas were provided as an alternative for most meals." },
  { date: "August 25, 2024", feedback: "John mentioned enjoying the addition of quinoa to his meals, requesting it be included more frequently in his lunch and dinner options." }
];

export const DieteryManagement = () => {
  return (
    <div className="p-card p-5">
      <h2>Dietary Management Profile: John Smith</h2>

      <div className="flex flex-col gap-10">
        <ul>
          <li><strong>Diet Type:</strong> {dietaryOverview.dietType}</li>
          <li><strong>Texture:</strong> {dietaryOverview.texture}</li>
          <li><strong>Caloric Requirement:</strong> {dietaryOverview.caloricRequirement}</li>
          <li><strong>Fluid Requirement:</strong> {dietaryOverview.fluidRequirement}</li>
        </ul>

        {nutritionalGoals.map((goal, index) => (
          <div key={index}>
            <h4>{goal.goal}</h4>
            <p>{goal.description}</p>
          </div>
        ))}

        <DataTable value={dietaryRestrictions} paginator rows={5} responsiveLayout="scroll">
          <Column field="type" header="Type" />
          <Column field="details" header="Details" />
        </DataTable>

        <DataTable value={dietaryPreferences} paginator rows={5} responsiveLayout="scroll">
          <Column field="foodPreference" header="Food Preferences" />
          <Column field="comments" header="Comments" />
        </DataTable>

        <DataTable value={mealPlan} paginator rows={5} responsiveLayout="scroll">
          <Column field="meal" header="Meal" />
          <Column field="time" header="Time" />
          <Column field="menu" header="Menu" />
          <Column field="notes" header="Notes" />
        </DataTable>

        <p><strong>Daily Fluid Intake Target:</strong> 1.5-2 liters/day</p>
        <p><strong>Preferred Beverages:</strong> Water, herbal teas, occasionally black coffee.</p>
        <p><strong>Monitoring:</strong> Ensure regular fluid intake throughout the day, especially important for preventing urinary discomfort related to UTIs. Limit caffeinated beverages to avoid dehydration.</p>

        <DataTable value={nutritionalMonitoring} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="weight" header="Weight" />
          <Column field="bmi" header="BMI" />
          <Column field="bloodGlucose" header="Blood Glucose (Fasting)" />
          <Column field="bloodPressure" header="Blood Pressure" />
          <Column field="comments" header="Comments" />
        </DataTable>
        n                   <ul>
          <li><strong>Diabetes:</strong> John Smith's diet is closely monitored to ensure carbohydrate intake is balanced and does not cause spikes in blood glucose levels.</li>
          <li><strong>Hypertension and Cholesterol:</strong> A low-sodium, heart-healthy diet helps manage John Smith’s blood pressure and cholesterol levels.</li>
          <li><strong>Weight Maintenance:</strong> Maintaining a stable weight is a key focus.</li>
          <li><strong>Hydration:</strong> Given John Smith’s history of recurrent urinary tract infections, maintaining adequate hydration is critical.</li>
        </ul>

        <DataTable value={residentFeedback} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="feedback" header="Feedback" />
        </DataTable>

        <ul>
          <li><strong>Continue Monitoring Blood Glucose:</strong> Ensure that carbohydrate portions are well-controlled during meals, particularly breakfast.</li>
          <li><strong>Increase Omega-3 Rich Foods:</strong> Consider increasing the frequency of salmon or other fatty fish to twice a week to support cardiovascular health.</li>
          <li><strong>Monitor Hydration Closely:</strong> Continue emphasizing hydration to support urinary health.</li>
          <li><strong>Snack Variety:</strong> Consider introducing more variety in snacks, such as hummus with vegetables or low-sugar granola bars.</li>
        </ul>

        <p>John Smith follows a diabetic-friendly, heart-healthy diet that aligns with his medical conditions, including type 2 diabetes, hypertension, and hypercholesterolemia. His meals are carefully portioned, with a focus on lean proteins, whole grains, and fresh vegetables. Regular monitoring of blood glucose and weight shows stable trends, and his hydration needs are being met consistently. John is compliant with his dietary plan and provides regular feedback on his preferences.</p>
        <p><strong>Next Dietary Review:</strong> October 1, 2024, or sooner if any significant changes in health status occur.</p>
      </div>
    </div>
  );
};

import {Button} from 'primereact/button'
import {Card} from 'primereact/card'
import {Timeline} from 'primereact/timeline'
import {spaceMargin} from '../../constants/styling'
import {timelineData} from '../../timelineData'
import {DataCard2} from '../dataCard'
import {Charts} from 'src/app/modules/widgets/components/Charts'
import {ImageCard} from './imageCard'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {ChartsWidget3} from 'src/_metronic/partials/widgets'

interface TimelineEvent {
  status?: string
  date?: string
  icon?: string
  color?: string
  image?: string
  content?: string
}
export const Home = () => {
  const events: TimelineEvent[] = timelineData

  const customizedContent = (item: TimelineEvent) => {
    return (
      <Card title={item.status} subTitle={item.date}>
        {/* {item.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`}
            alt={item.image}
            width={200}
            className='shadow-1'
          />
        )} */}
        <p>{item.content}</p>
        <Button label='Read more' className='p-button-text'></Button>
      </Card>
    )
  }

  const customizedMarker = (item: TimelineEvent) => {
    return (
      <span
        //className='flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1'
        style={{
          backgroundColor: item.color,
          height: '2rem',
          width: '2rem',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          color: 'white',
        }}
      >
        <i className={item.icon}></i>
      </span>
    )
  }

  return (
    <div className='flex'>
      <div className='card w-60 center'>
        <div>
          <div className='flex  gap-3 flex-wrap'>
            <ImageCard image='Blood Glucose.jpg' />
            <ImageCard image='Blood Presure.jpg' />
            <ImageCard image='Body Mass.jpg' />
            <ImageCard image='Body Temperature.jpg' />
            <ImageCard image='Heart Rate.jpg' />
            <ImageCard image='Oxygen Saturation.jpg' />
            <ImageCard image='Respiration Rate.jpg' />
            <ImageCard image='Respiratory Volume.jpg' />
            <ImageCard image='Weight.jpg' />
          </div>
          <div className='mt-2'>
            <ChartsWidget3 className='card-xl-stretch mb-xl-8' />
          </div>
        </div>
      </div>
      <div style={{width: '40%', marginLeft: spaceMargin}}>
        <div className='card h-80vh'>
          <div style={{overflow: 'auto'}}>
            <Timeline
              value={events}
              align='alternate'
              className='customized-timeline'
              marker={customizedMarker}
              content={customizedContent}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

import {
  AIAssistViewComponent,
  PromptRequestEventArgs,
  ToolbarItemClickedEventArgs,
  ToolbarSettingsModel,
} from '@syncfusion/ej2-react-interactive-chat'
import * as React from 'react'
import {useCallback, useEffect} from 'react'
import {AssistPromptHistory, ChatSession} from 'src/api/services/ChatSessionService'
import showdown from 'showdown'
import InputBlock from 'src/app/pages/AskMe/pages/Chat/components/InputBlock/InputBlock'
import styles from './PlaygroundView.module.scss'
import {useAppSelector} from 'src/app/pages/AskMe/store/hooks/useApp'
import chatSelector from 'src/app/pages/AskMe/store/slices/chat/selectors'
import useLoader from 'src/app/common/hooks/useLoader/useLoader'
import DotsLoader from 'src/app/components/DotsLoader/DotsLoader'

interface Props {
  session: ChatSession
  executionPrompt?: {prompt: string; actionId: string}
  onPrompt: (prompt: string, actionId?: string) => Promise<string | {error: string}>
  onAddToCompletion?: (response: string) => void
}

const converter = new showdown.Converter({tables: true})

const PlaygroundView = ({session, executionPrompt, onPrompt, onAddToCompletion}: Props) => {
  const [prompt, setPrompt] = React.useState<string>('test')
  const assistInstance = React.useRef<AIAssistViewComponent>(null)
  const {message} = useAppSelector(chatSelector)
  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    const viewElement = document.getElementsByClassName('e-views')[0]
    if (viewElement) {
      setTimeout(() => {
        viewElement.scrollTo({top: viewElement.scrollHeight})
      }, 0)
    }
  }, [loading])

  useEffect(() => {
    if (message) {
      setPrompt(message)
    }
  }, [message])

  useEffect(() => {
    if (executionPrompt) {
      assistInstance.current?.executePrompt(executionPrompt.prompt)
    }
  }, [executionPrompt])

  const onPromptRequest = useCallback(
    (args: PromptRequestEventArgs) => {
      if (!args.prompt) {
        return
      }
      setLoading(true)

      onPrompt(args.prompt, executionPrompt?.actionId)
        .then((response) => {
          if (typeof response === 'object' && 'error' in response && assistInstance.current) {
            assistInstance.current.addPromptResponse(`Error: ${response.error}`)
            return
          }
          if (assistInstance.current) {
            const html = converter.makeHtml(response as string)
            assistInstance.current.addPromptResponse(html)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [executionPrompt?.actionId, onPrompt, setLoading]
  )

  const onToolbarItemClicked = (args: ToolbarItemClickedEventArgs) => {
    if (typeof args.dataIndex !== 'number') return
    const prompt = assistInstance.current?.prompts[args.dataIndex]
    if (!prompt?.response) return

    switch (args.item?.iconCss) {
      case 'e-icons e-copy':
        let html = new Blob([prompt.response], {type: 'text/html'})
        const data = new ClipboardItem({'text/html': html})
        navigator.clipboard.write([data]).then(() => {
          alert('Document copied to clipboard')
        })
        break
      case 'e-icons e-arrow-right':
        const responseHtml = converter.makeHtml(prompt.response)
        onAddToCompletion?.(responseHtml)
        break
    }
  }

  const responseToolbarSettings: ToolbarSettingsModel = {
    items: [
      {type: 'Button', iconCss: 'e-icons e-copy', tooltip: 'Copy response'},
      {
        type: 'Button',
        iconCss: 'e-icons e-arrow-right',
        tooltip: 'Add response to Completion',
        visible: !!onAddToCompletion,
      },
    ],
    itemClicked: (args) => onToolbarItemClicked(args),
  }

  const promptsMapper = (p: AssistPromptHistory) => {
    const {prompt} = JSON.parse(p.request)
    let response = p.response
    response = p.response?.split('\n').join('\r\n')
    const html = converter.makeHtml(response)
    return {prompt, response: html}
  }

  return (
    <>
      <AIAssistViewComponent
        id='assistPlayground'
        ref={assistInstance}
        prompts={session.assistPromptHistory?.map(promptsMapper) ?? []}
        promptRequest={onPromptRequest}
        footerTemplate={() => <div style={{minHeight: '50px'}}></div>}
        responseToolbarSettings={responseToolbarSettings}
      ></AIAssistViewComponent>
      <div className={styles.customFooter}>
        {!loading ? (
          <InputBlock
            searchTerm={prompt}
            setSearchTerm={(prompt) => {
              setPrompt(prompt)
            }}
            handleAddMessage={() => {
              setPrompt('')
              if (assistInstance.current) {
                assistInstance.current.prompts.push({prompt})
                onPromptRequest({prompt})
              }
            }}
            isMessageLoading={loading}
            setFiles={() => {}}
            files={[]}
            enableFiles={false}
            enableSuggestions={false}
          />
        ) : (
          <div className={styles.loader}>
            <DotsLoader />
          </div>
        )}
      </div>
    </>
  )
}

export default PlaygroundView

import {useQuery} from '@tanstack/react-query'
import {appConfigurationClient} from 'src/api/apiClient'

const getAppConfiguration = async () => {
  return await appConfigurationClient.getAppConfiguration()
}

export const useAppConfigurationQuery = () =>
  useQuery({
    queryKey: ['appConfiguration'],
    queryFn: () => getAppConfiguration(),
    staleTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  })

import type {IPaginationItemArrow} from '../interfaces/IPaginationItemArrow'

import {ReactComponent as ArrowIcon} from '../../../assets/icons/downArrow.svg'

import styles from '../sass/PaginationItemArrow.module.scss'

export default function PaginationItemArrow({
  btnNext = false,
  disabled,
  onClick,
}: IPaginationItemArrow) {
  return (
    <div
      className={disabled ? styles.disabled : ''}
      onClick={onClick}
      onKeyDown={onClick}
      role='presentation'
    >
      <div className={`${styles.container} ${btnNext ? styles.next : ''}`}>
        <ArrowIcon className={styles.arrow} />
      </div>
    </div>
  )
}

import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import {AskMeCategoryRolesResponse, RoleDto} from 'src/api/generatedClient'

const getRoles = async (categoryId: string) => {
  const response = await axios.get(`/api/admin/askmeroles/${categoryId}`)
  const roles = response.data as AskMeCategoryRolesResponse
  console.log('roles', roles)
  return roles
}

export const useAskMeRoles = (categoryId: string) =>
  useQuery({
    queryKey: ['roles', categoryId],
    queryFn: () => getRoles(categoryId),
    select: (data) =>
      data?.roles?.map(
        (role: any) =>
          ({
            id: role.id,
            name: role.name,
          } as RoleDto)
      ),
  })

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

const Topbar: FC = () => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  const formattedDate = new Date().toLocaleDateString('en-US', options)

  return (
    <div className='d-flex flex-shrink-0'>
      <div className='d-flex ms-3 align-items-center mr-3'>{formattedDate}</div>
      <div className='d-flex align-items-center ms-3'></div>
    </div>
  )
}

export {Topbar}

import DotsLoader from '../../components/DotsLoader/DotsLoader'
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage'
import {CHAT, USER} from '../../constants/chat'
import InputBlock from './components/InputBlock/InputBlock'
import Message from './components/Message/Message'

import type {ReactElement} from 'react'
import type {IChatView} from './interfaces/IChatView'

import userAvatar from '../../assets/images/avatar1.png'
import chatAvatar from '../../assets/images/short_logo.png'

import styles from './sass/ChatView.module.scss'

function ChatView({
  searchTerm,
  setSearchTerm,
  handleAddMessage,
  isLoading,
  messages,
  loaderRef,
  isFetching,
  isMessageLoading,
  chatError,
  files,
  setFiles,
  isCompact,
}: IChatView): ReactElement {
  const heightStyle = isCompact ? styles.chatHeightCompact : styles.chatHeightFull
  const containerWidth = isCompact ? 'w-100' : 'w-80'
  return (
    <div className={`${styles.chat} ${heightStyle}`}>
      <div className={`${styles.container} ${containerWidth}`}>
        {isLoading ? (
          <DotsLoader variant='centered' />
        ) : (
          <div className={styles.messages}>
            {messages.map(({id, message, isQuestion, createdAt}, index) => (
              <Message
                key={id}
                message={message}
                avatar={isQuestion ? userAvatar : chatAvatar}
                variant={isQuestion ? USER : CHAT}
                createdAt={createdAt}
                index={index}
              />
            ))}
            <div className={styles.loader} ref={loaderRef}>
              {isFetching ? <DotsLoader /> : null}
            </div>
          </div>
        )}
        <div className={styles.messageLoader}>{isMessageLoading ? <DotsLoader /> : null}</div>
        <ErrorMessage error={chatError} />
        <InputBlock
          files={files}
          setFiles={setFiles}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleAddMessage={handleAddMessage}
          isMessageLoading={isMessageLoading}
        />
      </div>
    </div>
  )
}

export default ChatView

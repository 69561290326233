import {Button} from 'primereact/button'
import {Divider} from 'primereact/divider'
import {toAbsoluteUrl} from 'src/_metronic/helpers'

export const Header = () => {
  return (
    <div className='h-18 bg-white p-1 justify-center flex'>
      <div className='flex items-center justify-center h-full px-6  max-w-[1650px] w-100'>
        <div className='flex items-center border-1 py-1 align-middle px-2'>
          <div className='flex items-center' style={{fontSize: '0.8rem'}}>
            <img src={toAbsoluteUrl('/media/demo/resident.png')} alt='logo' className='h-14' />
            <div className='ml-2' style={{color: '#717171'}}>
              <div>John Smith</div>
              <div className='flex'>998 | MALE | 07/02/1949</div>
            </div>
            <Divider layout='vertical' />
            <div className='flex flex-col gap-0 align-middle h-full'>
              <Button
                label='Consent'
                link
                icon='pi pi-clipboard'
                size='small'
                style={{padding: '2px', fontSize: '0.7rem'}}
              />
              <Button
                label='Profile'
                link
                icon='pi pi-user-edit'
                size='small'
                style={{padding: '2px', fontSize: '0.7rem'}}
              />
            </div>
            <Divider layout='vertical' />
            <div className='flex flex-col gap-1'>
              <div>
                <Button
                  label='RPM'
                  icon='pi pi-check'
                  rounded
                  size='small'
                  style={{
                    padding: '4px 6px',
                    borderRadius: '0.3rem',
                    backgroundColor: '#0078d4',
                    borderColor: '#0078d4',
                  }}
                />
              </div>
              <span style={{fontSize: '0.7rem'}}>24/08/2024, 07:36:03 PM</span>
            </div>
            <Divider layout='vertical' />
            <div className='flex gap-3'>
              <Button
                icon='pi pi-bell'
                className='rounded-full'
                outlined
                style={{
                  padding: '3px ',
                  width: '2rem',
                  color: '#0078d4',
                  borderColor: '#0078d4',
                }}
              />
              <Button
                icon='pi pi-phone'
                className='rounded-full'
                outlined
                style={{
                  padding: '3px ',
                  color: '#0078d4',
                  width: '2rem',
                  borderColor: '#0078d4',
                }}
              />
              <Button
                icon='pi pi-comment'
                className='rounded-full'
                outlined
                style={{
                  padding: '3px ',
                  width: '2rem',
                  borderColor: '#0078d4',
                  color: '#0078d4',
                }}
              />
            </div>
          </div>
        </div>
        <div className='flex items-center border-1'></div>
      </div>
    </div>
  )
}

import type {ReactElement} from 'react'
import type {IDotsLoader} from './interfaces/IDotsLoader'

import styles from './sass/DotsLoader.module.scss'

export default function DotsLoader({variant}: IDotsLoader): ReactElement {
  return (
    <div className={`${styles.dotsLoader} ${variant ? styles[variant] : ''}`}>
      <span className={styles.dot} />
      <span className={styles.dot} />
      <span className={styles.dot} />
    </div>
  )
}

import {Button} from 'primereact/button'
import {Menu} from 'primereact/menu'
import {useState} from 'react'
import './reboot.scss'
import ResizableSidebar from 'src/app/components/sidebar'
import './index.styles.scss'
import {PrimeReactProvider} from 'primereact/api'
import {AskMeContent} from './components/askMe'
import {navItems} from './constants/navItems'
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom'
import {Home} from './components/home'
import {ProgressNotes} from './components/progressNotes'
import {MenuItemCommandEvent} from 'primereact/menuitem'
import {Incidents} from './components/incidents'
import {CarePlan} from './components/carePlan'
import {Profile} from './components/profile'
import {PainManagement} from './components/painManagement'
import {NurseCall} from './components/nurseCall'
import {MedicatonManagement} from './components/medicationManagement'
import {SocialAndRecreational} from './components/socialAndReacreational'
import {DieteryManagement} from './components/dieteryManagement'
import {EmergencyProtocols} from './components/emergencyProtocols'
import {Feedback} from './components/feedback'
import {Financial} from './components/financial'
import {TimelineView} from './components/timeline'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {Header} from './components/header'

const spaceMargin = '0.5rem'

export const Overview = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const handleAskMeOpen = () => {
    setIsSidebarOpen(true)
  }

  const handleNavClick = (event: MenuItemCommandEvent) => {
    navigate(event.item.id ?? '')
  }
  return (
    <PrimeReactProvider>
      <div style={{height: '100%', backgroundColor: '#e9e9e9'}}>
        <Header />
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{width: '100%', backgroundColor: '#e9e9e9', maxWidth: '1650px'}}>
            <div style={{margin: spaceMargin, display: 'flex'}}>
              <div>
                <Menu model={navItems(handleNavClick)} />
              </div>
              <div className={'content'}>
                <Routes>
                  <Route element={<Home />} path='home' />
                  <Route element={<ProgressNotes />} path='progressnotes' />
                  <Route element={<Incidents />} path='incidents' />
                  <Route element={<CarePlan />} path='careplan' />
                  <Route element={<Profile />} path='profile' />
                  <Route element={<PainManagement />} path='painmanagement' />
                  <Route element={<NurseCall />} path='nursecall' />
                  <Route element={<MedicatonManagement />} path='medicationmanagement' />
                  <Route element={<SocialAndRecreational />} path='socialandrecreational' />
                  <Route element={<DieteryManagement />} path='dieterymanagement' />
                  <Route element={<EmergencyProtocols />} path='emergencyProtocols' />
                  <Route element={<Feedback />} path='feedback' />
                  <Route element={<Financial />} path='financial' />
                  <Route element={<TimelineView />} path='timeline' />
                  <Route element={<Navigate to='home' />} path='*' />
                </Routes>
              </div>
              <ResizableSidebar
                visible={isSidebarOpen}
                onHide={() => {
                  setIsSidebarOpen(false)
                }}
                storageKey='overview-ask-me'
                position='right'
              >
                <AskMeContent />
              </ResizableSidebar>
            </div>
          </div>
        </div>
        <div className='fixed bottom-[10rem] right-5'>
          <Button
            rounded
            text
            icon={<img src='/media/logos/Main Menu_ Overview.png' height={44} width={44} />}
            onClick={handleAskMeOpen}
          />
        </div>
      </div>
    </PrimeReactProvider>
  )
}

type DataCardProps = {
  title: string
  value: string
}
const DataCard = ({title, value}: DataCardProps) => {
  return (
    <div className='flex flex-center' style={{flexDirection: 'column', justifyContent: 'center'}}>
      <div
        style={{display: 'flex', justifyContent: 'center', marginBottom: '1rem', fontWeight: '600'}}
      >
        {title}
      </div>
      <div
        style={{
          color: 'green',
          fontSize: '14',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {value}
      </div>
    </div>
  )
}

import type {ReactElement} from 'react'
import type IArrow from './interfaces/IArrow'

import arrow from 'src/app/common/assets/icons/whiteSidebar/arrow.svg'

import styles from './sass/Arrow.module.scss'

export default function Arrow({isOpen}: IArrow): ReactElement {
  return (
    <span className={`${styles.arrow} ${isOpen ? styles.open : ''}`}>
      <img src={arrow} alt='arrow' />
    </span>
  )
}

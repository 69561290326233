import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {format} from 'date-fns'

import {useAppSelector} from '../../../../store/hooks/useApp'
import categoriesSelector from '../../../../store/slices/categories/selectors'

import Categories from '../../../../components/Categories/Categories'
import {CHAT} from '../../../../constants/chat'
import StandardsIconsBlock from '../StandardsIconsBlock/StandardsIconsBlock'

import type {ReactElement} from 'react'
import type {IMessageProps} from './interfaces/IMessage'

import styles from './sass/Message.module.scss'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function Message({
  avatar,
  message,
  createdAt,
  variant,
  index,
}: IMessageProps): ReactElement {
  const {t} = useTranslation()
  const [isPositiveReview, setIsPositiveReview] = useState<boolean | undefined>(undefined)
  const date = `${t('chat.lastGenerationDate')} ${format(createdAt, 'dd/MM/yyyy')}`
  const {category, subCategory} = useAppSelector(categoriesSelector)

  return (
    <div
      id={`message-${index}`}
      className={`${styles.message} ${variant === CHAT ? styles.chatMessage : styles.userMessage}`}
    >
      <div className={styles.avatarBlock}>
        <img
          src={avatar}
          alt='avatar'
          className={`${variant === CHAT ? styles.chatAvatar : styles.userAvatar}`}
        />
        {variant === CHAT ? (
          <Categories category={category?.name || ''} subCategory={subCategory?.name || ''} />
        ) : (
          <p className={styles.title}>{t('chat.you')}</p>
        )}
      </div>
      <div
        className={`${styles.messageContent} ${
          variant === CHAT ? styles.chatMessage : styles.userMessage
        }`}
      >
        {variant === CHAT ? (
          <p className={`${styles.messageText} ${styles.chatMessage}`}>
            <Markdown remarkPlugins={[remarkGfm]}>{message}</Markdown>
          </p>
        ) : (
          <p className={styles.messageText}>{message}</p>
        )}
        {variant === CHAT && (
          <div className={styles.dateBlock}>
            <div className={styles.date}>{date}</div>
            <StandardsIconsBlock
              isPositiveReview={isPositiveReview}
              setIsPositiveReview={setIsPositiveReview}
              message={message}
            />
          </div>
        )}
      </div>
    </div>
  )
}

import {useParams} from 'react-router-dom'
import {Chip} from 'primereact/chip'
import {
  DataTable,
  DataTableFilterMeta,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {useEffect, useState} from 'react'
import {FilterMatchMode} from 'primereact/api'
import {IconField} from 'primereact/iconfield'
import {InputIcon} from 'primereact/inputicon'
import {InputText} from 'primereact/inputtext'
import {useRoles} from 'src/queries/getRoles'
import {useAskMeRoles} from '../api/getCategoryRoles'
import {RoleDto, UpdateCategoryRolesCommand} from 'src/api/generatedClient'
import {useUpdateCategoryRoles} from '../api/updateCategoryRoles'

export const RolesView = () => {
  const roles = useRoles({page: 1, pageSize: 40, search: ''})
  const {categoryId} = useParams()
  const categoryRoles = useAskMeRoles(categoryId ?? '')
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })
  const updateCategoryRoles = useUpdateCategoryRoles()
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const handleSelect = (e: DataTableSelectionMultipleChangeEvent<RoleDto[]>) => {
    const roles = e.value ?? []
    const roleIds = roles.map((role) => role.name!)
    const request = {categoryId: categoryId ?? '', roles: roleIds} as UpdateCategoryRolesCommand
    updateCategoryRoles.mutate(request)
  }
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = {...filters}

    // @ts-ignore
    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }
  useEffect(() => {
    console.log(categoryRoles.data)
  }, [categoryRoles.data])

  const renderHeader = () => {
    return (
      <div className='flex justify-content-end'>
        <IconField iconPosition='left'>
          <InputIcon className='pi pi-search' />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Roles Search'
          />
        </IconField>
      </div>
    )
  }

  const header = renderHeader()
  return (
    <div>
      <h3>Assigned Roles</h3>
      <div style={{display: 'flex', gap: '0.5rem', marginTop: '2rem'}}>
        {categoryRoles.data?.map((role) => (
          <div key={role.id}>
            <Chip label={role.name ?? ''} />
          </div>
        ))}
      </div>

      <h3 style={{marginTop: '3rem'}}>Assign Roles</h3>
      <DataTable
        value={roles.data?.items ?? []}
        globalFilterFields={['name']}
        selection={categoryRoles.data ?? []}
        dataKey='id'
        onSelectionChange={handleSelect}
        selectionMode={null}
        filters={filters}
        header={header}
      >
        <Column selectionMode='multiple' headerStyle={{width: '3rem'}} />

        <Column sortable field='name' header='Name' />
      </DataTable>
    </div>
  )
}

import type {
  IPaginationData,
  IPaginationForceRefetch,
  IPaginationSerializeQueryArgs,
} from './interfaces/IPaginationHelpers'

export const paginationMerge = <T>(
  currentData: IPaginationData<T>,
  newData: IPaginationData<T>
) => {
  const mergedData = newData
  // clear old cache if we are on the first page
  if (newData?.pageNumber === 1) {
    return newData
  }
  mergedData.data.unshift(...currentData.data)

  return mergedData
}

export const paginationSerializeQueryArgs = ({
  endpointName,
  queryArgs,
}: IPaginationSerializeQueryArgs) => {
  const {pageNumber, skip, ...other} = queryArgs
  return `${endpointName}(${JSON.stringify(other)})`
}

export const paginationForceRefetch = ({currentArg, previousArg}: IPaginationForceRefetch) =>
  currentArg?.pageNumber !== previousArg?.pageNumber || currentArg?.skip !== previousArg?.skip

import {useMutation} from '@tanstack/react-query'
import {appConfigurationClient} from 'src/api/apiClient'
import {FileParameter} from 'src/api/generatedClient'
import {queryClient} from 'src/libs/react-query'

export type CreateAppConfigurationOptions = {
  logoUrl: string | null | undefined
  disclaimerLineOne: string | null | undefined
  disclaimerLineTwo: string | null | undefined
  headerColor: string | null | undefined
  isInsightEnabled: boolean | undefined
  isAskMeEnabled: boolean | undefined
  isAssistEnabled: boolean | undefined
  isOneViewEnabled: boolean | undefined
  loginLogoUrl: string | null | undefined
  loginBackgroundUrl: string | null | undefined
  logo: FileParameter | null | undefined
  loginLogo: FileParameter | null | undefined
  loginBackground: FileParameter | null | undefined
}

const createAppConfiguration = async ({
  logoUrl,
  disclaimerLineOne,
  disclaimerLineTwo,
  headerColor,
  isInsightEnabled,
  isAskMeEnabled,
  isAssistEnabled,
  isOneViewEnabled,
  loginLogoUrl,
  loginBackgroundUrl,
  logo,
  loginLogo,
  loginBackground,
}: CreateAppConfigurationOptions) => {
  console.log(
    'Creating app configuration',
    logoUrl,
    disclaimerLineOne,
    disclaimerLineTwo,
    headerColor,
    isInsightEnabled,
    isAskMeEnabled,
    isAssistEnabled,
    isOneViewEnabled,
    loginLogoUrl,
    loginBackgroundUrl,
    logo,
    loginLogo,
    loginBackground
  )
  return await appConfigurationClient.createAppConfiguration(
    logoUrl,
    disclaimerLineOne,
    disclaimerLineTwo,
    headerColor,
    isInsightEnabled,
    isAskMeEnabled,
    isAssistEnabled,
    isOneViewEnabled,
    loginLogoUrl,
    loginBackgroundUrl,
    logo,
    loginLogo,
    loginBackground
  )
}

export const useCreateAppConfiguration = () =>
  useMutation({
    mutationFn: (options: CreateAppConfigurationOptions) => createAppConfiguration(options),
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['appConfiguration']}),
  })

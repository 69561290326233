import {axiosMiddleware, lexiApi} from './axiosMiddleware'
import {
  AppConfigurationClient,
  AskMeRolesClient,
  FilesClient,
  IAppConfigurationClient,
  IAskMeRolesClient,
  IFilesClient,
  IPythiaClient,
  PythiaClient,
} from './generatedClient'
const baseUrl = ''

export const pythiaClient: IPythiaClient = new PythiaClient(baseUrl, axiosMiddleware)
export const filesClient: IFilesClient = new FilesClient(baseUrl, axiosMiddleware)
export const askMeRolesClient: IAskMeRolesClient = new AskMeRolesClient(baseUrl, axiosMiddleware)
export const appConfigurationClient: IAppConfigurationClient = new AppConfigurationClient(
  baseUrl,
  axiosMiddleware
)

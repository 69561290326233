import {Chip} from 'primereact/chip'
import {Dialog} from 'primereact/dialog'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useCategories} from '../api/getCategories'
import {useNavigate, useParams} from 'react-router-dom'
import {useCreateCategory} from '../api/createCategory'
import {useDeleteCategory} from '../api/deleteCategory'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'

type FormData = {
  name: string
}
export const AddSubcategoryView = () => {
  const [visible, setVisible] = useState(false)
  const form = useForm<FormData>()
  const {categoryId} = useParams()
  const categories = useCategories()
  const category = categories.data?.find((category) => category.id === categoryId)
  const handleHide = () => setVisible(false)
  const deleteCategory = useDeleteCategory()
  const onClick = () => setVisible(true)
  const {register, handleSubmit} = form
  const createSubCategory = useCreateCategory()
  const onSubmit = (data: FormData) => {
    createSubCategory.mutate({...data, parentCategory: categoryId}, {onSuccess: handleHide})
  }
  const footerContent = (
    <div>
      <Button
        label='Cancel'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Submit'
        icon='pi pi-check'
        autoFocus
        type='submit'
        loading={createSubCategory.isPending}
        form={'typeForm'}
      />
    </div>
  )

  const onDeleteSubCategory = (subCategoryId: string) => {
    deleteCategory.mutate(subCategoryId)
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '80%',
          flexWrap: 'wrap',
          gap: '1rem',
          marginBottom: '2rem',
        }}
      >
        {category?.children.map((subCategory) => (
          <div key={subCategory.id}>
            <Chip
              label={subCategory.name}
              removable
              onRemove={() => onDeleteSubCategory(subCategory.id ?? '')}
            />
          </div>
        ))}
      </div>
      <Button label='Add Sub Category' onClick={onClick} />
      <Dialog
        header='Create Sub Category'
        visible={visible}
        style={{width: '30rem'}}
        onHide={handleHide}
        footer={footerContent}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={'typeForm'}>
          <div className='p-fluid'>
            <div className='p-field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' type='text' {...register('name', {required: true})} />
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}

import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface Feedback {
  date: string;
  feedbackType: string;
  feedback: string;
}

const residentFeedback: Feedback[] = [
  {
    date: "August 10, 2024",
    feedbackType: "Positive",
    feedback: "I really enjoy the music therapy sessions. Listening to classical music helps me relax and brings back good memories. I appreciate that the staff play my favorite pieces."
  },
  {
    date: "August 14, 2024",
    feedbackType: "Negative",
    feedback: "I’m not happy with the food lately. The meals feel a bit repetitive, and I’d like more variety in my dinners, especially more fresh vegetables."
  },
  {
    date: "August 22, 2024",
    feedbackType: "Negative",
    feedback: "It takes too long for someone to respond when I press the call button, especially in the evenings. I had to wait almost 10 minutes once when I needed help to use the bathroom."
  }
];

const daughterFeedback: Feedback[] = [
  {
    date: "August 18, 2024",
    feedbackType: "Positive",
    feedback: "I’m really happy with the way the staff take care of my dad, especially when it comes to his daily routines and social activities. He enjoys the reading group and feels more connected thanks to the attention given to his interests."
  },
  {
    date: "August 25, 2024",
    feedbackType: "Negative",
    feedback: "I’m concerned about the frequent medication changes not being communicated properly. There was a recent adjustment to his pain meds that I wasn’t informed about until days later."
  }
];

export const Feedback = () => {
  return (
    <div className="p-card p-5">
      <h2>Feedback Profile: John Smith</h2>

      <div className="flex flex-col gap-10">
        <DataTable value={residentFeedback} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="feedbackType" header="Feedback Type" />
          <Column field="feedback" header="Feedback" />
        </DataTable>

        <DataTable value={daughterFeedback} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="feedbackType" header="Feedback Type" />
          <Column field="feedback" header="Feedback" />
        </DataTable>
      </div >
    </div >
  );
};


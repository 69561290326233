import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { spaceMargin } from '../../constants/styling';

interface ProgressNote {
  noteTime: string;
  subject: string;
  authorRole: string;
  notes: string;
}

interface ResidentInfo {
  wing: string;
  room: string;
  resident: string;
}

const residentInfo: ResidentInfo = {
  wing: "Section Alpha",
  room: "B202",
  resident: "John Smith",
};

// Consolidate all progress notes
const notesData: ProgressNote[] = [
  { noteTime: "09:55:13", subject: "GP - Resident review", authorRole: "Doctor/General Practitioner", notes: "Resident remains well. Eating well. Has a new wheelchair - well-padded, though doesn't appear entirely comfortable. No PRNs administered last week. No new issues or concerns. No changes to care plan." },
  { noteTime: "11:07:48", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Right lower leg. Evaluation: Healing, reduced size, dry, no pain, and no signs of infection." },
  { noteTime: "11:37:41", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Mid-back. Evaluation: Dry wound bed, healing, no exudate, odor, or signs of infection." },
  { noteTime: "11:53:16", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Mid-back. Evaluation: Wound bed continues healing. No signs of infection or other concerns noted. Monitoring to continue." },
  { noteTime: "13:47:11", subject: "Wound evaluation", authorRole: "Enrolled Nurse, PCA/AIN/Care Companion", notes: "Wound location: Mid-back. Evaluation: Wound remains dry, no signs of infection, and no pain reported." },
  { noteTime: "13:53:31", subject: "Wound evaluation", authorRole: "Enrolled Nurse, PCA/AIN/Care Companion", notes: "Wound location: Right lower leg. Evaluation: Surrounding skin slightly red, no infection, and no pain reported." },
  { noteTime: "23:55:29", subject: "Weekly review", authorRole: "Registered Nurse", notes: "Resident has remained stable throughout the past week. GP review conducted - no changes. The wound on the mid-back and lower leg is healing well. No other concerns noted." },
  { noteTime: "05:30:35", subject: "Sleep", authorRole: "Registered Nurse", notes: "Resident had a restful sleep during this shift. No concerns raised." },
  { noteTime: "06:22:05", subject: "General unwell", authorRole: "Registered Nurse", notes: "Resident slept well during the shift. Asleep during all checks. No complaints of pain. Asleep ATOR (At Time of Report)." },
  { noteTime: "08:57:14", subject: "PRN Administration", authorRole: "Registered Nurse", notes: "PRN medication administered due to reported pain." },
  { noteTime: "09:16:30", subject: "Hospital leave, Unwell general, Family", authorRole: "Registered Nurse", notes: "Resident continued to report lower abdominal pain. This morning, hematuria and minor blood discharge from the genitals were observed. Pain rated at 6/10, increasing to 8/10 during voiding. Vitals stable, RAT test negative, no neurological changes. PRN administered before hospital transfer. Contacted NOK and arranged non-emergency transport for further investigations." },
  { noteTime: "09:34:54", subject: "Family, Appointments", authorRole: "Registered Nurse", notes: "Resident’s sister called to confirm a scheduled medical appointment." },
  { noteTime: "10:41:08", subject: "PRN evaluation", authorRole: "Registered Nurse", notes: "PRN medication administered earlier was effective, and Resident was calm before leaving for the hospital." },
  { noteTime: "12:11:27", subject: "Hospital contact", authorRole: "Registered Nurse", notes: "Contact from the hospital confirmed a suspected UTI. Resident is being treated with IV antibiotics and will be discharged with oral antibiotics." },
  { noteTime: "12:15:48", subject: "PRN evaluation", authorRole: "Registered Nurse", notes: "PRN medication from the previous day was effective, with Resident reporting less discomfort during urination." },
  { noteTime: "13:50:49", subject: "General unwell", authorRole: "Registered Nurse", notes: "Resident still experiencing dysuria and a continuous burning sensation. Reports seeing dry blood stains on undergarments. PRN medication administered for pain relief." },
  { noteTime: "14:47:51", subject: "PRN Administration", authorRole: "Registered Nurse", notes: "PRN medication given for groin pain." },
  { noteTime: "14:48:33", subject: "PRN evaluation", authorRole: "Registered Nurse", notes: "PRN medication effective, with Resident reporting no further pain or discomfort." },
  { noteTime: "15:00:05", subject: "Podiatry - General notes", authorRole: "Podiatrist", notes: "Right 1st ingrown toenail healed. No complaints or issues noted. Nails were elongated, skin dry, and fragile. Trimmed and filed all nails." },
  { noteTime: "15:56:41", subject: "Returned from hospital leave, General unwell, PRN Administration - Pain", authorRole: "Registered Nurse", notes: "Resident returned from the hospital after IV antibiotic treatment for suspected UTI. PRN medication administered for pain. Discharge summary uploaded." },
  { noteTime: "15:57:42", subject: "PRN Administration", authorRole: "Registered Nurse", notes: "PRN medication administered." },
  { noteTime: "16:29:24", subject: "PRN evaluation, General unwell", authorRole: "Registered Nurse", notes: "Moderate effect from PRN medication, still reports pain during voiding. No signs of hematuria or bleeding. Monitoring continues." },
  { noteTime: "17:35:36", subject: "Nurse initiated medication", authorRole: "Registered Nurse", notes: "Resident reports burning sensation during urination. Nurse-initiated Ural x2 given and fluids encouraged." },
  { noteTime: "17:48:13", subject: "Family", authorRole: "Registered Nurse", notes: "Resident’s sister called to confirm a CT referral is being arranged." },
  { noteTime: "21:56:35", subject: "Nurse initiated medication", authorRole: "Registered Nurse", notes: "Ural given for burning sensation was effective. No further concerns reported." },
  { noteTime: "02:19:39", subject: "Care plan review/evaluation, Global summary", authorRole: "Registered Nurse", notes: "Resident of the day reviewed. Podiatry appointment attended. Transferred to ED due to voiding pain and hematuria. Treated with IV antibiotics, discharged with oral antibiotics, and pain managed with PRN. Continence, mobility, behavior, personal hygiene, skin, and sleep status reviewed." },
  { noteTime: "05:21:13", subject: "Sleep", authorRole: "Registered Nurse", notes: "Resident slept soundly throughout the night. No concerns raised." },
  { noteTime: "11:25:29", subject: "Toileting, Continence, Pain management", authorRole: "PCA/AIN/Care Companion", notes: "Resident reports ongoing pain, particularly in the left testicle, and incontinence." },
  { noteTime: "12:21:12", subject: "PRN Administration - Pain", authorRole: "Registered Nurse", notes: "Resident reports burning sensation during urination and bladder pressure. PRN Ural administered." },
  { noteTime: "03:29:33", subject: "Skin integrity", authorRole: "Registered Nurse", notes: "Overnight skin check completed. No IAD (Incontinence-Associated Dermatitis) or PI (Pressure Injuries) noted." },
  { noteTime: "10:08:28", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Right arm. Evaluation: Wound healing well." },
  { noteTime: "11:11:54", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Left lower leg. Evaluation: No open wound or signs of infection. Wound healed, dry, and moisturizer applied." },
  { noteTime: "15:54:16", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Left lower leg. Evaluation: Wound healing continues, no infection noted." },
  { noteTime: "15:57:50", subject: "Wound evaluation", authorRole: "Registered Nurse", notes: "Wound location: Unspecified. Evaluation: Noted a skin tear, with a plan to review in 48 hours." },
  { noteTime: "17:35:36", subject: "Nurse initiated medication", authorRole: "Registered Nurse", notes: "Resident reports burning sensation during urination. Nurse-initiated Ural x2 given, and fluids encouraged." },
  { noteTime: "17:48:13", subject: "Family", authorRole: "Registered Nurse", notes: "Resident’s daughter called to confirm a CT referral is being arranged." },
  { noteTime: "20:52:18", subject: "Family, Post GP visit", authorRole: "Registered Nurse", notes: "GP reviewed Resident this evening and updated medication. No other changes." },
  { noteTime: "21:42:22", subject: "Family", authorRole: "Registered Nurse", notes: "GP visit completed, and NOK informed via text message." },
  { noteTime: "04:44:08", subject: "Acute", authorRole: "Registered Nurse", notes: "Checked on Resident, remains comfortable and resting in bed (RIB). No signs of discomfort." },
  { noteTime: "06:37:29", subject: "Acute, Pressure area care repositioning", authorRole: "Registered Nurse", notes: "Resident slept well overnight. Alert and appeared comfortable. Pressure area care performed x3. Pad changed. Vitals within normal limits (WNL). No signs of discomfort." },
];

export const ProgressNotes = () => {
  return (
    <div className="p-card px-5 py-3">
      <h2>Resident Progress Notes</h2>
      <div className="p-mb-4">
        <strong>Wing:</strong> {residentInfo.wing}<br />
        <strong>Room:</strong> {residentInfo.room}<br />
        <strong>Resident:</strong> {residentInfo.resident}
      </div>
      <div className='mt-5'>
        <DataTable value={notesData} paginator rows={10} responsiveLayout="scroll">
          <Column field="noteTime" header="Time" style={{ width: '10%' }} />
          <Column field="subject" header="Subject" style={{ width: '20%' }} />
          <Column field="authorRole" header="Author Role" style={{ width: '20%' }} />
          <Column field="notes" header="Notes" style={{ width: '50%' }} />
        </DataTable>
      </div>
    </div>
  );
};


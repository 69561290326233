import {createSlice} from '@reduxjs/toolkit'

import type {PayloadAction} from '@reduxjs/toolkit'
import type {ISuggestions} from './interfaces/ISuggestions'

export const initialState: ISuggestions = {
  searchTerm: '',
  page: 1,
}

const suggestionsSlice = createSlice({
  name: 'suggestions',
  initialState,
  reducers: {
    setSuggestionsState: (state, action: PayloadAction<Partial<ISuggestions>>) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const {setSuggestionsState} = suggestionsSlice.actions

export default suggestionsSlice.reducer

import {createSlice} from '@reduxjs/toolkit'
import {sub} from 'date-fns'

import {DEFAULT_RANGE} from '../../../../../common/constants/calendar'

import type {PayloadAction} from '@reduxjs/toolkit'
import type ICalendarFilter from './interfaces/ICalendarFilter'

const today = new Date()
export const defaultSelected = {
  from: sub(today, {days: DEFAULT_RANGE}).toString(),
  to: today.toISOString(),
}

export const initialState: ICalendarFilter = {
  isOpenCalendar: false,
  range: defaultSelected,
  selectedRange: {
    id: 1,
    title: 'Last week',
  },
  prevSelectedDays: undefined,
  selectedRangeDates: '',
}

const calendarSliceFilter = createSlice({
  name: 'calendarFilter',
  initialState,
  reducers: {
    setIsOpenCalendar: (state) => {
      state.isOpenCalendar = !state.isOpenCalendar
    },
    setCalendarFilterState: (state, action: PayloadAction<Partial<ICalendarFilter>>) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const {setIsOpenCalendar, setCalendarFilterState} = calendarSliceFilter.actions

export default calendarSliceFilter.reducer

import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from '../../AskMe/store/store'
import Chat from '../../AskMe/pages/Chat/Chat'
import {useFetchCategoriesQuery} from '../../AskMe/store/slices/categories/apis/categories'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setCategoriesState} from '../../AskMe/store/slices/categories/slice'
import {useAppSelector} from '../../AskMe/store/hooks/useApp'
import categoriesSelector from '../../AskMe/store/slices/categories/selectors'
import {useSearchParams} from 'react-router-dom'

const AskMe = () => {
  const categoriesData = useFetchCategoriesQuery()

  const [searchParams, setSearchParams] = useSearchParams()
  const {subCategory, categories} = useAppSelector(categoriesSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setCategoriesState({
        category: categoriesData.data?.[0],
        subCategory: categoriesData.data?.[0]?.subCategories?.[0],
      })
    )

    console.log('subCategory?.id', subCategory?.id)
    if (subCategory?.id) {
      setSearchParams({categoryId: categoriesData.data?.[0]?.subCategories?.[0].id ?? ''})
    }
  }, [categoriesData, dispatch, subCategory, searchParams])

  useEffect(() => {
    console.log('categoriesData', categoriesData.data)
  }, [categoriesData.data])
  return <Chat isCompact />
}

export const AskMeContent = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AskMe />
      </PersistGate>
    </Provider>
  )
}

import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAppSelector} from '../../store/hooks/useApp'
import categoriesSelector from '../../store/slices/categories/selectors'

import {ASK_ME_SUGGESTIONS} from '../../constants/general'
import {
  useAddSuggestionMutation,
  useGetSuggestionQuery,
  useUpdateSuggestionMutation,
} from '../../store/slices/suggestions/apis/suggestions'
import suggestionSchema from '../../utils/validators'
import SuggestionView from './SuggestionView'

import type {ReactElement} from 'react'
import type {SubmitHandler} from 'react-hook-form'
import {IFormValues} from './interfaces/IFormValues'
import {useFetchCategoriesQuery} from '../../store/slices/categories/apis/categories'
import {ICategoriesOption} from './interfaces/ICategoriesOption'

function Suggestion(): ReactElement {
  const {id} = useParams()
  const navigate = useNavigate()
  const {subCategory: subCategoryState} = useAppSelector(categoriesSelector)
  const {data, isLoading} = useGetSuggestionQuery(id, {skip: !id})
  const [updateSuggestion] = useUpdateSuggestionMutation()
  const [addSuggestion] = useAddSuggestionMutation()
  const {data: categoriesData} = useFetchCategoriesQuery()
  const [categories, setCategories] = useState<ICategoriesOption[] | undefined>(undefined)
  const [subCategories, setSubCategories] = useState<ICategoriesOption[] | undefined>(undefined)

  const formInitialValues = {
    category: undefined,
    subCategory: undefined,
    text: '',
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: {errors},
  } = useForm<IFormValues>({
    defaultValues: formInitialValues,
    resolver: yupResolver(suggestionSchema),
  })

  const watchedText = watch('text', '')
  const watchedCategory = watch('category')

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const preparedData = {
        ...data,
        category: {
          id: watchedCategory?.id || data.category.id,
          label: watchedCategory?.label || data.category.name,
          value: watchedCategory?.value || data.category.name,
        },
        subCategory: {
          id: watchedCategory?.id && subCategories ? subCategories[0]?.id : data.subCategory.id,
          label:
            watchedCategory?.id && subCategories ? subCategories[0]?.name : data.subCategory.name,
          value:
            watchedCategory?.id && subCategories ? subCategories[0]?.name : data.subCategory.name,
        },
      }
      reset(preparedData)
    } else if (!data && watchedCategory && subCategories) {
      const newSuggestion = {
        watchedText,
        category: {
          id: watchedCategory.id,
          label: watchedCategory.label,
          value: watchedCategory.value,
        },
        subCategory: {
          id: subCategories[0]?.id,
          label: subCategories[0]?.name,
          value: subCategories[0]?.name,
        },
      }
      reset(newSuggestion)
    }
  }, [data, reset, watchedCategory?.id, subCategories])

  useEffect(() => {
    const categoryId = watchedCategory?.id

    const categoriesOptions = categoriesData?.map((category) => ({
      id: category.id,
      name: category.name,
    }))
    setCategories(categoriesOptions)

    const subCategoriesOptions = categoriesData
      ?.find(({id}) => id === categoryId)
      ?.subCategories.map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name,
      }))
    setSubCategories(subCategoriesOptions)
  }, [categoriesData, data, watchedCategory?.id])

  const onSubmit: SubmitHandler<IFormValues> = async (suggestion) => {
    const {text, subCategory} = suggestion
    const trimmedText = text.trim()
    if (suggestion.id) {
      await updateSuggestion({
        id: suggestion.id,
        text: trimmedText,
        subCategory: subCategory.id || subCategoryState?.id,
      })
    } else {
      await addSuggestion({
        text: trimmedText,
        subCategory: subCategory.id || subCategoryState?.id || '',
      })
    }

    navigate(`/${ASK_ME_SUGGESTIONS}`)
  }

  return (
    <SuggestionView
      isLoading={isLoading}
      onSubmit={() => handleSubmit(onSubmit)}
      isEdit={!!data}
      register={register}
      control={control}
      errors={errors}
      categories={categories || []}
      subCategories={subCategories || []}
    />
  )
}

export default Suggestion

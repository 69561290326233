import {useEffect, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useSearchParams} from 'react-router-dom'

import useThrottle from '../../hooks/useThrottle'
import {useAppSelector} from '../../store/hooks/useApp'
import {useAddMessageMutation, useFetchChatHistoryQuery} from '../../store/slices/chat/apis/chat'
import chatSelector from '../../store/slices/chat/selectors'
import categoriesSelector from '../../store/slices/categories/selectors'
import {setChatState} from '../../store/slices/chat/slice'
import ChatView from './ChatView'

import type {ReactElement} from 'react'
import {ICategory} from '../../store/slices/categories/interfaces/ICategoriesResponse'
import {setCategoriesState} from '../../store/slices/categories/slice'
import {useCreateChatMessage} from '../../api/createChatMessage'
type ChatProps = {
  isCompact?: boolean
}
function Chat({isCompact}: ChatProps): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams()
  const {subCategory, categories} = useAppSelector(categoriesSelector)
  const dispatch = useDispatch()
  const loaderRef = useRef<HTMLDivElement>(null)
  const {skip, message, chatError} = useAppSelector(chatSelector)
  const {data, isLoading, isFetching, refetch} = useFetchChatHistoryQuery({
    subCategory: subCategory?.id || '',
    skip,
  })
  const [addMessage, {isLoading: isMessageLoading}] = useAddMessageMutation()
  const [messagePromise, setMessagePromise] = useState<ReturnType<typeof addMessage> | null>(null)
  const [files, setFiles] = useState<File[]>([])
  const createChatMessage = useCreateChatMessage()
  const handleObserver = useThrottle((entries) => {
    const target = entries[0]
    if (target.isIntersecting && !isFetching && data && data.data.length < data.totalItems) {
      dispatch(setChatState({skip: data.data.length}))
    }
  })

  useEffect(() => {
    const id = searchParams.get('categoryId')
    if (!id) {
      dispatch(
        setCategoriesState({
          category: categories?.[0],
          subCategory: categories?.[0]?.subCategories?.[0],
        })
      )

      if (subCategory?.id) {
        setSearchParams({categoryId: subCategory?.id})
      }
    } else {
      const currentCategory = categories?.find((category) =>
        category.subCategories.some((subCategory) => subCategory.id === id)
      )
      const currentSubcategory = currentCategory?.subCategories.find(
        (subCategory) => subCategory.id === id
      )

      if (currentCategory && currentSubcategory) {
        dispatch(
          setCategoriesState({
            category: currentCategory,
            subCategory: currentSubcategory,
          })
        )
      }
    }
  }, [categories, subCategory, searchParams, setSearchParams, dispatch])

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver)

    const loader = loaderRef.current

    if (loader) {
      observer.observe(loader)
    }

    return () => {
      if (loader) {
        observer.unobserve(loader)
      }
    }
  }, [skip, isFetching, data, dispatch, handleObserver])

  useEffect(() => {
    return () => {
      messagePromise?.abort()
    }
  }, [messagePromise, subCategory?.id])

  const handleMessageChange = (newMessage: string): void => {
    dispatch(setChatState({message: newMessage}))
  }

  const handleAddMessage = async () => {
    if (message) {
      console.log('message', message)
      createChatMessage.mutate(
        {
          subCategory: subCategory?.id || '',
          message,
          files,
        },
        {
          onSuccess: () => {
            refetch()
          },
        }
      )
    }

    setFiles([])
    handleMessageChange('')
  }

  return (
    <ChatView
      files={files}
      setFiles={setFiles}
      searchTerm={message}
      setSearchTerm={handleMessageChange}
      handleAddMessage={handleAddMessage}
      isLoading={isLoading}
      messages={data?.data || []}
      loaderRef={loaderRef}
      isFetching={isFetching}
      isMessageLoading={isMessageLoading || createChatMessage.isPending}
      chatError={chatError}
      isCompact={isCompact}
    />
  )
}

export default Chat

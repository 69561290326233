import {toAbsoluteUrl} from 'src/_metronic/helpers'

type ImageCardProps = {
  image: string
}

export const ImageCard = ({image}: ImageCardProps) => {
  return (
    <div>
      <img
        src={toAbsoluteUrl(`/media/demo/${image}`)}
        alt='placeholder'
        width={250}
        className='shadow-1'
      />
    </div>
  )
}

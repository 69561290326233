/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import {Dispatch, FC, SetStateAction, useEffect} from 'react'
import {KTIcon} from '../../../helpers'
import {useCurrentUser} from '../../../../queries/getCurrentUser'
import {useNavigate} from 'react-router-dom'
import {useLayout} from '../../core'
import {useExternalApplications} from '../../../../queries/getExternalApplications'
import {useThemeMode} from '../../../partials'
import {useLocation} from 'react-router'
import {useAppConfigurationQuery} from 'src/queries/getAppConfiguration'

const tabs: ReadonlyArray<{link: string; icon: string; tooltip: string}> = [
  // {
  //   link: 'subscription',
  //   icon: 'chart-simple',
  //   tooltip: 'Subscription',
  // },
]

type Props = {
  link: string
  setLink: Dispatch<SetStateAction<string>>
}

const AsideTabs: FC<Props> = ({link, setLink}) => {
  const applications = useExternalApplications()
  const configuration = useAppConfigurationQuery()
  const user = useCurrentUser()
  const navigate = useNavigate()
  const theme = useThemeMode()
  const layout = useLayout()
  const isAdmin = user?.data?.roles?.includes('Admin')
  const {pathname} = useLocation()
  const ASK_ME_PATH = '/askme'

  useEffect(() => {
    const isAskMe = pathname.includes(ASK_ME_PATH)
    if (isAskMe) {
      setLink('askme')
    }
  }, [pathname])

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      <ul className='nav flex-column justify-end' id='kt_aside_nav_tabs'>
        {isAdmin && configuration.data?.isOneViewEnabled && (
          <li key={'overview'} className='flex items-center flex-col mb-2'>
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: 'overview' === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = false
                layout.setLayout(layout)
                setLink('overview')
                navigate('/overview')
              }}
            >
              <img
                height='42'
                width='42'
                src={'/media/logos/Main Menu_ Overview.png'}
                alt={'Aged Care Assist logo'}
              />
            </a>
            <div>360View</div>
          </li>
        )}
        {tabs.map((t) => (
          <li key={t.link} className='flex items-center flex-col mb-2'>
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: t.link === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = true
                layout.setLayout(layout)
                setLink(t.link)
              }}
            >
              <KTIcon iconName={t.icon} className='fs-2x' />
            </a>
            <div></div>
          </li>
        ))}
        {configuration.data?.isInsightEnabled && (
          <li key={'agedcareai'} className='flex items-center flex-col mb-2'>
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: 'agedcareai' === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = false
                layout.setLayout(layout)
                setLink('agedcareai')
                navigate('/agedcareai')
              }}
            >
              <img
                src={'/media/logos/Main Menu_ Insights.png'}
                alt={'Pythia logo'}
                height='42'
                width='42'
                className='align-self-center'
              />
            </a>
            <div>Insights</div>
          </li>
        )}

        {configuration.data?.isAskMeEnabled && (
          <li key={'askMe'} className='flex items-center flex-col mb-2'>
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: 'askme' === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = false
                layout.setLayout(layout)
                setLink('askme')
                navigate(ASK_ME_PATH)
              }}
            >
              <img
                src='/media/logos/Main Menu_ AskMe .png'
                height='42'
                width='42'
                alt='Ask-Me logo'
                className='align-self-center'
              />
            </a>
            <div>AskMe</div>
          </li>
        )}

        {configuration.data?.isAssistEnabled && (
          <li key={'assist'} className='flex items-center flex-col mb-2'>
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: 'assist' === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = false
                layout.setLayout(layout)
                setLink('assist')
                navigate('/assist/my-files')
              }}
            >
              <img
                height='42'
                width='42'
                src={'/media/logos/Main Menu_ Assist.png'}
                alt={'Aged Care Assist logo'}
              />
            </a>
            <div>Assist</div>
          </li>
        )}
        {isAdmin && (
          <li key={'configuration'} className='flex items-center flex-col mb-2'>
            <a
              className={clsx(
                'nav-link btn btn-icon btn-active-color-primary btn-color-white btn-active-light',
                {active: 'configuration' === link}
              )}
              onClick={() => {
                layout.config.aside.secondaryDisplay = true
                layout.setLayout(layout)
                setLink('configuration')
              }}
            >
              <img
                height='42'
                width='42'
                src={'/media/logos/Main Menu_ Admin Menu.png'}
                alt={'Aged Care Assist logo'}
              />
            </a>
            <div>Settings</div>
          </li>
        )}
      </ul>
    </div>
  )
}

export {AsideTabs}

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface PainAssessment {
  date: string;
  time: string;
  painScore: string;
  painLocation: string;
  facialExpression: string;
  vocalization: string;
  bodyLanguage: string;
  intervention: string;
  outcome: string;
}

interface PainSeverityBreakdown {
  severityLevel: string;
  range: string;
  frequency: string;
  percentage: string;
}

const painAssessments: PainAssessment[] = [
  { date: "August 3, 2024", time: "06:50 AM", painScore: "6/10", painLocation: "Groin, Urinary Discomfort", facialExpression: "Grimacing", vocalization: "Groaning", bodyLanguage: "Restlessness", intervention: "Administered PRN Ural, fluids encouraged", outcome: "Pain reduced to 2/10 after 30 mins" },
  { date: "August 6, 2024", time: "09:10 PM", painScore: "8/10", painLocation: "Lower Back", facialExpression: "Clenched jaw", vocalization: "Moaning", bodyLanguage: "Rubbing lower back", intervention: "PRN Paracetamol administered", outcome: "Pain reduced to 4/10 after 45 mins" },
  { date: "August 9, 2024", time: "07:45 AM", painScore: "5/10", painLocation: "Knees (Osteoarthritis)", facialExpression: "Frowning", vocalization: "Quiet", bodyLanguage: "Holding knees", intervention: "Cold compress applied, PRN medication", outcome: "Pain reduced to 3/10 after 30 mins" },
  // Add more pain assessment records...
];

const painSeverityBreakdown: PainSeverityBreakdown[] = [
  { severityLevel: "Mild", range: "1-3", frequency: "3 instances", percentage: "30%" },
  { severityLevel: "Moderate", range: "4-6", frequency: "5 instances", percentage: "50%" },
  { severityLevel: "Severe", range: "7-10", frequency: "2 instances", percentage: "20%" }
];

export const PainManagement = () => {
  return (
    <div className="p-card p-5">
      <h2>Pain Management Report - August 2024</h2>

      <div className="p-mb-4">
        <strong>Resident Name:</strong> John Smith<br />
        <strong>Room:</strong> A101, 2nd Floor<br />
        <strong>Wing:</strong> Section Alpha<br />
        <strong>Reporting Period:</strong> August 1 - August 31, 2024
      </div>

      <div className='mt-10'>
        <h3>Summary of Pain Assessments</h3>
        <ul>
          <li><strong>Total Pain Assessments Conducted:</strong> 10</li>
          <li><strong>Average Pain Severity Score:</strong> 4/10 (Moderate Pain)</li>
          <li><strong>Highest Recorded Pain Score:</strong> 8/10 (Severe Pain)</li>
          <li><strong>Lowest Recorded Pain Score:</strong> 1/10 (Mild Pain)</li>
          <li><strong>Most Common Pain Locations:</strong> Groin, Lower Back, Knees, Urinary Discomfort</li>
        </ul>
      </div>
      <div className='mt-10'>
        <h3>Pain Assesments Details</h3>
        <DataTable value={painAssessments} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="time" header="Time" />
          <Column field="painScore" header="Pain Score (0-10)" />
          <Column field="painLocation" header="Pain Location" />
          <Column field="facialExpression" header="Facial Expression" />
          <Column field="vocalization" header="Vocalization" />
          <Column field="bodyLanguage" header="Body Language" />
          <Column field="intervention" header="Intervention" />
          <Column field="outcome" header="Outcome" />
        </DataTable>
      </div >

      <div className='mt-10'>
      <h3>Pain Severity Breakdown</h3>
        <DataTable value={painSeverityBreakdown} responsiveLayout="scroll">
          <Column field="severityLevel" header="Severity Level" />
          <Column field="range" header="Range (Score)" />
          <Column field="frequency" header="Frequency" />
          <Column field="percentage" header="Percentage" />
        </DataTable>
      </div >

      <div className='mt-10'>
        <h3>Intervation Effectiveness</h3>
        <ul>
          <li><strong>Most Common Intervention:</strong> PRN Ural for urinary discomfort and PRN Paracetamol for osteoarthritis and back pain.</li>
          <li><strong>Average Time to Relief:</strong> 30–45 minutes.</li>
          <li><strong>Most Effective Intervention:</strong> Ural (for urinary pain) and cold compress for knee pain (immediate relief in 3 cases).</li>
        </ul>
      </div >

      <div className='mt-10'>
        <h3>Observations & Trends</h3>
        <ul>
          <li><strong>Urinary Discomfort:</strong> Urinary discomfort was the most frequent source of pain during August, often related to chronic UTIs. Ural was effective in reducing pain from 6-8/10 to 1-2/10 within 30 minutes of administration.</li>
          <li><strong>Osteoarthritis Pain:</strong> Pain in the knees and lower back due to osteoarthritis was the second most common complaint, particularly in the morning and evening. Paracetamol provided moderate relief, but pain often persisted at low levels (2-4/10).</li>
          <li><strong>Severe Pain Episodes:</strong> Two severe pain episodes (8/10) were noted on August 6 and August 26. These were both related to lower back pain and required PRN medication. Relief was achieved after 45 minutes.</li>
        </ul>
      </div >

      <div className='mt-10'>
        <h3>Recomendations</h3>
        <ul>
          <li><strong>Pain Review:</strong> Monitor for UTI symptoms and adjust the UTI management plan as necessary, possibly increasing proactive hydration and Ural usage when discomfort arises.</li>
          <li><strong>Osteoarthritis Management:</strong> Continue using cold compresses for knee pain and consider regular physiotherapy sessions. Review Paracetamol dosage with the GP.</li>
          <li><strong>Medication Adjustment:</strong> Evaluate stronger pain management options if back pain persists, especially during episodes of severe pain.</li>
        </ul>
      </div >

      <div className='mt-10'>
        <h3>Conclusion</h3>
        <p>John Smith's pain was generally well-managed throughout August, with most pain episodes effectively resolved within 30-45 minutes of intervention. Continued proactive management of his osteoarthritis and urinary discomfort is recommended to maintain his quality of life.</p>
      </div >
    </div >
  );
};


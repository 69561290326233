import React from 'react'

import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage'
import {MIN_TEXT_SUGGESTION_LENGTH} from '../../../constants/general'

import type {ReactElement} from 'react'
import type {IFormField} from '../interfaces/IFormField'

import styles from '../sass/Textarea.module.scss'

export default function Textarea({
  label,
  register,
  required,
  value,
  errors,
}: IFormField): ReactElement {
  const {name, onBlur, onChange, ref} =
    register(value, {required, minLength: MIN_TEXT_SUGGESTION_LENGTH}) || {}

  return (
    <div className={styles.textField}>
      <div className={styles.label}>{label}</div>
      <textarea name={name} onBlur={onBlur} onChange={onChange} ref={ref} />
      {errors ? <ErrorMessage error={errors} /> : null}
    </div>
  )
}

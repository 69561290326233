import {ISearchInputBlockItem} from './interfaces/ISearchInputBlock'
import styles from './sass/SearchInputBlock.module.scss'

const IncidentListItem = ({
  id,
  subject,
  content,
  activeIncident,
  prevIncidentIndex,
  index,
  onItemClick,
  ...rest
}: {
  id: string
  subject: string
  content: string
  activeIncident: ISearchInputBlockItem | null | undefined
  prevIncidentIndex: number | undefined
  index: number
  onItemClick: (item: ISearchInputBlockItem, index: number) => void
  [key: string]: any
}) => {
  return (
    <li
      key={id}
      className={`
    ${styles.incident} 
    ${id === activeIncident?.id ? styles.active : ''}
    ${index === prevIncidentIndex ? styles.prevActiveItem : ''}
    `}
      onClick={() =>
        onItemClick(
          {
            id,
            subject,
            content,
            ...rest,
          },
          index
        )
      }
      role='presentation'
    >
      <h4 className={styles.title}>{subject}</h4>
      <p className={styles.description}>{content}</p>
    </li>
  )
}

export default IncidentListItem

import {ReactElement, useEffect, useState} from 'react'
import {formatAndSanitizeContent} from '../../../../common/helpers/formatAndSanitizeContent'
import useDebounce from '../../../../common/hooks/useDebounce'
import DotsLoader from '../../../../components/DotsLoader/DotsLoader'
import SearchInputBlock from '../../../PythiaV3/pages/Complaints/components/SearchInputBlock/SearchInputBlock'
import {useFetchAssistFilesQuery} from '../../store/slices/assistFiles/apis/assistFiles'
import {IAssistFile} from '../../store/slices/assistFiles/interfaces/IAssistFile'
import AssistFilesContentView from '../AssistFilesContentView/AssistFilesContentView'
import styles from './AssistFilesView.module.scss'

export default function AssistFilesView(): ReactElement {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm.trim())
  const {data, isLoading} = useFetchAssistFilesQuery({searchInput: debouncedSearchTerm})
  const [mappedData, setMappedData] = useState<IAssistFile[]>([])
  const [activeFile, setActiveFile] = useState<IAssistFile | undefined>(undefined)
  useEffect(() => {
    const mappedItems: IAssistFile[] =
      data?.items.map((item) => ({
        ...item,
        content: formatAndSanitizeContent(item.content),
      })) ?? []
    setMappedData(mappedItems)
    if (activeFile && data?.items?.some((item) => item.id === activeFile.id)) {
      return
    }
    setActiveFile(data?.items[0])
  }, [data])

  if (isLoading) {
    return <DotsLoader variant='centered' />
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SearchInputBlock
          activeIncident={activeFile}
          setActiveIncident={(incident) => {
            const item = data?.items.find((item) => incident?.id === item.id)
            setActiveFile(item)
          }}
          complaints={mappedData}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          listClassName={styles.searchInputBlockList}
        />
        {data?.totalCount ? (
          <AssistFilesContentView
            activeItem={activeFile || null}
            typeId={activeFile?.typeId}
            statusId={activeFile?.statusId}
            mode='file'
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

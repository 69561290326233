import {IAssistFilesResponse} from '../interfaces/IAssistFilesResponse'
import {IAssistFilesParams} from '../interfaces/IAssistFilesParams'
import {ICreateAssistFileResponse} from '../interfaces/ICreateAssistFileResponse'
import {ICreateAssistFileParams} from '../interfaces/ICreateAssistFileParams'
import {IDeleteAssistFileResponse} from '../interfaces/IDeleteAssistFileResponse'
import {IDeleteAssistFileParams} from '../interfaces/IDeleteAssistFileParams'
import {IUpdateAssistFileParams} from '../interfaces/IUpdateAssistFileParams'
import {IUpdateAssistFileResponse} from '../interfaces/IUpdateAssistFileResponse'
import assistApi from '../../../api/assistApi'
import {ASSIST_FILES} from '../../../../../../common/constants/requestUrls'
import {CONTENT_ITEMS_DEFAULT_PAGE_SIZE} from '../../../../../../common/constants/general'
import {DELETE, GET, POST, PUT} from '../../../../../../common/constants/requestMethods'
import {IPaginatedResponse} from 'src/app/pages/AskMe/store/types/IPaginatedResponse'
import ISuggestion from 'src/app/pages/AskMe/store/slices/chat/interfaces/ISuggestion'
import {IGetSuggestionParams} from 'src/app/pages/AskMe/store/slices/chat/interfaces/IGetSuggestionParams'
import {SUGGESTIONS_PAGE_SIZE} from 'src/app/pages/AskMe/constants/general'
import {SUGGESTIONS} from 'src/app/pages/AskMe/constants/requestUrls'
import {SUGGESTIONS_TAG} from 'src/app/pages/AskMe/constants/tags'

const assistFilesApi = assistApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAssistFiles: builder.query<IAssistFilesResponse, IAssistFilesParams>({
      query: ({searchInput}) => ({
        url: `${ASSIST_FILES}`,
        params: {
          PageNumber: 1,
          PageSize: CONTENT_ITEMS_DEFAULT_PAGE_SIZE,
          SearchInput: searchInput,
        },
        method: GET,
      }),
      providesTags: ['AssistFiles'],
    }),
    createFile: builder.mutation<ICreateAssistFileResponse, ICreateAssistFileParams>({
      query: ({typeId, statusId, subject, content, contentItemId}) => ({
        url: `${ASSIST_FILES}`,
        method: POST,
        body: {
          typeId,
          statusId,
          subject,
          content,
          contentItemId,
        },
      }),
      invalidatesTags: ['AssistFiles'],
    }),
    updateFile: builder.mutation<IUpdateAssistFileResponse, IUpdateAssistFileParams>({
      query: ({id, subject, content}) => ({
        url: `${ASSIST_FILES}/${id}`,
        method: PUT,
        body: {
          subject,
          content,
        },
      }),
      invalidatesTags: ['AssistFiles'],
    }),
    deleteFile: builder.mutation<IDeleteAssistFileResponse, IDeleteAssistFileParams>({
      query: ({id}) => ({
        url: `${ASSIST_FILES}/${id}`,
        method: DELETE,
      }),
      invalidatesTags: ['AssistFiles'],
    }),
    fetchSuggestions: builder.query<IPaginatedResponse<ISuggestion>, IGetSuggestionParams>({
      query: ({search, pageNumber, startsWith, pageSize = SUGGESTIONS_PAGE_SIZE, subCategory}) => ({
        url: `${SUGGESTIONS}`,
        params: {
          PageNumber: pageNumber,
          PageSize: pageSize,
          Search: search,
          StartsWith: startsWith,
          SubCategory: subCategory,
        },
        method: GET,
      }),
      providesTags: [SUGGESTIONS_TAG],
    }),
  }),
})

export const {
  useFetchAssistFilesQuery,
  useCreateFileMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
  useFetchSuggestionsQuery,
} = assistFilesApi

export default assistFilesApi

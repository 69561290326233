import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface NurseCall {
  date: string;
  timeOfCall: string;
  reasonForCall: string;
  responseTime: string;
  notes: string;
}

interface MonthlySummary {
  month: string;
  totalCalls: number;
  avgResponseTime: string;
}

const juneCalls: NurseCall[] = [
  { date: "June 5, 2024", timeOfCall: "07:10 AM", reasonForCall: "Assistance with toileting", responseTime: "5 minutes", notes: "Resident requested help with mobility to bathroom." },
  { date: "June 7, 2024", timeOfCall: "02:35 PM", reasonForCall: "Pain (groin discomfort)", responseTime: "3 minutes", notes: "PRN medication administered." },
  { date: "June 12, 2024", timeOfCall: "08:45 AM", reasonForCall: "Assistance getting out of bed", responseTime: "4 minutes", notes: "Required assistance to stand safely." },
  { date: "June 15, 2024", timeOfCall: "11:22 AM", reasonForCall: "Pain (knee discomfort)", responseTime: "2 minutes", notes: "Applied cold compress, PRN administered." },
  { date: "June 18, 2024", timeOfCall: "09:40 PM", reasonForCall: "Restroom assistance", responseTime: "4 minutes", notes: "Assisted to restroom, no issues." },
  { date: "June 22, 2024", timeOfCall: "12:15 PM", reasonForCall: "Pain (urinary burning sensation)", responseTime: "3 minutes", notes: "Administered Ural and encouraged fluid intake." },
  { date: "June 25, 2024", timeOfCall: "10:30 AM", reasonForCall: "Repositioning", responseTime: "6 minutes", notes: "Helped with pressure area care repositioning." },
  { date: "June 29, 2024", timeOfCall: "05:50 PM", reasonForCall: "Request for water", responseTime: "2 minutes", notes: "Resident thirsty, provided water." }
];

const julyCalls: NurseCall[] = [
  { date: "July 2, 2024", timeOfCall: "07:30 AM", reasonForCall: "Assistance with dressing", responseTime: "5 minutes", notes: "Helped with dressing and morning hygiene." },
  { date: "July 6, 2024", timeOfCall: "01:45 PM", reasonForCall: "Pain (groin pain, rated 5/10)", responseTime: "4 minutes", notes: "PRN medication administered for pain relief." },
  { date: "July 10, 2024", timeOfCall: "09:15 AM", reasonForCall: "Assistance transferring to wheelchair", responseTime: "4 minutes", notes: "Transferred safely to wheelchair." },
  { date: "July 12, 2024", timeOfCall: "02:30 PM", reasonForCall: "Pain (lower leg pain)", responseTime: "3 minutes", notes: "PRN pain medication administered." },
  { date: "July 16, 2024", timeOfCall: "10:40 AM", reasonForCall: "Help with personal hygiene", responseTime: "5 minutes", notes: "Assisted with a shower." },
  { date: "July 19, 2024", timeOfCall: "07:00 PM", reasonForCall: "Pain (urinary burning sensation)", responseTime: "2 minutes", notes: "Ural administered. Encouraged fluid intake." },
  { date: "July 23, 2024", timeOfCall: "04:25 PM", reasonForCall: "Request for blanket", responseTime: "3 minutes", notes: "Provided extra blanket for warmth." },
  { date: "July 28, 2024", timeOfCall: "11:10 AM", reasonForCall: "Assistance getting into bed", responseTime: "6 minutes", notes: "Helped resident get into bed for a rest." }
];

const augustCalls: NurseCall[] = [
  { date: "August 3, 2024", timeOfCall: "06:50 AM", reasonForCall: "Help with toileting", responseTime: "3 minutes", notes: "Assisted to restroom." },
  { date: "August 6, 2024", timeOfCall: "09:10 PM", reasonForCall: "Pain (lower back pain)", responseTime: "4 minutes", notes: "PRN pain relief provided." },
  { date: "August 9, 2024", timeOfCall: "07:45 AM", reasonForCall: "Help getting dressed", responseTime: "6 minutes", notes: "Assisted with dressing and morning care." },
  { date: "August 12, 2024", timeOfCall: "01:35 PM", reasonForCall: "Repositioning for pressure care", responseTime: "4 minutes", notes: "Assisted with repositioning to prevent pressure sores." },
  { date: "August 15, 2024", timeOfCall: "02:40 PM", reasonForCall: "Request for water", responseTime: "2 minutes", notes: "Resident requested water; provided immediately." },
  { date: "August 18, 2024", timeOfCall: "08:20 PM", reasonForCall: "Pain (urinary discomfort)", responseTime: "3 minutes", notes: "Ural administered and encouraged hydration." },
  { date: "August 22, 2024", timeOfCall: "11:55 AM", reasonForCall: "Restroom assistance", responseTime: "5 minutes", notes: "Assisted to restroom, no issues." },
  { date: "August 29, 2024", timeOfCall: "04:00 PM", reasonForCall: "Assistance with shower", responseTime: "7 minutes", notes: "Resident showered with assistance." }
];

const monthlySummaries: MonthlySummary[] = [
  { month: "June", totalCalls: 8, avgResponseTime: "3.63 minutes" },
  { month: "July", totalCalls: 8, avgResponseTime: "4 minutes" },
  { month: "August", totalCalls: 8, avgResponseTime: "4.25 minutes" }
];

export const NurseCall = () => {
  return (
    <div className="p-card p-5">
      <h2>Nurse Call System Usage Report for John Smith</h2>

      <div className="p-mb-4">
        <strong>Resident Name:</strong> John Smith<br />
        <strong>Room:</strong> A101, 2nd Floor<br />
        <strong>Wing:</strong> Section Alpha<br />
        <strong>Period:</strong> 3 Months (June, July, August)
      </div>
      <div className='flex-col flex gap-10 mt-10'>
        <DataTable value={juneCalls} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="timeOfCall" header="Time of Call" />
          <Column field="reasonForCall" header="Reason for Call" />
          <Column field="responseTime" header="Response Time" />
          <Column field="notes" header="Notes" />
        </DataTable>
        <p><strong>Total Calls in June:</strong> 8</p>
        <p><strong>Average Response Time:</strong> 3.63 minutes</p>

        <DataTable value={julyCalls} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="timeOfCall" header="Time of Call" />
          <Column field="reasonForCall" header="Reason for Call" />
          <Column field="responseTime" header="Response Time" />
          <Column field="notes" header="Notes" />
        </DataTable>
        <p><strong>Total Calls in July:</strong> 8</p>
        <p><strong>Average Response Time:</strong> 4 minutes</p>
        <DataTable value={augustCalls} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="timeOfCall" header="Time of Call" />
          <Column field="reasonForCall" header="Reason for Call" />
          <Column field="responseTime" header="Response Time" />
          <Column field="notes" header="Notes" />
        </DataTable>
        <p><strong>Total Calls in August:</strong> 8</p>
        <p><strong>Average Response Time:</strong> 4.25 minutes</p>

        <DataTable value={monthlySummaries} responsiveLayout="scroll">
          <Column field="month" header="Month" />
          <Column field="totalCalls" header="Total Calls" />
          <Column field="avgResponseTime" header="Average Response Time" />
        </DataTable>
        <p><strong>Total Calls Over 3 Months:</strong> 24</p>
        <p><strong>Overall Average Response Time:</strong> 3.96 minutes</p>
      </div>
    </div>
  );
};

import type {ReactElement} from 'react'
import {useState} from 'react'
import searchIcon from 'src/app/common/assets/icons/search.svg'
import {ISearchInputBlock, ISearchInputBlockItem} from './interfaces/ISearchInputBlock'
import styles from './sass/SearchInputBlock.module.scss'
import IncidentListItem from './IncidentListItem'

export default function SearchInputBlock({
  activeIncident,
  setActiveIncident,
  complaints,
  setSearchTerm,
  searchTerm,
  listClassName,
}: ISearchInputBlock): ReactElement {
  const [prevIncidentIndex, setPrevIncidentIndex] = useState<number | undefined>(undefined)

  const onItemClick = (complaintsItem: ISearchInputBlockItem, index: number) => {
    setActiveIncident(complaintsItem)
    setPrevIncidentIndex(index - 1)
  }

  return (
    <div className={styles.searchInputBlock}>
      <div className={styles.searchInput}>
        <div className={styles.searchIcon}>
          <img src={searchIcon} alt='search' />
        </div>
        <div className={styles.inputField}>
          <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            aria-label='Search'
            placeholder='Search'
          />
        </div>
      </div>
      <ul className={`${styles.incidentList} ${listClassName}`}>
        {complaints?.map(({id, subject, content, ...rest}, index) => (
          <IncidentListItem
            key={id}
            id={id}
            subject={subject}
            content={content}
            activeIncident={activeIncident}
            prevIncidentIndex={prevIncidentIndex}
            index={index}
            onItemClick={onItemClick}
            {...rest}
          />
        ))}
      </ul>
    </div>
  )
}

import {t} from 'i18next'
import * as Yup from 'yup'

import {MIN_TEXT_SUGGESTION_LENGTH} from '../constants/general'

const suggestionSchema = Yup.object().shape({
  category: Yup.object({
    id: Yup.string().required(t('suggestions.isRequired')),
    label: Yup.string(),
    value: Yup.string().required(t('suggestions.isRequired')),
  }).required(),
  subCategory: Yup.object({
    id: Yup.string().required(t('suggestions.isRequired')),
    label: Yup.string(),
    value: Yup.string().required(t('suggestions.isRequired')),
  }).required(),
  text: Yup.string()
    .required(t('suggestions.isRequired'))
    .min(
      MIN_TEXT_SUGGESTION_LENGTH,
      t('suggestions.textShouldBeOfMinimumLength', {number: MIN_TEXT_SUGGESTION_LENGTH})
    ),
})

export default suggestionSchema

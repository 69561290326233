import React, {createContext, useContext, useState, ReactNode, useRef} from 'react'
import {Messages} from 'primereact/messages'

export interface Notification {
  sticky: boolean
  severity: 'success' | 'info' | 'warn' | 'error'
  summary: string
  detail: string
  life?: number
  closable: boolean
}

interface NotificationsContextType {
  notifications: Notification[]
  addNotification: (notification: Notification) => void
  removeNotification: (index: number) => void
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined)

export const useNotifications = () => {
  const context = useContext(NotificationsContext)
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider')
  }
  return context
}

interface NotificationsProviderProps {
  children: ReactNode
}

export const NotificationsProvider: React.FC<NotificationsProviderProps> = ({children}) => {
  const msgs = useRef<Messages>(null)
  const [notifications, setNotifications] = useState<Notification[]>([])

  const addNotification = (notification: Notification) => {
    msgs.current?.show(notification)
  }

  const removeNotification = (index: number) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index))
  }

  return (
    <NotificationsContext.Provider value={{notifications, addNotification, removeNotification}}>
      <div style={{position: 'fixed', top: '20px', right: '20px', zIndex: 9999}}>
        <Messages ref={msgs} />
      </div>
      {children}
    </NotificationsContext.Provider>
  )
}

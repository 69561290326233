export default {
  suggestions: 'Suggestions',
  createSuggestion: 'Create new suggestion',
  changeSuggestion: 'Change suggestion',
  suggestionText: 'Suggestion text',
  submit: 'Submit',
  categories: 'Categories',
  subcategories: 'Subcategories',
  selectCategory: 'Select category',
  selectSubcategory: 'Select subcategory',
  isRequired: 'This field is required',
  textShouldBeOfMinimumLength: 'Text should be of minimum {{ number }} characters length',
}

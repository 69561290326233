import {AssistService} from 'src/api/services/AssistService'

interface handleAiActionProps {
  prompt: string
  actionId?: string
  context: string
  activeChatSessionId: string
  onAiRequestComplete?: () => void
}

const handleAiAction = async ({
  prompt,
  actionId,
  context,
  activeChatSessionId,
  onAiRequestComplete,
}: handleAiActionProps): Promise<string | {error: string}> => {
  if (!prompt && !actionId) {
    return ''
  }
  try {
    const fullPromptObject = {prompt, context}
    const res = await AssistService.assistGeneratePrompt(
      JSON.stringify(fullPromptObject),
      actionId,
      activeChatSessionId
    )
    if (res.success) {
      return res.result
    } else {
      return {error: 'Prompt failed to generate'}
    }
  } catch (e) {
    if (e instanceof Error) {
      return {error: e.message}
    } else {
      return {error: 'An unknown error occurred'}
    }
  } finally {
    onAiRequestComplete?.()
  }
}

export {handleAiAction}

import {MenuItemCommandEvent} from 'primereact/menuitem'

export const navItems = (commandHandler: (event: MenuItemCommandEvent) => void) => [
  {label: 'Overview', icon: 'pi pi-fw pi-home', command: commandHandler, id: 'home'},
  {
    label: 'Progress Notes',
    icon: 'pi pi-fw pi-pencil',
    command: commandHandler,
    id: 'progressnotes',
  },
  {
    label: 'Incidents',
    icon: 'pi pi-fw pi-exclamation-triangle',
    command: commandHandler,
    id: 'incidents',
  },
  {label: 'Care Plan', icon: 'pi pi-fw pi-calendar', command: commandHandler, id: 'careplan'},
  {label: 'Profile', icon: 'pi pi-fw pi-user', command: commandHandler, id: 'profile'},
  {
    label: 'Pain Management',
    icon: 'pi pi-fw pi-heart-fill',
    command: commandHandler,
    id: 'painmanagement',
  }, // Alternative ic, command: commandHandleron
  {label: 'Nurse Call', icon: 'pi pi-fw pi-phone', command: commandHandler, id: 'nursecall'},
  {
    label: 'Medication Management',
    icon: 'pi pi-fw pi-briefcase',
    command: commandHandler,
    id: 'medicationmanagement',
  },
  {
    label: 'Social and Recreational Activities',
    icon: 'pi pi-fw pi-users',
    command: commandHandler,
    id: 'socialandrecreational',
  },
  {
    label: 'Dietary Management',
    icon: 'pi pi-fw pi-apple',
    command: commandHandler,
    id: 'dieterymanagement',
  }, // Alternative ic, command: commandHandleron
  {
    label: 'Emergency Protocols',
    icon: 'pi pi-fw pi-shield',
    command: commandHandler,
    id: 'emergencyprotocols',
  },
  {label: 'Feedback', icon: 'pi pi-fw pi-comment', command: commandHandler, id: 'feedback'},
  {label: 'Finance', icon: 'pi pi-fw pi-money-bill', command: commandHandler, id: 'financial'},
  {label: 'Timeline', icon: 'pi pi-fw pi-history', command: commandHandler, id: 'timeline'},
  //{ label: 'Reports', icon: 'pi pi-fw pi-chart-bar', command: commandHandler, id: "reports" },
  //{ label: 'Settings', icon: 'pi pi-fw pi-cog', command: commandHandler, id: "settings" },
  //{ label: 'Assessments', icon: 'pi pi-fw pi-file-o', command: commandHandler, id: "assessments" },
  //{ label: 'Incident Reports', icon: 'pi pi-fw pi-file-excel', command: commandHandler, id: "incidentreports" },
  //{ label: 'Lifestyle Activities', icon: 'pi pi-fw pi-moon', command: commandHandler }, // Alternative ic, command: commandHandleron
  //{ label: 'Documentation & Legal', icon: 'pi pi-fw pi-file-pdf', command: commandHandler },
  //{ label: 'Resident Satisfaction and Feedback', icon: 'pi pi-fw pi-comments', command: commandHandler },
  //{ label: 'Protocols', icon: 'pi pi-fw pi-book', command: commandHandler },
]

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type {AnalysisOutcome} from '../models/AnalysisOutcome'

import type {CancelablePromise} from '../core/CancelablePromise'
import {OpenAPI} from '../core/OpenAPI'
import {request as __request} from '../core/request'

export class AnalysisOutcomesService {
  /**
   * @param id
   * @returns AnalysisOutcome
   * @throws ApiError
   */
  public static analysisOutcomesGetAnalysisOutcome(id: string): CancelablePromise<AnalysisOutcome> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/AnalysisOutcomes/{id}',
      path: {
        id: id,
      },
    })
  }

  /**
   * @param id
   * @returns binary
   * @throws ApiError
   */
  public static analysisOutcomesDeleteAnalysisOutcome(id: string): CancelablePromise<Blob> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/AnalysisOutcomes/{id}',
      path: {
        id: id,
      },
    })
  }
}

import type {ReactElement} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {INavSubItem} from './interfaces/INavSubItem'
import {ASKME} from '../../constants/general'

import {useAppDispatch, useAppSelector} from '../../store/hooks/useApp'
import categoriesSelector from '../../store/slices/categories/selectors'
import {setCategoriesState} from '../../store/slices/categories/slice'

import styles from './sass/NavSubItem.module.scss'

export default function NavSubItem({
  title,
  isSidebarOpened,
  parentCategory,
  id,
  parentCategoryId,
}: INavSubItem): ReactElement {
  const {subCategory} = useAppSelector(categoriesSelector)
  const dispatch = useAppDispatch()
  let {pathname} = useLocation()
  const onClickHandler = () => {
    dispatch(
      setCategoriesState({
        category: parentCategory,
        subCategory: {
          id,
          name: title,
          parentCategory: parentCategoryId,
        },
      })
    )
  }

  return (
    <li
      className={`${styles.submenuItem} 
        ${subCategory?.id === id ? styles.activeSubmenuItem : ''}
        ${!isSidebarOpened ? styles.shortSubmenuItem : ''}`}
      onClick={onClickHandler}
    >
      <Link
        className={styles.submenuLink}
        to={pathname === `/${ASKME}` ? `?categoryId=${id}` : `/${ASKME}?categoryId=${id}`}
      >
        {title}
      </Link>
    </li>
  )
}

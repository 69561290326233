import {CancelablePromise} from '../core/CancelablePromise'
import {request as __request} from '../core/request'
import {OpenAPI} from '../core/OpenAPI'

export interface AssistPromptHistory {
  createdAt: string
  request: string
  response: string
}

export interface ChatSession {
  id: string
  name: string
  createdAt: Date
  updatedAt: Date
  assistPromptHistory?: AssistPromptHistory[]
}

export class ChatSessionService {
  /**
   * @param fileId
   * @returns ChatSession
   * @throws ApiError
   */
  public static getActiveSession(fileId: string): CancelablePromise<ChatSession> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/chat-sessions/active',
      query: {
        fileId,
      },
    })
  }

  /**
   * @param fileId
   * @param name
   * @returns ChatSession
   * @throws ApiError
   */
  public static createSession(fileId: string): CancelablePromise<ChatSession> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/chat-sessions/new',
      body: {
        fileId,
      },
    })
  }
}

import {
  RemovingEventArgs,
  UploaderComponent,
  UploadingEventArgs,
} from '@syncfusion/ej2-react-inputs'
import {useEffect} from 'react'
import {useUploadedFiles} from 'src/app/common/queries/getUploadedFIles'
import styles from './UploadFilesView.module.scss'
interface Props {
  fileId: string
  sessionId: string
}

function getApiToken(): string {
  const storedToken = localStorage.getItem('kt-auth-react-v')
  const {api_token} = storedToken && JSON.parse(storedToken)
  return api_token
}

const fileExtensions =
  '.docx,.docm,.dotx,.dotm,.doc,.dot,.htm,.html,.rtf,.mht,.mhtml,.xml,.odt,.pdf,.xlsx,.xlsm,.xlsb,.xlam,.xls,.xlt,.xla,.xlm,.xlw,.odc,.ods,.pptx,.ppt,.pptm,.ppsx,.ppsm,.potx,.pot,.potm,.odp,.txt,.csv,.json,.png,.jpg,.jpeg,.webp,.gif'

const path = {
  removeUrl: '/api/files/delete',
  saveUrl: '/api/files/save',
}

export default function UploadFilesView({sessionId, fileId}: Props) {
  const {data: files, isLoading} = useUploadedFiles(sessionId, fileId)

  useEffect(() => {
    const clearButton = document.getElementsByClassName('e-file-clear-btn')[0] as HTMLButtonElement
    if (clearButton) {
      clearButton.disabled = true
    }
  }, [files])

  const onUploading = (args: UploadingEventArgs): void => {
    const api_token = getApiToken()
    if (args.currentRequest) {
      args.currentRequest.setRequestHeader('Authorization', `Bearer ${api_token}`)
    }
    args.customFormData = [{sessionId}, {fileId}]
  }

  const onRemoving = (args: RemovingEventArgs): void => {
    const api_token = getApiToken()
    if (args.currentRequest) {
      args.currentRequest.setRequestHeader('Authorization', `Bearer ${api_token}`)
    }
    args.customFormData = [{sessionId}, {name: args.filesData[0].name ?? ''}, {fileId}]
  }

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <i className='pi pi-spin pi-spinner' style={{fontSize: '2rem'}}></i>
      </div>
    )
  }

  return (
    !isLoading && (
      <UploaderComponent
        key={sessionId}
        files={files}
        sequentialUpload
        autoUpload={false}
        asyncSettings={path}
        allowedExtensions={fileExtensions}
        uploading={onUploading}
        removing={onRemoving}
      />
    )
  )
}

import type {ReactElement} from 'react'

import SecondarySidebar from '../SecondarySidebar/SecondarySidebar'
import styles from './sass/Sidebar.module.scss'

export default function Sidebar(): ReactElement {
  return (
    <div className={styles.sidebar}>
      {/* <MainSidebar /> */}
      <SecondarySidebar />
    </div>
  )
}

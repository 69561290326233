import {Link} from 'react-router-dom'

import Button from '../../pages/Chat/components/Button/Button'
import {useDeleteSuggestionMutation} from '../../store/slices/suggestions/apis/suggestions'
import Categories from '../Categories/Categories'
import CircleLoader from '../CircleLoader/CircleLoader'

import type {ReactElement} from 'react'
import type {ISuggestionsItem} from './interfaces/ISuggestionsItem'

import {ReactComponent as DeleteIcon} from '../../assets/icons/suggestions/delete.svg'
import {ReactComponent as EditIcon} from '../../assets/icons/suggestions/edit.svg'

import styles from './sass/SuggestionsItem.module.scss'

export default function SuggestionsItem({
  id,
  text,
  category,
  subCategory,
}: ISuggestionsItem): ReactElement {
  const [deleteSuggestion, {isLoading: isDeleting}] = useDeleteSuggestionMutation()

  const onDeleteClickHandler = (suggestionId: string) => {
    deleteSuggestion(suggestionId)
  }

  return (
    <li className={styles.suggestion}>
      <div>
        <Categories category={category} subCategory={subCategory} />
        <p className={styles.text}>{text}</p>
      </div>
      <div className={styles.icons}>
        <Link to={`edit/${id}`}>
          <EditIcon className={styles.icon} />
        </Link>
        <Button
          icon={isDeleting ? <CircleLoader /> : <DeleteIcon className={styles.icon} />}
          onClick={() => onDeleteClickHandler(id)}
          buttonStyle={isDeleting ? styles.loader : styles.button}
        />
      </div>
    </li>
  )
}

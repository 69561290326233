export const timelineData = [
  {
    status: 'Payment',
    date: '15/10/2020 10:30',
    icon: 'pi pi-dollar',
    color: '#9C27B0',
    content:
      "The resident's monthly fee payment was processed successfully. A receipt was generated and sent to the resident's family for their records.",
  },
  {
    status: 'Nurse Call',
    date: '15/10/2020 11:15',
    icon: 'pi pi-bell',
    color: '#FF9800',
    content:
      'The resident used the nurse call system to request assistance. A nurse responded promptly and provided necessary help.',
  },
  {
    status: 'ECG',
    date: '15/10/2020 14:30',
    icon: 'pi pi-heart',
    color: '#E91E63',
    content:
      "An ECG was performed to monitor the resident's heart activity. Results showed normal sinus rhythm with no abnormalities detected.",
  },
  {
    status: 'Blood Pressure Check',
    date: '16/10/2020 09:00',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#607D8B',
    content:
      "The resident's blood pressure was measured. Readings were 120/80 mmHg, within the normal range.",
  },
  {
    status: 'SpO2 Monitoring',
    date: '16/10/2020 10:00',
    icon: 'pi pi-chart-line',
    color: '#9C27B0',
    content:
      "The resident's blood oxygen saturation (SpO2) was monitored. Levels were at 98%, indicating good oxygenation.",
  },
  {
    status: 'Temperature Check',
    date: '16/10/2020 10:15',
    icon: 'pi pi-info-circle',
    color: '#3F51B5',
    content:
      "The resident's body temperature was checked and recorded at 36.7°C, which is within the normal range.",
  },
  {
    status: 'Heart Rate Monitoring',
    date: '16/10/2020 11:00',
    icon: 'pi pi-heart-fill',
    color: '#FF5722',
    content:
      "The resident's heart rate was monitored continuously for 30 minutes. Average heart rate was 75 bpm, within the normal range.",
  },
  {
    status: 'Payment Reminder',
    date: '17/10/2020 09:00',
    icon: 'pi pi-exclamation-circle',
    color: '#FF9800',
    content:
      "A reminder was sent to the resident's family regarding the upcoming monthly payment due.",
  },
  {
    status: 'ECG Follow-Up',
    date: '17/10/2020 14:00',
    icon: 'pi pi-heart',
    color: '#9a1b1b',
    content:
      'A follow-up ECG was performed to compare with previous results. The readings remained consistent with normal heart function.',
  },
  {
    status: 'Blood Pressure Monitoring',
    date: '18/10/2020 09:30',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#673AB7',
    content:
      "Routine blood pressure monitoring was conducted. The resident's readings were stable at 118/76 mmHg.",
  },
  {
    status: 'Nurse Call',
    date: '18/10/2020 12:00',
    icon: 'pi pi-bell',
    color: '#FF9800',
    content:
      'The resident activated the nurse call button for assistance with mobility. A nurse assisted the resident to the common area for a social activity.',
  },
  {
    status: 'SpO2 Check',
    date: '18/10/2020 13:00',
    icon: 'pi pi-chart-line',
    color: '#607D8B',
    content:
      "A routine check of SpO2 levels showed 97%, indicating the resident's oxygen levels are within a healthy range.",
  },
  {
    status: 'Temperature Monitoring',
    date: '18/10/2020 16:00',
    icon: 'pi pi-info-circle',
    color: '#009688',
    content:
      "The resident's temperature was monitored regularly throughout the day. All readings remained within the normal range.",
  },
  {
    status: 'Heart Rate Check',
    date: '19/10/2020 09:00',
    icon: 'pi pi-heart-fill',
    color: '#4CAF50',
    content:
      "The resident's heart rate was checked during a routine examination. The rate was stable at 72 bpm.",
  },
  {
    status: 'Payment Processed',
    date: '19/10/2020 10:30',
    icon: 'pi pi-dollar',
    color: '#9C27B0',
    content:
      "The resident's payment for this month has been successfully processed. A confirmation email was sent to the resident's family.",
  },
  {
    status: 'ECG Monitoring',
    date: '19/10/2020 11:00',
    icon: 'pi pi-heart',
    color: '#E91E63',
    content:
      'Continuous ECG monitoring was conducted for 24 hours to check for any irregularities. Results showed normal cardiac activity.',
  },
  {
    status: 'Blood Pressure Check',
    date: '20/10/2020 08:45',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#3F51B5',
    content:
      'Blood pressure check showed a reading of 125/82 mmHg, which is within acceptable limits.',
  },
  {
    status: 'SpO2 Monitoring',
    date: '20/10/2020 10:00',
    icon: 'pi pi-chart-line',
    color: '#FF9800',
    content:
      "The resident's SpO2 levels were monitored, with readings showing 96% consistently throughout the check.",
  },
  {
    status: 'Temperature Check',
    date: '20/10/2020 12:30',
    icon: 'pi pi-info-circle',
    color: '#607D8B',
    content:
      'Temperature check recorded a reading of 37.0°C, slightly elevated but within a non-concerning range.',
  },
  {
    status: 'Nurse Call',
    date: '21/10/2020 11:15',
    icon: 'pi pi-bell',
    color: '#FFC107',
    content:
      'The resident requested assistance using the nurse call system. The nurse helped with medication administration.',
  },
  {
    status: 'Heart Rate Monitoring',
    date: '21/10/2020 14:00',
    icon: 'pi pi-heart-fill',
    color: '#9a1b1b',
    content:
      "The resident's heart rate was monitored continuously during physical therapy. The average rate was 78 bpm, with no significant deviations.",
  },
  {
    status: 'Payment Due',
    date: '21/10/2020 15:00',
    icon: 'pi pi-exclamation-circle',
    color: '#FF5722',
    content:
      "A notification was sent to remind the resident's family of the upcoming payment due date.",
  },
  {
    status: 'ECG Check',
    date: '22/10/2020 09:00',
    icon: 'pi pi-heart',
    color: '#E91E63',
    content:
      'An ECG check was conducted as part of a routine cardiovascular assessment. The results showed no signs of arrhythmia.',
  },
  {
    status: 'Blood Pressure Monitoring',
    date: '22/10/2020 09:30',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#3F51B5',
    content:
      'Blood pressure readings were taken multiple times during the day. Average readings were 122/78 mmHg.',
  },
  {
    status: 'SpO2 Monitoring',
    date: '22/10/2020 11:00',
    icon: 'pi pi-chart-line',
    color: '#4CAF50',
    content:
      'SpO2 monitoring indicated stable oxygen levels at 97%, with no drops below the acceptable range.',
  },
  {
    status: 'Temperature Check',
    date: '22/10/2020 13:00',
    icon: 'pi pi-info-circle',
    color: '#FF9800',
    content:
      "The resident's temperature was recorded at 36.8°C, within the normal range, indicating no signs of fever.",
  },
  {
    status: 'Nurse Call',
    date: '23/10/2020 10:00',
    icon: 'pi pi-bell',
    color: '#FFC107',
    content:
      "The resident used the nurse call system for assistance with personal care. The nurse promptly attended to the resident's needs.",
  },
  {
    status: 'Heart Rate Check',
    date: '23/10/2020 10:30',
    icon: 'pi pi-heart-fill',
    color: '#9a1b1b',
    content:
      "A manual check of the resident's heart rate showed a consistent rate of 70 bpm, which is within a healthy range.",
  },
  {
    status: 'Payment Confirmation',
    date: '23/10/2020 11:00',
    icon: 'pi pi-dollar',
    color: '#9C27B0',
    content:
      "The resident's payment was confirmed for the current billing cycle. A statement was sent to the family for their records.",
  },
  {
    status: 'ECG Monitoring',
    date: '24/10/2020 09:00',
    icon: 'pi pi-heart',
    color: '#E91E63',
    content:
      'Continuous ECG monitoring was conducted over a 24-hour period to observe cardiac rhythms. The results were normal.',
  },
  {
    status: 'Blood Pressure Check',
    date: '24/10/2020 09:45',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#607D8B',
    content:
      'Blood pressure readings were taken and recorded as 118/74 mmHg, which is within the optimal range.',
  },
  {
    status: 'SpO2 Check',
    date: '24/10/2020 10:15',
    icon: 'pi pi-chart-line',
    color: '#3F51B5',
    content:
      'SpO2 levels were checked and recorded at 98%, indicating that the resident is maintaining healthy oxygen levels.',
  },
  {
    status: 'Temperature Monitoring',
    date: '24/10/2020 10:30',
    icon: 'pi pi-info-circle',
    color: '#FF9800',
    content:
      "The resident's temperature was monitored regularly throughout the day. All readings remained within the normal range.",
  },
  {
    status: 'Heart Rate Monitoring',
    date: '25/10/2020 09:00',
    icon: 'pi pi-heart-fill',
    color: '#4CAF50',
    content:
      "Heart rate monitoring was performed for 15 minutes during physical activity. The resident's heart rate remained stable at 75 bpm.",
  },
  {
    status: 'Nurse Call',
    date: '25/10/2020 11:00',
    icon: 'pi pi-bell',
    color: '#FFC107',
    content:
      'The resident used the nurse call system to request assistance with transferring to a wheelchair. A nurse was able to help within minutes.',
  },
  {
    status: 'ECG Test',
    date: '25/10/2020 14:00',
    icon: 'pi pi-heart',
    color: '#E91E63',
    content:
      "An ECG test was conducted to monitor the resident's heart function. The results indicated normal heart rhythms with no irregularities.",
  },
  {
    status: 'Blood Pressure Check',
    date: '26/10/2020 09:00',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#607D8B',
    content:
      'A routine blood pressure check was conducted, showing a reading of 120/80 mmHg, which is within a healthy range.',
  },
  {
    status: 'SpO2 Monitoring',
    date: '26/10/2020 10:00',
    icon: 'pi pi-chart-line',
    color: '#3F51B5',
    content:
      'SpO2 monitoring showed consistent levels at 97%, indicating good oxygen saturation in the blood.',
  },
  {
    status: 'Temperature Check',
    date: '26/10/2020 11:30',
    icon: 'pi pi-info-circle',
    color: '#FF9800',
    content:
      "The resident's temperature was checked and recorded at 36.9°C, within the normal range for body temperature.",
  },
  {
    status: 'Heart Rate Monitoring',
    date: '26/10/2020 14:00',
    icon: 'pi pi-heart-fill',
    color: '#9a1b1b',
    content:
      "The resident's heart rate was monitored continuously during the afternoon. No abnormal heart activity was detected.",
  },
  {
    status: 'Payment Reminder',
    date: '27/10/2020 09:00',
    icon: 'pi pi-exclamation-circle',
    color: '#FF5722',
    content:
      "A reminder was issued to the resident's family regarding the upcoming payment due date for the next month.",
  },
  {
    status: 'ECG Follow-Up',
    date: '27/10/2020 10:00',
    icon: 'pi pi-heart',
    color: '#E91E63',
    content:
      'A follow-up ECG was performed to monitor any changes in cardiac activity. The results were consistent with previous findings.',
  },
  {
    status: 'Blood Pressure Check',
    date: '27/10/2020 10:30',
    icon: 'pi pi-sort-amount-up-alt',
    color: '#607D8B',
    content:
      'Blood pressure readings remained stable at 117/75 mmHg, indicating good cardiovascular health.',
  },
  {
    status: 'SpO2 Check',
    date: '27/10/2020 11:00',
    icon: 'pi pi-chart-line',
    color: '#4CAF50',
    content:
      'SpO2 levels were checked as part of a routine examination, showing a healthy reading of 97%.',
  },
  {
    status: 'Temperature Monitoring',
    date: '27/10/2020 11:30',
    icon: 'pi pi-info-circle',
    color: '#FF9800',
    content:
      "The resident's temperature was monitored every 4 hours throughout the day, with all readings staying within the normal range.",
  },
]

import {Timeline} from 'primereact/timeline'
import {timelineData} from '../../timelineData'
import {Card} from 'primereact/card'
import {Button} from 'primereact/button'
interface TimelineEvent {
  status?: string
  date?: string
  icon?: string
  color?: string
  image?: string
  content?: string
}
export const TimelineView = () => {
  const events: TimelineEvent[] = timelineData

  const customizedContent = (item: TimelineEvent) => {
    return (
      <Card title={item.status} subTitle={item.date}>
        {/* {item.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`}
            alt={item.image}
            width={200}
            className='shadow-1'
          />
        )} */}
        <p>{item.content}</p>
        <Button label='Read more' className='p-button-text'></Button>
      </Card>
    )
  }
  const customizedMarker = (item: TimelineEvent) => {
    return (
      <span
        //className='flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1'
        style={{
          backgroundColor: item.color,
          height: '2rem',
          width: '2rem',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          color: 'white',
        }}
      >
        <i className={item.icon}></i>
      </span>
    )
  }
  return (
    <div className='card h-80vh w-[65vw]'>
      <div style={{overflow: 'auto'}}>
        <Timeline
          value={events}
          align='alternate'
          className='customized-timeline'
          marker={customizedMarker}
          content={customizedContent}
        />
      </div>
    </div>
  )
}

import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../helpers'

const tasks: ReadonlyArray<{
  icon: string
  color: string
  title: string
  description: string
  link: string
  logo: string
}> = [
  {
    icon: 'user',
    color: 'info',
    title: 'User Management',
    description: 'User Management',
    link: 'user-management',
    logo: '/media/logos/Admin Panel_ User Management.png',
  },
  {
    icon: 'user',
    color: 'info',
    title: 'Roles Management',
    description: 'Roles Management',
    link: 'role-management',
    logo: '/media/logos/Admin Panel_ Role Management.png',
  },

  {
    icon: 'user',
    color: 'info',
    title: 'Pythia Configuration',
    description: 'Pythia Configuration',
    link: 'pythia',
    logo: '/media/logos/Admin Panel_ Pythia Configuration.png',
  },
  {
    icon: 'user',
    color: 'info',
    title: 'AskMe Configuration',
    description: 'AskMe Configuration',
    link: 'ask-me',
    logo: '/media/logos/Admin Panel_ AskMe Configuration.png',
  },
  {
    icon: 'user',
    color: 'info',
    title: 'App Configuration',
    description: 'App Configuration',
    link: 'app',
    logo: '/media/logos/Admin Panel_ AskMe Configuration.png',
  },
  // {
  //   icon: 'user',
  //   color: 'success',
  //   title: 'Applications Management',
  //   description: 'Applications Management',
  //   link: 'external-applications',
  // },
  // {
  //   icon: 'user',
  //   color: 'success',
  //   title: 'Software Management',
  //   description: 'Software Management',
  //   link: 'external-software',
  // },
]

const AdminConfig = () => (
  <div className='mx-5'>
    {/*begin::Header*/}
    <h3 className='fw-bolder text-dark mb-10 mx-0'>Admin Menu</h3>
    {/*end::Header*/}

    {/*begin::Body*/}
    <div className='mb-12'>
      {tasks.map((t) => (
        <div className='d-flex align-items-center mb-7' key={t.title}>
          {/*begin::Symbol*/}
          <div className='symbol symbol-50px me-5'>
            <span className={`symbol-label bg-light-${t.color}`}>
              {/*<KTIcon iconName={t.icon} className={`fs-2x text-${t.color}`} />*/}
              <img src={t.logo} alt={t.title} className='h-100' />
            </span>
          </div>
          {/*end::Symbol*/}

          {/*begin::Text*/}
          <div className='d-flex flex-column'>
            <Link
              to={`configuration/${t.link}`}
              className='text-gray-800 text-hover-primary fs-6 fw-bold'
            >
              {t.title}
            </Link>

            <span className='text-muted fw-bold'>{t.description}</span>
          </div>
          {/*end::Text*/}
        </div>
      ))}
    </div>
    {/*end::Body*/}
  </div>
)

export {AdminConfig}

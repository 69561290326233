import { useMutation } from '@tanstack/react-query'
import {askMeRolesClient} from 'src/api/apiClient'
import {UpdateCategoryRolesCommand} from 'src/api/generatedClient'
import { queryClient } from 'src/libs/react-query'

const updateCategoryRoles = async (request: UpdateCategoryRolesCommand) => {
  const response = await askMeRolesClient.createRole(request)
  return response
}

export const useUpdateCategoryRoles = () => useMutation({
    mutationFn: updateCategoryRoles,
    onSuccess: () => queryClient.invalidateQueries({queryKey: ['roles']})
})



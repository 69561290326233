import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface FinancialRecord {
  month: string;
  invoiceDate: string;
  amountDue: string;
  governmentSupport: string;
  paymentReceived: string;
  paymentDate: string;
  overdueAmount: string;
  balanceForward: string;
  notes: string;
}

interface Summary {
  totalAmountDue: string;
  totalGovSupport: string;
  totalResidentPayments: string;
  totalOverdueAmount: string;
  outstandingBalance: string;
}

const financialRecords: FinancialRecord[] = [
  { month: "Sep 2023", invoiceDate: "01-Sep-2023", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "05-Sep-2023", overdueAmount: "$0", balanceForward: "$0", notes: "Paid on time, gov't support applied" },
  { month: "Oct 2023", invoiceDate: "01-Oct-2023", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "04-Oct-2023", overdueAmount: "$0", balanceForward: "$0", notes: "Paid on time, gov't support applied" },
  { month: "Nov 2023", invoiceDate: "01-Nov-2023", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "05-Nov-2023", overdueAmount: "$0", balanceForward: "$0", notes: "Paid on time, gov't support applied" },
  { month: "Dec 2023", invoiceDate: "01-Dec-2023", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$800", paymentDate: "12-Dec-2023", overdueAmount: "$400", balanceForward: "$400", notes: "Partial payment received, balance overdue" },
  { month: "Jan 2024", invoiceDate: "01-Jan-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "06-Jan-2024", overdueAmount: "$0", balanceForward: "$400", notes: "Overdue carried forward from Dec 2023" },
  { month: "Feb 2024", invoiceDate: "01-Feb-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$800", paymentDate: "10-Feb-2024", overdueAmount: "$400", balanceForward: "$800", notes: "Partial payment received, balance overdue" },
  { month: "Mar 2024", invoiceDate: "01-Mar-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,000", paymentDate: "08-Mar-2024", overdueAmount: "$200", balanceForward: "$1,000", notes: "Partial payment received" },
  { month: "Apr 2024", invoiceDate: "01-Apr-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "03-Apr-2024", overdueAmount: "$0", balanceForward: "$1,000", notes: "No overdue payment this month, balance carried forward" },
  { month: "May 2024", invoiceDate: "01-May-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "06-May-2024", overdueAmount: "$0", balanceForward: "$1,000", notes: "Balance forward from overdue payments" },
  { month: "Jun 2024", invoiceDate: "01-Jun-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "04-Jun-2024", overdueAmount: "$0", balanceForward: "$1,000", notes: "Full payment received" },
  { month: "Jul 2024", invoiceDate: "01-Jul-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$800", paymentDate: "07-Jul-2024", overdueAmount: "$400", balanceForward: "$1,400", notes: "Partial payment received, overdue balance increases" },
  { month: "Aug 2024", invoiceDate: "01-Aug-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "$1,200", paymentDate: "05-Aug-2024", overdueAmount: "$0", balanceForward: "$1,400", notes: "Gov't support applied, overdue carried forward" },
  { month: "Sep 2024", invoiceDate: "01-Sep-2024", amountDue: "$3,000", governmentSupport: "$1,800", paymentReceived: "Pending", paymentDate: "Pending", overdueAmount: "$1,400", balanceForward: "$1,400", notes: "Payment pending, overdue balance of $1,400 carried forward" }
];

const financialSummary: Summary = {
  totalAmountDue: "$36,000",
  totalGovSupport: "$21,600",
  totalResidentPayments: "$12,000",
  totalOverdueAmount: "$1,400",
  outstandingBalance: "$1,400"
};

export const Financial = () => {
  return (
    <div className="p-card p-5">
      <h2>Financial Profile: John Smith (Last 12 Months)</h2>
      <div className="flex flex-col gap-10">

        <DataTable value={financialRecords} paginator rows={5} responsiveLayout="scroll">
          <Column field="month" header="Month" />
          <Column field="invoiceDate" header="Invoice Date" />
          <Column field="amountDue" header="Amount Due" />
          <Column field="governmentSupport" header="Government Support" />
          <Column field="paymentReceived" header="Payment Received" />
          <Column field="paymentDate" header="Payment Date" />
          <Column field="overdueAmount" header="Overdue Amount" />
          <Column field="balanceForward" header="Balance Forward" />
          <Column field="notes" header="Notes" />
        </DataTable>

        <ul>
          <li><strong>Total Amount Due for the Last 12 Months:</strong> {financialSummary.totalAmountDue}</li>
          <li><strong>Total Government Support Received:</strong> {financialSummary.totalGovSupport}</li>
          <li><strong>Total Resident/Family Payments:</strong> {financialSummary.totalResidentPayments}</li>
          <li><strong>Total Overdue Amount:</strong> {financialSummary.totalOverdueAmount}</li>
          <li><strong>Outstanding Balance:</strong> {financialSummary.outstandingBalance}</li>
        </ul>

        <p><strong>Government Support:</strong> John Smith receives $1,800 per month from government subsidies as part of the Aged Care Pension and Commonwealth Aged Care Support.</p>
        <p><strong>Overdue Balances:</strong> Starting in December 2023, a pattern of partial payments resulted in overdue balances. These have been carried forward and currently total $1,400.</p>
        <p><strong>Resident Payments:</strong> Payments made by John Smith's family have been consistent, but some months included partial payments, leading to overdue amounts.</p>

        <ul>
          <li><strong>Overdue Balance:</strong> Notify John Smith’s family about the current overdue balance of $1,400, and discuss options for resolving the outstanding amount, such as a payment plan or government subsidy review.</li>
          <li><strong>Payment Plan Suggestion:</strong> Propose a plan to pay the overdue amount over the next 3 months, with additional payments of $500 per month to cover both current dues and overdue amounts.</li>
          <li><strong>Monitor for Changes:</strong> Continuously monitor payments to avoid further increases in the overdue balance, and apply any additional government support if available.</li>
        </ul>
      </div>
    </div>
  );
};

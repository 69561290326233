import {useTranslation} from 'react-i18next'

import Suggestions from '../Suggestions/Suggestions'
import TextField from '../TextField/TextField'

import type {ReactElement} from 'react'
import type {IInputBlock} from './interfaces/IInputBlock'

import styles from './sass/InputBlock.module.scss'

function InputBlock({
  searchTerm,
  setSearchTerm,
  handleAddMessage,
  isMessageLoading,
  setFiles,
  files,
  enableFiles = true,
  enableSuggestions = true,
}: IInputBlock): ReactElement {
  const {t} = useTranslation()

  return (
    <div className={styles.inputBlock}>
      <div className={styles.inputContainer}>
        <TextField
          files={files}
          setFiles={setFiles}
          searchTerm={searchTerm}
          setSearchTerm={(prompt) => {
            setSearchTerm(prompt)
          }}
          handleAddMessage={() => {
            handleAddMessage()
          }}
          isMessageLoading={isMessageLoading}
          enableFiles={enableFiles}
          enableSuggestions={enableSuggestions}
        />
        {enableSuggestions && <Suggestions />}
      </div>
    </div>
  )
}

export default InputBlock

import {testIdHeader} from '../../constants/TestId'

import type {ReactElement} from 'react'

import styles from './sass/Header.module.scss'

/**
 * Main layout header component
 */
function Header(): ReactElement {
  return <header className={styles.header} data-testid={testIdHeader} />
}

export default Header

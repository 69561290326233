import {useQuery} from '@tanstack/react-query'

import type {ISubmenuItem} from 'src/app/components/NavItem/interfaces/INavItem'
import {ContentItemService} from '../../../api'

export const useStatuses = (id?: string) =>
  useQuery({
    queryKey: ['statuses', id],
    queryFn: () => ContentItemService.contentItemGetContentItemStatuses(id!),
    enabled: !!id,
    select: (data): ISubmenuItem[] =>
      data.map((item) => ({
        submenuId: item.id ?? '',
        submenuTitle: item.name ?? '',
        to: '',
      })),
  })

export interface IAssistFileContent {
  content: string
  context: string
}

export const useFileContent = (contextText: string): IAssistFileContent => {
  try {
    return JSON.parse(contextText) as IAssistFileContent
  } catch (e) {
    return {content: '', context: contextText}
  }
}

import mainApi from '../../../../../../api/mainApi'
import {CONTENT_ITEMS_DEFAULT_PAGE_SIZE} from '../../../../constants/general'
import {GET, POST, PUT, DELETE} from '../../../../constants/requestMethods'
import {
  CONTENT_ITEM,
  CONTENT_ITEM_ANALYSIS_CATEGORIES,
  CONTENT_ITEM_GENERATE_ANALYSIS,
  PYTHIA_ORACLE_OFFERINGS,
} from '../../../../constants/requestUrls'
import type {AnalysisCategoriesResponseType} from '../interfaces/IAnalysisCategoryResponse'
import {IContentItemParams, IContentItemsParams} from '../interfaces/IContentItemsParams'
import {IContentItem, IContentItemsResponse} from '../interfaces/IContentItemsResponse'
import type {IGenerateAnalysisParams} from '../interfaces/IGenerateAnalysisParams'
import type IGenerateAnalysisResponse from '../interfaces/IGenerateAnalysisResponse'
import type {IOracleOffering} from '../interfaces/IOracleOfferingResponse'
import {IUpdateContentItemParams} from '../interfaces/IUpdateContentItemParams'

const sortAlphabetically = (a: any, b: any) => {
  return a.name.trim() > b.name.trim() ? 1 : -1
}

const contentItemsApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchContentItems: builder.query<IContentItemsResponse, IContentItemsParams>({
      query: ({statusId, typeId, searchInput = ''}) => ({
        url: `${CONTENT_ITEM}`,
        params: {
          PageNumber: 1,
          PageSize: CONTENT_ITEMS_DEFAULT_PAGE_SIZE,
          StatusId: statusId,
          TypeId: typeId,
          SearchInput: searchInput,
        },
        method: GET,
      }),
      providesTags: ['ContentItems'],
    }),
    fetchContentItem: builder.query<IContentItem, IContentItemParams>({
      query: ({id}) => ({
        url: `${CONTENT_ITEM}/${id}`,
        method: GET,
      }),
    }),
    updateContentItem: builder.mutation<string, IUpdateContentItemParams>({
      query: (params) => ({
        url: `${CONTENT_ITEM}/${params.id}`,
        method: PUT,
        body: {
          ...params,
        },
      }),
      invalidatesTags: ['ContentItems'],
    }),
    deleteContentItem: builder.mutation<string, string>({
      query: (id) => ({
        url: `${CONTENT_ITEM}/${id}`,
        method: DELETE,
      }),
      invalidatesTags: ['ContentItems'],
    }),
    getAnalysisCategories: builder.query<AnalysisCategoriesResponseType, IContentItemsParams>({
      query: ({statusId, typeId}) =>
        statusId && typeId
          ? {
              url: CONTENT_ITEM_ANALYSIS_CATEGORIES,
              params: {
                statusId,
                typeId,
              },
              method: GET,
            }
          : '',
      transformResponse: (response: AnalysisCategoriesResponseType) =>
        response.sort(sortAlphabetically),
    }),
    getOracleOffering: builder.query<IOracleOffering[], void>({
      query: () => ({
        url: PYTHIA_ORACLE_OFFERINGS,
        method: GET,
      }),
    }),
    generateAnalysis: builder.mutation<IGenerateAnalysisResponse, IGenerateAnalysisParams>({
      query: ({serviceId, id, resetData}) => ({
        url: `${CONTENT_ITEM_GENERATE_ANALYSIS.replace('{id}', id)}`,
        method: POST,
        params: {
          serviceId,
          resetData,
        },
      }),
    }),
  }),
})

export const {
  useFetchContentItemsQuery,
  useFetchContentItemQuery,
  useGetAnalysisCategoriesQuery,
  useGetOracleOfferingQuery,
  useGenerateAnalysisMutation,
  useUpdateContentItemMutation,
  useDeleteContentItemMutation,
} = contentItemsApi

export default contentItemsApi

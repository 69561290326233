import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface EmergencyProtocol {
  emergencyType: string;
  symptoms: string;
  immediateActions: string;
  nextSteps: string;
}

interface SpecificProtocol {
  protocolType: string;
  risk: string;
  actions: string;
}

interface ContactInfo {
  contact: string;
  relationship: string;
  phoneNumber: string;
}

const emergencyProtocols: EmergencyProtocol[] = [
  {
    emergencyType: "Fall",
    symptoms: "Dizziness, unsteadiness, visible injury",
    immediateActions: "1. Assess for injuries (head, neck, limbs). \n2. Do not move the resident if there is a risk of serious injury. \n3. Call for assistance.",
    nextSteps: "1. If no serious injury, assist the resident up with help. \n2. Record the incident and notify the physician. \n3. Monitor for 24 hours for any delayed symptoms."
  },
  {
    emergencyType: "Hypoglycemia (Low Blood Sugar)",
    symptoms: "Shakiness, sweating, confusion, dizziness",
    immediateActions: "1. Administer fast-acting sugar (e.g., glucose tablets or juice). \n2. Recheck blood sugar after 15 minutes. \n3. Repeat if blood sugar is below 4.0 mmol/L.",
    nextSteps: "1. If unresponsive or symptoms persist, call emergency services. \n2. Notify the physician and family. \n3. Record the incident in medical notes."
  },
  {
    emergencyType: "Hyperglycemia (High Blood Sugar)",
    symptoms: "Increased thirst, frequent urination, confusion",
    immediateActions: "1. Check blood sugar levels. \n2. Ensure hydration with water. \n3. Monitor for signs of diabetic ketoacidosis (vomiting, rapid breathing).",
    nextSteps: "1. Contact the physician if blood sugar is above 15 mmol/L. \n2. Administer insulin as per emergency orders if applicable."
  },
  {
    emergencyType: "Cardiac Event (Heart Attack/Angina)",
    symptoms: "Chest pain, shortness of breath, sweating, nausea",
    immediateActions: "1. Call emergency services immediately. \n2. Assist the resident to sit and keep calm. \n3. Administer nitroglycerin (if prescribed).",
    nextSteps: "1. Monitor vital signs while waiting for emergency responders. \n2. Contact the family and notify the physician."
  },
  {
    emergencyType: "Stroke",
    symptoms: "Sudden weakness (one side), slurred speech, confusion",
    immediateActions: "1. Call emergency services immediately (FAST protocol: Face, Arms, Speech, Time). \n2. Keep the resident safe, but do not give food or drink.",
    nextSteps: "1. Monitor closely until help arrives. \n2. Notify family and physician."
  },
  {
    emergencyType: "Urinary Tract Infection (UTI) Symptoms",
    symptoms: "Fever, burning sensation, hematuria",
    immediateActions: "1. Administer PRN Ural (if urinary burning is reported). \n2. Encourage hydration.",
    nextSteps: "1. Contact the physician if symptoms worsen. \n2. Monitor for signs of sepsis (fever, confusion)."
  },
  {
    emergencyType: "Severe Pain (Arthritis/Back Pain)",
    symptoms: "Sharp pain in joints or back, limited mobility",
    immediateActions: "1. Administer PRN pain relief (Paracetamol). \n2. Apply a cold compress to the affected area.",
    nextSteps: "1. If no improvement after 30 minutes, contact the physician for further evaluation."
  },
  {
    emergencyType: "Hypertensive Crisis",
    symptoms: "Severe headache, vision changes, chest pain",
    immediateActions: "1. Check blood pressure immediately. \n2. If systolic BP is above 180 mmHg or diastolic BP is above 120 mmHg, contact emergency services.",
    nextSteps: "1. Monitor vitals while waiting for emergency responders. \n2. Notify the family and physician."
  },
  {
    emergencyType: "Dehydration",
    symptoms: "Dry mouth, confusion, low urine output",
    immediateActions: "1. Encourage fluid intake. \n2. Administer IV fluids if prescribed. \n3. Monitor urinary output.",
    nextSteps: "1. If symptoms persist or worsen, contact the physician. \n2. Notify family if dehydration is severe."
  }
];

const specificProtocols: SpecificProtocol[] = [
  {
    protocolType: "Fall Management Protocol",
    risk: "John Smith has a history of falls due to osteoarthritis and reduced mobility.",
    actions: "• Do not move the resident if there is a risk of injury to the head, neck, or spine. Call for assistance.\n• Conduct a visual inspection for cuts, bruises, or visible fractures.\n• Monitor for 24 hours and record the incident."
  },
  {
    protocolType: "Diabetic Emergency (Hypoglycemia/Hyperglycemia)",
    risk: "John Smith is at risk of both low and high blood sugar due to type 2 diabetes.",
    actions: "• For Hypoglycemia: Administer fast-acting sugar, check blood sugar after 15 minutes.\n• For Hyperglycemia: Check blood sugar levels, encourage hydration, monitor for ketoacidosis."
  },
  {
    protocolType: "Cardiac Emergency Protocol",
    risk: "John Smith has hypertension and hypercholesterolemia, placing him at risk for heart-related events.",
    actions: "• Call emergency services immediately, assist John to sit down and administer nitroglycerin if prescribed. Monitor vital signs."
  },
  {
    protocolType: "Stroke Protocol",
    risk: "John Smith has hypertension, increasing his risk for a stroke.",
    actions: "• Call emergency services and follow the FAST protocol. Do not give food or drink."
  },
  {
    protocolType: "UTI Emergency",
    risk: "John Smith has a history of recurrent UTIs, which may develop into serious infections.",
    actions: "• Administer PRN Ural, encourage hydration, monitor for signs of worsening infection or sepsis."
  },
  {
    protocolType: "Hypertensive Crisis Protocol",
    risk: "John Smith has hypertension and is at risk for hypertensive emergencies.",
    actions: "• Check blood pressure and contact emergency services if systolic BP is above 180 mmHg or diastolic BP is above 120 mmHg."
  }
];

const contactInformation: ContactInfo[] = [
  { contact: "Jane Smith", relationship: "Daughter", phoneNumber: "0490422257" }
];

export const EmergencyProtocols = () => {
  return (
    <div className="p-card p-5">
      <h2>Emergency Protocols Profile: John Smith</h2>

      <div className="flex flex-col gap-10">
        <ul>
          <li>Diabetes Type 2</li>
          <li>Hypertension</li>
          <li>Osteoarthritis</li>
          <li>Recurrent Urinary Tract Infections (UTIs)</li>
          <li>History of Falls</li>
          <li>Hypercholesterolemia</li>
        </ul>

        <DataTable value={emergencyProtocols} paginator rows={5} responsiveLayout="scroll">
          <Column field="emergencyType" header="Emergency Type" />
          <Column field="symptoms" header="Symptoms" />
          <Column field="immediateActions" header="Immediate Actions" />
          <Column field="nextSteps" header="Next Steps" />
        </DataTable>

        {specificProtocols.map((protocol, index) => (
          <div key={index}>
            <h4>{protocol.protocolType}</h4>
            <p><strong>Risk:</strong> {protocol.risk}</p>
            <p><strong>Actions:</strong> {protocol.actions}</p>
          </div>
        ))}

        <DataTable value={contactInformation} paginator rows={5} responsiveLayout="scroll">
          <Column field="contact" header="Contact" />
          <Column field="relationship" header="Relationship" />
          <Column field="phoneNumber" header="Phone Number" />
        </DataTable>
      </div>
    </div >
  );
};

import {Outlet} from 'react-router-dom'

import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../Header/Header'

import type {ReactElement} from 'react'

import styles from './sass/Page.module.scss'

/**
 * Main page layout
 */
function Page(): ReactElement {
  return (
    <div id='modal' className={`${styles.page} page`}>
      <Header />
      <Sidebar />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Page

import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import mainApi from './apis/main'
import chat from './slices/chat/slice'
import suggestions from './slices/suggestions/slice'
import categories from './slices/categories/slice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'theme'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    [mainApi.reducerPath]: mainApi.reducer,
    chat,
    suggestions,
    categories,
  })
)

export function makeStore(preloadedState = {}) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState: Object.keys(preloadedState).length ? preloadedState : undefined,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([mainApi.middleware]),
  })
}

export const store = makeStore()

setupListeners(store.dispatch)

export const persistor = persistStore(store)

import {Link, useParams} from 'react-router-dom'

import type {ReactElement} from 'react'
import type INavItem from './interfaces/INavItem'

import Arrow from '../../pages/PythiaV3/components/Arrow/Arrow'

import styles from './sass/NavItem.module.scss'
import {useStatuses} from '../../common/queries/getStatuses'

export default function NavItem({
  icon,
  title,
  id,
  onClickHandler,
  openedItemId,
  isSidebarOpened,
}: INavItem): ReactElement {
  const {data: submenu} = useStatuses(id)
  const {typeId, statusId} = useParams()
  const getItemHeaderStyles = () => {
    if (typeId === id && !isSidebarOpened) {
      return styles.activeItemHeader
    }
    if (!isSidebarOpened) {
      return styles.shortItemHeader
    }
    return ''
  }

  return (
    <li className={styles.item}>
      <div
        className={`${styles.itemHeader} ${getItemHeaderStyles()}`}
        role='presentation'
        onClick={() => onClickHandler(id)}
      >
        <img src={icon} alt='icon' className={styles.icon} />
        <div className={`${styles.titleGroup} ${!isSidebarOpened ? styles.shortTitleGroup : ''}`}>
          <span className={styles.title}>{title}</span>
          <Arrow isOpen={openedItemId === id} />
        </div>
      </div>
      <ul
        className={`${styles.submenu}
        ${openedItemId === id ? styles.active : styles.collapse}
        `}
      >
        {submenu
          ? submenu.map(({submenuId, submenuTitle}) => (
              <li
                key={submenuId}
                className={`${styles.submenuItem}
            ${statusId === submenuId && styles.activeSubmenuItem}
            ${!isSidebarOpened ? styles.shortSubmenuItem : ''}`}
              >
                <Link to={`type/${id}/status/${submenuId}`} className={styles.submenuLink}>
                  {submenuTitle}
                </Link>
              </li>
            ))
          : null}
      </ul>
    </li>
  )
}

import {CancelablePromise} from '../core/CancelablePromise'
import {request as __request} from '../core/request'
import {OpenAPI} from '../core/OpenAPI'
import {FilesPropModel} from '@syncfusion/ej2-react-inputs'

export type FileWithIdModel = FilesPropModel & {id: string}

export class UploadedFileService {
  /**
   * @param sessionId
   * @param fileId
   * @returns FilesPropModel[]
   * @throws ApiError
   */
  public static getUploadedFiles(
    sessionId: string,
    fileId: string
  ): CancelablePromise<FileWithIdModel[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/files',
      query: {
        sessionId,
        fileId,
      },
    })
  }
}

import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from 'redux-persist'
import {setupListeners} from '@reduxjs/toolkit/query'
import translates from 'src/app/common/store/slices/translates/slice'
import dashboard from '../../../common/store/slices/dashboard/slice'
import mainApi from '../../../../api/mainApi'
import contentItems from '../../../common/store/slices/contentItems/slice'
import assistApi from './api/assistApi'
import chat from '../../AskMe/store/slices/chat/slice'
import suggestions from '../../AskMe/store/slices/suggestions/slice'
import categories from '../../AskMe/store/slices/suggestions/slice'
import suggestionsApi from '../../AskMe/store/slices/suggestions/apis/suggestions'

const reducers = combineReducers({
  [mainApi.reducerPath]: mainApi.reducer,
  [assistApi.reducerPath]: assistApi.reducer,
  [suggestionsApi.reducerPath]: suggestionsApi.reducer,
  translates,
  dashboard,
  contentItems,
  chat,
  suggestions,
  categories,
})

export function makeStore(preloadedState = {}) {
  return configureStore({
    reducer: reducers,
    preloadedState: Object.keys(preloadedState).length ? preloadedState : undefined,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([mainApi.middleware, assistApi.middleware, suggestionsApi.middleware]),
  })
}

export const store = makeStore()

setupListeners(store.dispatch)

import {request as __request} from '../core/request'
import {CancelablePromise} from '../core/CancelablePromise'
import {OpenAPI} from '../core/OpenAPI'

export class AssistService {
  public static assistGeneratePrompt(
    prompt: string,
    actionId?: string,
    sessionId?: string
  ): CancelablePromise<{result: string; success: boolean}> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/Assist/prompts',
      body: {prompt, actionId, sessionId},
    })
  }

  public static assistExportHtmlToFile(html: string) {
    const storedToken = localStorage.getItem('kt-auth-react-v')
    const {api_token} = storedToken && JSON.parse(storedToken)

    return fetch('/api/Assist/export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${api_token}`,
      },
      body: JSON.stringify({html: html}),
    })
  }
}

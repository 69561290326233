import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface Medication {
  name: string;
  dosage: string;
  frequency: string;
  route: string;
  indication: string;
  prescribingPhysician: string;
  startDate: string;
  comments: string;
}

interface MedicationSchedule {
  timeOfDay: string;
  medications: string[];
  instructions: string;
}

interface PRNUsage {
  date: string;
  time: string;
  medication: string;
  reason: string;
  painScore: string;
  effectiveness: string;
  comments: string;
}

interface MonitoringCompliance {
  area: string;
  details: string[];
}

interface SideEffect {
  date: string;
  medication: string;
  sideEffect: string;
  actionTaken: string;
  outcome: string;
}

interface MissedDose {
  date: string;
  medication: string;
  reason: string;
  actionTaken: string;
}

const medications: Medication[] = [
  { name: "Metformin", dosage: "500 mg", frequency: "Once daily (Morning)", route: "Oral", indication: "Type 2 Diabetes Management", prescribingPhysician: "Dr. Michael Lee", startDate: "March 12, 2024", comments: "Monitor blood glucose regularly." },
  { name: "Lisinopril", dosage: "10 mg", frequency: "Once daily (Morning)", route: "Oral", indication: "Hypertension", prescribingPhysician: "Dr. Michael Lee", startDate: "March 12, 2024", comments: "Check blood pressure daily." },
  { name: "Paracetamol", dosage: "500 mg", frequency: "PRN (As needed)", route: "Oral", indication: "Pain relief (Osteoarthritis)", prescribingPhysician: "Dr. Michael Lee", startDate: "March 12, 2024", comments: "Administer when pain exceeds 3/10." },
  { name: "Ural (Sodium Citrate)", dosage: "2 sachets", frequency: "PRN (As needed)", route: "Oral", indication: "Urinary discomfort (UTI)", prescribingPhysician: "Dr. Michael Lee", startDate: "April 1, 2024", comments: "Administer for burning sensation, monitor hydration." },
  { name: "Atorvastatin", dosage: "20 mg", frequency: "Once daily (Evening)", route: "Oral", indication: "Cholesterol management", prescribingPhysician: "Dr. Michael Lee", startDate: "April 1, 2024", comments: "Administer with or after meals." },
  { name: "Multivitamin", dosage: "1 tablet", frequency: "Once daily (Morning)", route: "Oral", indication: "General health and immunity", prescribingPhysician: "Dr. Michael Lee", startDate: "June 1, 2024", comments: "Administer with food to prevent stomach upset." }
];

const medicationSchedule: MedicationSchedule[] = [
  { timeOfDay: "Morning (08:00 AM)", medications: ["Metformin 500 mg", "Lisinopril 10 mg", "Multivitamin 1 tablet"], instructions: "Take with breakfast. Ensure water intake to support absorption. Blood glucose and blood pressure monitoring post-medication." },
  { timeOfDay: "Evening (08:00 PM)", medications: ["Atorvastatin 20 mg"], instructions: "Administer after dinner. Monitor for any side effects." },
  { timeOfDay: "PRN (As Needed)", medications: ["Paracetamol 500 mg", "Ural 2 sachets"], instructions: "Administer when pain (3/10 or higher) or urinary discomfort occurs. Record pain score before and after administration to assess effectiveness. Ensure adequate hydration." }
];

const prnUsage: PRNUsage[] = [
  { date: "August 3, 2024", time: "06:50 AM", medication: "Ural 2 sachets", reason: "Urinary discomfort (burning)", painScore: "6/10 → 2/10", effectiveness: "Effective within 30 minutes", comments: "Resident felt relief and was comfortable." },
  { date: "August 6, 2024", time: "09:10 PM", medication: "Paracetamol 500 mg", reason: "Lower back pain (Osteoarthritis)", painScore: "8/10 → 4/10", effectiveness: "Moderate relief after 45 minutes", comments: "Pain persisted at mild levels." },
  { date: "August 9, 2024", time: "07:45 AM", medication: "Paracetamol 500 mg", reason: "Knee pain (Osteoarthritis)", painScore: "5/10 → 3/10", effectiveness: "Effective within 30 minutes", comments: "Cold compress applied with Paracetamol." },
  { date: "August 12, 2024", time: "01:35 PM", medication: "Ural 2 sachets", reason: "Urinary discomfort (burning)", painScore: "4/10 → 1/10", effectiveness: "Effective within 30 minutes", comments: "Resident encouraged to drink more water." },
  { date: "August 18, 2024", time: "08:20 PM", medication: "Ural 2 sachets", reason: "Urinary discomfort (burning)", painScore: "6/10 → 2/10", effectiveness: "Effective within 30 minutes", comments: "Urine output increased after medication." },
  { date: "August 22, 2024", time: "11:55 AM", medication: "Paracetamol 500 mg", reason: "Lower back pain (Osteoarthritis)", painScore: "5/10 → 2/10", effectiveness: "Effective within 45 minutes", comments: "Resident felt relief, repositioned in bed." }
];

const monitoringCompliance: MonitoringCompliance[] = [
  { area: "Blood Glucose Monitoring", details: ["Frequency: Daily (before breakfast)", "Target Range: 4.0 – 7.0 mmol/L (Fasting)", "Notes: Blood glucose has been stable between 5.5 - 6.8 mmol/L."] },
  { area: "Blood Pressure Monitoring", details: ["Frequency: Daily (before Lisinopril administration)", "Target Range: 120/80 mmHg - 140/90 mmHg", "Notes: Recent readings are within acceptable range (130/85 mmHg)."] },
  { area: "Cholesterol Levels", details: ["Last Test: July 15, 2024", "Results: Total cholesterol 4.8 mmol/L (within target)", "Next Test: October 15, 2024 (3-month review)."] }
];

const sideEffects: SideEffect[] = [
  { date: "July 10, 2024", medication: "Metformin", sideEffect: "Mild nausea after breakfast", actionTaken: "Advised to take medication with food.", outcome: "Nausea resolved. No further incidents reported." },
  { date: "August 22, 2024", medication: "Lisinopril", sideEffect: "Slight dizziness after dosage", actionTaken: "Encouraged hydration and rest.", outcome: "Dizziness subsided after 15 minutes." }
];

const missedDoses: MissedDose[] = [
  { date: "August 5, 2024", medication: "Metformin 500 mg", reason: "Resident refused breakfast", actionTaken: "Nurse encouraged meal consumption, offered later." }
];

export const MedicatonManagement= () => {
  return (
    <div className="p-card p-5">
      <h2>Medication Management Profile: John Smith</h2>
      <div className='flex flex-col gap-10'>
        <DataTable value={medications} paginator rows={5} responsiveLayout="scroll">
          <Column field="name" header="Medication Name" />
          <Column field="dosage" header="Dosage" />
          <Column field="frequency" header="Frequency" />
          <Column field="route" header="Route" />
          <Column field="indication" header="Indication" />
          <Column field="prescribingPhysician" header="Prescribing Physician" />
          <Column field="startDate" header="Start Date" />
          <Column field="comments" header="Comments/Notes" />
        </DataTable>

        {medicationSchedule.map((schedule, index) => (
          <div key={index}>
            <h4>Time of Day: {schedule.timeOfDay}</h4>
            <ul>
              {schedule.medications.map((med, idx) => (
                <li key={idx}>{med}</li>
              ))}
            </ul>
            <p><strong>Instructions:</strong> {schedule.instructions}</p>
          </div>
        ))}

        <DataTable value={prnUsage} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="time" header="Time" />
          <Column field="medication" header="Medication" />
          <Column field="reason" header="Reason for Administration" />
          <Column field="painScore" header="Pain Score (Pre/Post)" />
          <Column field="effectiveness" header="Effectiveness" />
          <Column field="comments" header="Comments" />
        </DataTable>
        {monitoringCompliance.map((compliance, index) => (
          <div key={index}>
            <h4>{compliance.area}</h4>
            <ul>
              {compliance.details.map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}

        <DataTable value={sideEffects} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="medication" header="Medication" />
          <Column field="sideEffect" header="Reported Side Effect" />
          <Column field="actionTaken" header="Action Taken" />
          <Column field="outcome" header="Outcome" />
        </DataTable>

        <DataTable value={missedDoses} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="medication" header="Medication" />
          <Column field="reason" header="Reason for Missed Dose" />
          <Column field="actionTaken" header="Action Taken" />
        </DataTable>

        <p>
          <strong>Medication Review:</strong> Conduct a medication review in September 2024 to evaluate pain management effectiveness and the potential need for a stronger pain reliever for osteoarthritis and lower back pain.
        </p>
        <p>
          <strong>Hydration:</strong> John Smith is encouraged to maintain hydration, particularly due to his recurrent urinary tract infections. Daily fluid intake is monitored, with a target of 1.5–2 liters per day.
        </p>
        <p>
          <strong>PRN Evaluation:</strong> Ural has been highly effective in treating urinary discomfort, with a reduction in pain within 30 minutes of administration. PRN Paracetamol has provided moderate relief for osteoarthritis pain but may require further evaluation for more persistent discomfort.
        </p>

        <p><strong>Overall Medication Compliance:</strong> High. John Smith has been largely compliant with his scheduled medications, with minimal missed doses.</p>
        <p><strong>PRN Medication Use:</strong> Ural and Paracetamol have been effective in managing urinary discomfort and osteoarthritis pain. The frequency of PRN usage indicates ongoing issues with pain management, particularly in the lower back.</p>
        <p><strong>Side Effects:</strong> Minimal side effects noted, with no severe reactions to current medications.</p>
        <p><strong>Recommendations:</strong> Continue with the current regimen of PRN medications for pain and urinary discomfort. Consider a review of Paracetamol's effectiveness for managing osteoarthritis pain. Ensure ongoing monitoring of blood glucose and blood pressure to maintain control of diabetes and hypertension.</p>
      </div>
    </div>
  );
};


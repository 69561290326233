import {createSlice} from '@reduxjs/toolkit'

import clientDropdownItems from '../../../mocks/clientDropdownItems'

import type {PayloadAction} from '@reduxjs/toolkit'
import type IClientFilter from './interfaces/IClientFilter'

export const initialState: IClientFilter = {
  isOpenClient: false,
  clientName: clientDropdownItems[0].title,
}

const clientFilterSlice = createSlice({
  name: 'clientFilter',
  initialState,
  reducers: {
    setIsOpenClient: (state) => {
      state.isOpenClient = !state.isOpenClient
    },
    setClientFilterState: (state, action: PayloadAction<Partial<IClientFilter>>) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const {setIsOpenClient, setClientFilterState} = clientFilterSlice.actions

export default clientFilterSlice.reducer

import {useMutation} from '@tanstack/react-query'
import {pythiaClient} from 'src/api/apiClient'
import {FileParameter} from 'src/api/generatedClient'

type CreateMessageOptions = {
  message: string
  subCategory: string
  files: File[] | null | undefined
}

const createChatMessage = ({message, subCategory, files}: CreateMessageOptions) => {
  const fileParameters = files?.map((file) => ({data: file, fileName: file.name} as FileParameter))
  return pythiaClient.createMessage(subCategory, message, fileParameters)
}

export const useCreateChatMessage = () =>
  useMutation({
    mutationFn: createChatMessage,
  })

import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface Interest {
  interest: string;
  levelOfInterest: string;
  comments: string;
}

interface ActivitySchedule {
  day: string;
  time: string;
  activity: string;
  location: string;
  levelOfEngagement: string;
  comments: string;
}

interface TherapeuticBenefit {
  activity: string;
  benefits: string;
}

interface ParticipationReport {
  activity: string;
  sessionsAttended: string;
  engagementLevel: string;
  notes: string;
}

interface InteractionFeedback {
  date: string;
  interaction: string;
  feedback: string;
}

const residentPreferences: Interest[] = [
  { interest: "Reading", levelOfInterest: "High", comments: "Enjoys reading historical fiction and biographies. Prefers quiet spaces for reading." },
  { interest: "Gardening", levelOfInterest: "Medium", comments: "Enjoys light gardening, particularly tending to potted plants on the patio. Limited by mobility issues." },
  { interest: "Music", levelOfInterest: "High", comments: "Loves listening to classical music and oldies. Participates in music therapy sessions regularly." },
  { interest: "Board Games/Puzzles", levelOfInterest: "Medium", comments: "Participates in group board games, enjoys puzzles with other residents." },
  { interest: "Family Visits", levelOfInterest: "High", comments: "Values regular visits from his daughter, Jane Smith, and enjoys family gatherings." },
  { interest: "Group Activities", levelOfInterest: "Low", comments: "Prefers smaller, more intimate settings for socializing. Enjoys group activities when tailored to his interests." },
  { interest: "Walking/Outdoor Time", levelOfInterest: "Medium", comments: "Enjoys sitting outside and light walking with assistance." }
];

const activitySchedule: ActivitySchedule[] = [
  { day: "Monday", time: "10:00 AM", activity: "Chair Yoga (Low-impact Exercise)", location: "Recreation Room", levelOfEngagement: "Moderate", comments: "Participates for 20-30 minutes, enjoys light stretching." },
  { day: "Tuesday", time: "03:00 PM", activity: "Music Therapy (Classical Music)", location: "Music Room", levelOfEngagement: "High", comments: "Fully engaged, enjoys singing along to familiar tunes." },
  { day: "Wednesday", time: "11:00 AM", activity: "Gardening (Tending Potted Plants)", location: "Outdoor Patio", levelOfEngagement: "Moderate", comments: "Engages for 30 minutes, enjoys watering plants." },
  { day: "Thursday", time: "02:00 PM", activity: "Reading Group (Historical Fiction)", location: "Library Room", levelOfEngagement: "High", comments: "Participates in discussions about books." },
  { day: "Friday", time: "10:30 AM", activity: "Puzzles & Board Games", location: "Common Room", levelOfEngagement: "Moderate", comments: "Participates in puzzles, often joins group board games." },
  { day: "Saturday", time: "03:30 PM", activity: "Family Time/Visits", location: "Resident's Room", levelOfEngagement: "High", comments: "Enjoys regular visits from his daughter." },
  { day: "Sunday", time: "04:00 PM", activity: "Movie Afternoon (Old Films)", location: "Recreation Room", levelOfEngagement: "High", comments: "Watches classic films with other residents, enjoys the nostalgia." }
];

const therapeuticBenefits: TherapeuticBenefit[] = [
  { activity: "Chair Yoga", benefits: "Helps with mobility, flexibility, and pain management, especially for osteoarthritis. The low-impact nature reduces the risk of falls and promotes physical well-being." },
  { activity: "Music Therapy", benefits: "Provides emotional comfort and relaxation. Enhances memory recall and cognitive function by listening to familiar songs. Reduces feelings of isolation." },
  { activity: "Gardening", benefits: "Encourages light physical activity, provides a sense of purpose and connection with nature, and promotes relaxation. A positive, sensory-rich activity." },
  { activity: "Reading Group", benefits: "Stimulates intellectual discussion, enhances cognitive engagement, and offers a sense of belonging through shared interests." },
  { activity: "Puzzles & Board Games", benefits: "Encourages problem-solving, improves cognitive function, and promotes social interaction with other residents." },
  { activity: "Family Time/Visits", benefits: "Provides emotional support, reduces feelings of loneliness, and strengthens family bonds. John Smith particularly values time spent with his daughter." },
  { activity: "Movie Afternoon", benefits: "Promotes relaxation and offers a shared experience with other residents, reducing isolation and promoting conversation after the film." }
];

const participationReport: ParticipationReport[] = [
  { activity: "Chair Yoga", sessionsAttended: "3/4", engagementLevel: "Moderate", notes: "Participated in 3 out of 4 sessions, skipped 1 due to back pain." },
  { activity: "Music Therapy", sessionsAttended: "4/4", engagementLevel: "High", notes: "Fully engaged in every session, sings along to familiar music." },
  { activity: "Gardening", sessionsAttended: "2/4", engagementLevel: "Moderate", notes: "Participated in 2 out of 4 sessions, limited by weather and mobility issues." },
  { activity: "Reading Group", sessionsAttended: "4/4", engagementLevel: "High", notes: "Actively participated in all sessions, particularly enjoyed historical fiction discussions." },
  { activity: "Puzzles & Board Games", sessionsAttended: "3/4", engagementLevel: "Moderate", notes: "Participated in 3 out of 4 sessions, enjoys puzzles the most." },
  { activity: "Family Time/Visits", sessionsAttended: "4/4", engagementLevel: "High", notes: "Regular weekly visits from his daughter, a significant source of emotional support." },
  { activity: "Movie Afternoon", sessionsAttended: "4/4", engagementLevel: "High", notes: "Watched all four classic films, particularly enjoyed 'Casablanca'." }
];

const recentInteractions: InteractionFeedback[] = [
  { date: "August 14, 2024", interaction: "Attended Reading Group, discussed historical fiction", feedback: "Reported enjoying the discussion and being able to share his thoughts. Asked for more historical books." },
  { date: "August 22, 2024", interaction: "Participated in Music Therapy (classical music session)", feedback: "Expressed feeling 'relaxed and happy' after the session. Requested more Beethoven pieces in the future." },
  { date: "August 29, 2024", interaction: "Gardening session (light weeding, watering plants)", feedback: "Enjoyed being outside but mentioned that his back was sore after 30 minutes." }
];

export const SocialAndRecreational = () => {
  return (
    <div className="p-card p-5">
      <h2>Social and Recreational Activities Profile: John Smith</h2>
      <div className='flex flex-col gap-10'>
        <DataTable value={residentPreferences} paginator rows={5} responsiveLayout="scroll">
          <Column field="interest" header="Interest/Activity" />
          <Column field="levelOfInterest" header="Level of Interest" />
          <Column field="comments" header="Comments/Notes" />
        </DataTable>

        <DataTable value={activitySchedule} paginator rows={5} responsiveLayout="scroll">
          <Column field="day" header="Day" />
          <Column field="time" header="Time" />
          <Column field="activity" header="Activity" />
          <Column field="location" header="Location" />
          <Column field="levelOfEngagement" header="Level of Engagement" />
          <Column field="comments" header="Comments" />
        </DataTable>

        {therapeuticBenefits.map((benefit, index) => (
          <div key={index}>
            <h4>{benefit.activity}</h4>
            <p>{benefit.benefits}</p>
          </div>
        ))}

        <DataTable value={participationReport} paginator rows={5} responsiveLayout="scroll">
          <Column field="activity" header="Activity" />
          <Column field="sessionsAttended" header="Number of Sessions Attended" />
          <Column field="engagementLevel" header="Engagement Level" />
          <Column field="notes" header="Notes" />
        </DataTable>

        <p><strong>Goals:</strong></p>
        <ul>
          <li>Maintain cognitive function: Continue participating in intellectually stimulating activities such as reading, puzzles, and board games to preserve cognitive abilities.</li>
          <li>Enhance emotional well-being: Regular participation in music therapy and family visits to reduce feelings of isolation and provide emotional comfort.</li>
          <li>Promote mobility: Encourage continued participation in low-impact activities like chair yoga and light gardening to help with pain management and mobility related to osteoarthritis.</li>
        </ul>
        <p><strong>Action Plan:</strong></p>
        <ul>
          <li>Increase Physical Activity: Explore additional low-impact physical activities tailored to John Smith’s mobility level, such as stretching exercises or short, assisted walks around the facility.</li>
          <li>Personalized Music Therapy: Create a playlist of favorite classical music and songs from John Smith’s youth to enhance his emotional connection during music therapy sessions.</li>
          <li>Gardening Adaptation: Provide raised garden beds to make it easier for John to tend to plants without straining his back or knees, allowing him to continue his engagement with nature.</li>
          <li>Scheduled Family Time: Continue to facilitate weekly visits with John’s daughter and explore opportunities for video calls with extended family members who cannot visit in person.</li>
        </ul>

        <DataTable value={recentInteractions} paginator rows={5} responsiveLayout="scroll">
          <Column field="date" header="Date" />
          <Column field="interaction" header="Interaction" />
          <Column field="feedback" header="Feedback" />
        </DataTable>

        <p><strong>General Social Engagement:</strong> John Smith has a positive attitude toward participating in activities that align with his interests. He is more inclined toward one-on-one interactions or small group activities, avoiding large group events.</p>
        <p><strong>Emotional Well-being:</strong> Family visits and music therapy are essential to John Smith’s emotional health. These activities should be prioritized, as they consistently improve his mood and reduce feelings of loneliness.</p>
        <p><strong>Mobility Considerations:</strong> Due to chronic pain from osteoarthritis and his fall history, it’s important to monitor his mobility during outdoor and physical activities to ensure safety. Offering extra assistance when walking or gardening is crucial.</p>

        <p>John Smith is an active participant in several social and recreational activities, particularly those related to music, reading, and family engagement. His activities are designed to stimulate cognitive function, enhance emotional well-being, and maintain physical mobility. His involvement in music therapy, reading groups, and family time provides significant emotional and psychological benefits, while his participation in physical activities like chair yoga and gardening supports his physical health.</p>
        <p><strong>Next Review Date:</strong> October 1, 2024, or sooner if significant changes in physical or emotional well-being are observed.</p>
      </div>
    </div>
  );
};

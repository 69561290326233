import {CHAT_DEFAULT_PAGE_SIZE, SUGGESTIONS_PAGE_SIZE} from '../../../../constants/general'
import {GET, POST} from '../../../../constants/requestMethods'
import {CHAT_HISTORY, SUGGESTIONS} from '../../../../constants/requestUrls'
import {MESSAGES_TAG} from '../../../../constants/tags'
import {
  paginationForceRefetch,
  paginationMerge,
  paginationSerializeQueryArgs,
} from '../../../../utils/paginationHelpers'
import mainApi from '../../../apis/main'
import {setChatState} from '../slice'

import type IError from '../../../types/IError'
import type {IPaginatedResponse} from '../../../types/IPaginatedResponse'
import type {RootState} from '../../../types/TStore'
import type {IChatParams} from '../interfaces/IChatParams'
import type {ICreateMessageParams} from '../interfaces/ICreateMessageParams'
import type ICreateMessageResponse from '../interfaces/ICreateMessageResponse'
import type {IGetSuggestionParams} from '../interfaces/IGetSuggestionParams'
import type {IMessage} from '../interfaces/IMessage'
import type ISuggestion from '../interfaces/ISuggestion'

const chatApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchChatHistory: builder.query<IPaginatedResponse<IMessage>, IChatParams>({
      providesTags: [MESSAGES_TAG],
      query: ({subCategory, skip}) => ({
        url: `${CHAT_HISTORY}`,
        params: {
          Skip: skip,
          PageSize: CHAT_DEFAULT_PAGE_SIZE,
          SubCategory: subCategory,
        },
        method: GET,
      }),
      merge: paginationMerge,
      serializeQueryArgs: paginationSerializeQueryArgs,
      forceRefetch: paginationForceRefetch,
    }),
    fetchInfiniteSuggestions: builder.query<IPaginatedResponse<ISuggestion>, IGetSuggestionParams>({
      query: ({search, subCategory, pageNumber, startsWith, pageSize = SUGGESTIONS_PAGE_SIZE}) => ({
        url: `${SUGGESTIONS}`,
        params: {
          PageNumber: pageNumber,
          PageSize: pageSize,
          SubCategory: subCategory,
          Search: search,
          StartsWith: startsWith,
        },
        method: GET,
      }),
      merge: paginationMerge,
      serializeQueryArgs: paginationSerializeQueryArgs,
      forceRefetch: paginationForceRefetch,
    }),
    addMessage: builder.mutation<ICreateMessageResponse, ICreateMessageParams>({
      query: (body) => ({
        url: `${CHAT_HISTORY}`,
        method: POST,
        body: {
          message: body.message,
          subCategory: body.subCategory?.id || '',
        },
      }),
      onQueryStarted: async (args, {dispatch, queryFulfilled, getState}) => {
        dispatch(setChatState({chatError: ''}))
        const {
          chat: {skip},
          categories: {subCategory, category},
        } = getState() as RootState
        const updateData = (updateFn: (draft: IPaginatedResponse<IMessage>) => void) => {
          dispatch(
            chatApi.util.updateQueryData(
              'fetchChatHistory',
              {subCategory: subCategory?.id || '', skip},
              updateFn
            )
          )
        }

        updateData((draft: IPaginatedResponse<IMessage>) => {
          const chatHistory = draft
          if (category && subCategory) {
            chatHistory.data = [
              {
                id: crypto.randomUUID(),
                isQuestion: true,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                category,
                ...args,
              },
              ...chatHistory.data,
            ]
          }
        })

        try {
          const response = await queryFulfilled

          updateData((draft: IPaginatedResponse<IMessage>) => {
            const chatHistory = draft
            chatHistory.data = [
              response.data.answer,
              response.data.question,
              ...chatHistory.data.slice(1),
            ]
            return chatHistory
          })
        } catch (error) {
          if (error && typeof error === 'object' && 'error' in error) {
            dispatch(setChatState({chatError: (error as IError).error.data}))
          }
        }
      },
    }),
  }),
})

export const {useFetchChatHistoryQuery, useFetchInfiniteSuggestionsQuery, useAddMessageMutation} =
  chatApi

export default chatApi

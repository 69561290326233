import {useAppConfigurationQuery} from 'src/queries/getAppConfiguration'
import {
  CreateAppConfigurationOptions,
  useCreateAppConfiguration,
} from './api/createAppConfiguration'
import {Controller, useForm} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {InputSwitch} from 'primereact/inputswitch'
import {FileUpload} from 'primereact/fileupload'
import {Button} from 'primereact/button'
import {Chip} from 'primereact/chip'
import {useEffect} from 'react'
import {ColorPicker} from 'primereact/colorpicker'

export const AppConfiguration = () => {
  const createAppConfiguration = useCreateAppConfiguration()
  const appConfiguration = useAppConfigurationQuery()

  const initialValues: CreateAppConfigurationOptions = {
    logoUrl: appConfiguration.data?.logoUrl,
    disclaimerLineOne: appConfiguration.data?.disclaimerLineOne,
    disclaimerLineTwo: appConfiguration.data?.disclaimerLineTwo,
    headerColor: appConfiguration.data?.headerColor,
    isInsightEnabled: appConfiguration.data?.isInsightEnabled || false,
    isAskMeEnabled: appConfiguration.data?.isAskMeEnabled || false,
    isAssistEnabled: appConfiguration.data?.isAssistEnabled || false,
    isOneViewEnabled: appConfiguration.data?.isOneViewEnabled || false,
    loginLogoUrl: appConfiguration.data?.loginLogo,
    loginBackgroundUrl: appConfiguration.data?.loginBackgroundImage,
    logo: null,
    loginLogo: null,
    loginBackground: null,
  }

  const form = useForm<CreateAppConfigurationOptions>({defaultValues: initialValues})

  const {watch, setValue, reset} = form

  const logoUrl = watch('logoUrl')
  const loginLogoUrl = watch('loginLogoUrl')
  const loginBackgroundUrl = watch('loginBackgroundUrl')
  const onSubmit = async (data: CreateAppConfigurationOptions) => {
    console.log('Submitting', data)
    await createAppConfiguration.mutateAsync(data)
  }
  // Reset form values whenever appConfiguration.data changes
  useEffect(() => {
    if (appConfiguration.data) {
      reset(initialValues)
    }
  }, [appConfiguration.data, reset])
  return (
    <div className='items-center justify-center w-full flex mt-4'>
      <form onSubmit={form.handleSubmit(onSubmit)} className='w-2/4'>
        <div className='grid grid-cols-2 gap-4 p-4'>
          <label>Logo Url</label>
          {logoUrl ? (
            <div className='flex gap-1 text-center items-center'>
              <a href={logoUrl} target='_blank' rel='noreferrer'>
                {logoUrl}
              </a>
              <Button
                icon='pi pi-times'
                className='p-button-rounded p-button-text p-button-sm p-button-danger p-1'
                onClick={() => setValue('logoUrl', null)}
              />
            </div>
          ) : (
            <Controller
              name='logo'
              control={form.control}
              render={({field}) => (
                <FileUpload
                  mode='basic'
                  accept='image/*'
                  customUpload
                  onSelect={(e) => {
                    const file = e.files[0]
                    console.log('File', file)
                    setValue('logo', {data: file, fileName: file.name})
                  }}
                />
              )}
            />
          )}
          <label>Disclaimer Line 1</label>
          <Controller
            name='disclaimerLineOne'
            control={form.control}
            render={({field}) => (
              <InputText
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder='Disclaimer Line 1'
              />
            )}
          />
          <label>Disclaimer Line 2</label>
          <Controller
            name='disclaimerLineTwo'
            control={form.control}
            render={({field}) => (
              <InputText
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                placeholder='Disclaimer Line 2'
              />
            )}
          />
          <label>Header Color</label>
          <Controller
            name='headerColor'
            control={form.control}
            render={({field}) => (
              <div className='flex gap-5 text-center items-center'>
                <ColorPicker
                  value={field.value ?? '605e5c'}
                  onChange={(e) => field.onChange(e.value)}
                  format='hex'
                />
                {field.value && (
                  <Chip
                    label={field.value}
                    removable
                    onRemove={() => setValue('headerColor', null)}
                  />
                )}
              </div>
            )}
          />
          <label>Insight Enabled</label>
          <Controller
            name='isInsightEnabled'
            control={form.control}
            defaultValue={appConfiguration.data?.isInsightEnabled}
            render={({field}) => (
              <InputSwitch checked={!!field.value} onChange={(e) => field.onChange(e.value)} />
            )}
          />
          <label>Ask Me Enabled</label>
          <Controller
            name='isAskMeEnabled'
            control={form.control}
            defaultValue={appConfiguration.data?.isAskMeEnabled}
            render={({field}) => (
              <InputSwitch checked={!!field.value} onChange={(e) => field.onChange(e.value)} />
            )}
          />
          <label className='flex-1'>Assist Enabled</label>
          <Controller
            name='isAssistEnabled'
            control={form.control}
            defaultValue={appConfiguration.data?.isAssistEnabled}
            render={({field}) => (
              <InputSwitch
                className='flex-grow-3'
                checked={!!field.value}
                onChange={(e) => field.onChange(e.value)}
              />
            )}
          />
          <label>One View Enabled</label>
          <Controller
            name='isOneViewEnabled'
            control={form.control}
            defaultValue={appConfiguration.data?.isOneViewEnabled}
            render={({field}) => (
              <InputSwitch checked={!!field.value} onChange={(e) => field.onChange(e.value)} />
            )}
          />
          <label>Login Logo Url</label>
          {loginLogoUrl ? (
            <div className='flex gap-1 text-center items-center'>
              <a href={loginLogoUrl} target='_blank' rel='noreferrer'>
                {loginLogoUrl}
              </a>
              <Button
                icon='pi pi-times'
                className='p-button-rounded p-button-text p-button-sm p-button-danger p-1'
                onClick={() => setValue('loginLogoUrl', null)}
              />
            </div>
          ) : (
            <Controller
              name='loginLogo'
              control={form.control}
              render={({field}) => (
                <FileUpload
                  mode='basic'
                  accept='image/*'
                  customUpload
                  onSelect={(e) => {
                    const file = e.files[0]
                    setValue('loginLogo', {data: file, fileName: file.name})
                  }}
                />
              )}
            />
          )}
          <label>Login Background Url</label>
          {loginBackgroundUrl ? (
            <div className='flex gap-1 text-center items-center'>
              <a href={loginBackgroundUrl} target='_blank' rel='noreferrer'>
                {loginBackgroundUrl}
              </a>
              <Button
                icon='pi pi-times'
                className='p-button-rounded p-button-text p-button-sm p-button-danger p-1'
                onClick={() => setValue('loginBackgroundUrl', null)}
              />
            </div>
          ) : (
            <Controller
              name='loginBackground'
              control={form.control}
              render={({field}) => (
                <FileUpload
                  mode='basic'
                  accept='image/*'
                  customUpload
                  onSelect={(e) => {
                    const file = e.files[0]
                    setValue('loginBackground', {data: file, fileName: file.name})
                  }}
                />
              )}
            />
          )}
        </div>

        <Button type='submit' label='Submit' loading={createAppConfiguration.isPending} />
      </form>
    </div>
  )
}
